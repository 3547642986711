import React, { useState } from 'react'
import { InboxOutlined } from '@ant-design/icons'
import { Button, Drawer, Modal, Upload } from 'antd'
import { IAmountHeaderImport, SelectColumns } from '../defaultFilters/actionsButton/selectColumns'
import { IPropsDrawer } from '../defaultFilters/actionsButton'
import { lightColor } from '../../utils/styleColors'
import FileSaver from 'file-saver'
import api from '../../api'

const { Dragger } = Upload

interface IProps extends IPropsDrawer {
  setFile: any
  header: any[]
  setAmountHeader: any
  amountHeader: IAmountHeaderImport[]
  context: string
  setLoading: any
  file: any
}

export const UploadDocument = ({
  setFile,
  setAmountHeader,
  header,
  context,
  amountHeader,
  setLoading,
  file,
}: IProps) => {
  const [open, setOpen] = useState(false)
  const [modal, contextHolder] = Modal.useModal()

  const labelContext = {
    promoter: 'Promotor',
  }

  const generateTemplate = async () => {
    setLoading(true)
    const url = `/ms-import/export/${context}/download`
    const body: any = {
      amountHeader: amountHeader.map((item) => ({ key: item.key, label: item.label })),
    }

    const { data } = await api.post(url, body, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const blob = new Blob([data], {
      type: 'application/xlsx',
    })
    FileSaver.saveAs(blob, `${context}.xlsx`)
    setLoading(false)
  }

  return (
    <div
      style={{
        width: '100%',
        minHeight: 400,
        borderRadius: 20,
        marginTop: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          color: lightColor.blackText,
        }}
      >
        <p
          style={{
            fontSize: 17,
            maxWidth: 500,
            textAlign: 'center',
          }}
        >
          Preencha a planilha com os dados a serem importados, salve no formato XLSX e depois selecione o arquivo para
          importar.
        </p>
        <Button
          style={{
            margin: '10px 0',
          }}
          onClick={() => setOpen(!open)}
        >
          Gerar template
        </Button>
        <Dragger
          style={{ padding: 10, minWidth: 500 }}
          accept=".xlsx"
          multiple={false}
          customRequest={({ onSuccess }) => {
            if (onSuccess) onSuccess('ok', undefined)
          }}
          onRemove={() => setFile(undefined)}
          fileList={file ? [file] : []}
          beforeUpload={async (evt) => {
            const form = new FormData()
            const file = evt as Blob
            form.append('file', file)
            setLoading(true)
            try {
              const response = await fetch(`https://ms-import.rock-at.com/import/${context}/validate`, {
                method: 'POST',
                body: form as any,
              })

              const data = await response.json()
              setLoading(false)

              if (data.is_valid === false && data.errors?.length > 0) {
                modal.error({
                  title: `Erro ao importar ${labelContext[context as keyof typeof labelContext]}`,
                  content: (
                    <div style={{ maxHeight: '60vh', overflowX: 'auto' }}>
                      {data.errors.map((msg: string, index: number) => (
                        <p key={index}>{msg}</p>
                      ))}
                    </div>
                  ),
                  width: 600,
                  cancelText: 'Cancelar',
                  okCancel: true,
                  cancelButtonProps: {
                    style: {
                      height: 40,
                      width: 150,
                      border: `1px solid ${lightColor.primary}`,
                      color: lightColor.primary,
                      background: 'transparent',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: 17,
                      borderRadius: 20,
                      cursor: 'pointer',
                      marginTop: 20,
                    },
                  },
                  okButtonProps: {
                    style: {
                      height: 40,
                      width: 150,
                      border: `1px solid ${lightColor.primary}`,
                      color: lightColor.white,
                      background: lightColor.primary,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: 17,
                      borderRadius: 20,
                      cursor: 'pointer',
                      marginTop: 20,
                    },
                  },
                  okText: 'Baixar modelo',
                  onOk: () => generateTemplate(),
                })
                return
              }

              setFile(file)
            } catch (error) {
              console.log(error)
            }
            setLoading(false)
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Click ou arraste para a área de upload</p>
          <p className="ant-upload-hint">Importe o arquivo em formato xlsx.</p>
        </Dragger>
      </div>

      <Drawer
        title={'Gerar template'}
        placement="right"
        closable={true}
        onClose={() => setOpen(false)}
        open={open}
        key="left"
        width="100%"
      >
        <div
          style={{
            padding: '0 74px',
          }}
        >
          <SelectColumns
            columnsHeader={header}
            changeHeader={setAmountHeader}
            key="SelectCOmuns"
            amountHeader={amountHeader}
            context={context}
            setOpen={() => setOpen(!open)}
          />
        </div>
      </Drawer>
      {contextHolder}
    </div>
  )
}
