import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyDKjXrw7aErTJqi2UziSdTzVauXbHP3S6U',
  authDomain: 'rocketpdv-dev.firebaseapp.com',
  projectId: 'rocketpdv-dev',
  storageBucket: 'rocketpdv-dev.appspot.com',
  messagingSenderId: '954863674958',
  appId: '1:954863674958:web:f4912a2426952adac8051f',
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)

export const initFirebase = () => {
  return app
}
