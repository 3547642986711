import { DatePicker } from 'antd'
import styled from 'styled-components'
import { useDark } from '../../../context/contextDark'
import { darkColor } from '../../../utils/styleColors'

const { RangePicker } = DatePicker

export const ContainerDate = styled.div`
  ${() => {
    const { isDarkMode } = useDark()

    if (isDarkMode)
      return `
      && .ant-picker {
        background-color: ${darkColor.backgroundColor} !important;
        border-color: ${darkColor.primary} !important;
        color: ${darkColor.white} !important;
      }

      && input {
        color: ${darkColor.white} !important;
      }

      && .ant-picker-separator {
        color: ${darkColor.white} !important;
      }

      && .ant-picker-suffix {
        color: ${darkColor.white} !important;
      }

    `

    return ''
  }}
`

export const Date = styled(RangePicker)``
