import styled from 'styled-components'
import { useDark } from '../../../context/contextDark'
import { darkColor } from '../../../utils/styleColors'

export const ContainerSearceh = styled.div`
  .ant-input-affix-wrapper {
    border-color: transparent;
    border-width: 2px !important;
    border-radius: 25px;
    width: 80px;
  }

  .ant-input-affix-wrapper:hover {
    transition: all 0.3s;
    border-width: 2px !important;
    width: 150px;
  }
  ${() => {
    const { isDarkMode } = useDark()

    if (isDarkMode)
      return `
      && .ant-input-affix-wrapper {
        background-color: ${darkColor.backgroundColor} !important;
        color: ${darkColor.white} !important;
      }

      && .ant-input {
        background-color: ${darkColor.backgroundColor} !important;
        color: ${darkColor.white} !important;
      }

      && .ant-input::placeholder {
        color: rgb(177 177 177) !important;

      }
    `

    return ''
  }}
`
