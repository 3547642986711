import InputMask from 'react-input-mask'
import { Controller } from 'react-hook-form'
import { lightColor } from '../../utils/styleColors'

interface IProps {
  required?: string
  name: string
  label: string
  control: any
  disabled?: boolean
  mask?: any
  type?: string
  style?: any
}

export const HookInput = ({ control, name, label, required, disabled, type, style, mask }: IProps) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required || undefined,
      }}
      render={({ field: { onChange, value, name }, fieldState: { error } }) => (
        <div>
          <p
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              color: lightColor.blackTextBold,
              marginBottom: 8,
              opacity: 0.74,
            }}
          >
            {label}
          </p>
          <InputMask
            mask={mask}
            className="inputMask"
            disabled={disabled}
            autoComplete="off"
            id={name}
            name={name}
            onChange={onChange}
            value={value}
            type={type}
            placeholder={`${label}`}
            style={style}
          />

          {!!error && (
            <p
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                color: lightColor.redBold,
                marginBottom: 8,
                opacity: 0.74,
              }}
            >
              {error?.message?.toString()}
            </p>
          )}
        </div>
      )}
    />
  )
}
