import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

interface DarkProviderProps {
  children: ReactNode
}

type DarkContextData = {
  isDarkMode: boolean
  setIsDarkMode: any
}

export const MODE_DARK = '@rock-at/dark'
const DarkContext = createContext({} as DarkContextData)

export function DarkProvider({ children }: DarkProviderProps) {
  const [isDarkMode, setIsDarkModeContext] = useState(false)

  const setIsDarkMode = (checked: boolean) => {
    setIsDarkModeContext(checked)
  }

  useEffect(() => {
    const dark = localStorage.getItem(MODE_DARK)
    if (dark && dark === true.toString()) setIsDarkModeContext(true)
  }, [])
  return (
    <DarkContext.Provider
      value={{
        isDarkMode,
        setIsDarkMode,
      }}
    >
      {children}
    </DarkContext.Provider>
  )
}

export const useDark = () => useContext(DarkContext)
