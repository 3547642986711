/* eslint-disable */

import styled from 'styled-components'
import { Drawer, Upload } from 'antd'
import { useDark } from '../../context/contextDark'
import { darkColor, lightColor } from '../../utils/styleColors'

export const DrawerStyle = styled(Drawer)`
  ${() => {
    const { isDarkMode } = useDark()

    if (isDarkMode)
      return `
        .ant-drawer-wrapper-body {
          background-color: ${darkColor.bgTable} !important;
        }

        .ant-drawer-title {
          color: ${darkColor.white};
        }

        .ant-drawer-close {
          color: ${darkColor.white};
        }

        h3 {
          color: ${darkColor.white}; 
        }

        input {
          color: ${darkColor.white} !important;
          background-color: ${darkColor.bgTable} !important;
        }
  
        input::placeholder {
          color: rgb(177 177 177) !important;
        }
  
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          color: ${darkColor.white} !important;
          background-color: ${darkColor.bgTable} !important;
        }
  
        .ant-select-arrow {
          color: ${darkColor.white} !important;
        }

        .ant-input-affix-wrapper {
          background-color: ${darkColor.bgTable} !important;
          color: ${darkColor.white} !important;
        }

        span {
          color: ${darkColor.white} !important;
        }

        p {
          color: ${darkColor.white} !important;
        }

        .ant-table-thead .ant-table-cell {
          background-color: ${darkColor.bgTable} !important;
        }
  
        .ant-table-container {
          background: ${darkColor.bgTable} !important;
        }
  
        .ant-table-selection-column {
          background: ${darkColor.bgTable};
        }
  
        td {
          background: ${darkColor.bgTable} !important;
          border-color: ${darkColor.borderTable} !important;
          color: ${darkColor.white} !important;
        }
  
        th {
          border-color: ${darkColor.borderTable} !important;
          color: ${darkColor.white} !important;
        }
  
        table {
          border-color: ${darkColor.borderTable} !important;
        }
  
        && .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >thead>tr>th {
          color: ${darkColor.white} !important;
        }
  
        && .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tbody>tr>td {
          color: ${darkColor.white} !important;
        }
  
        && .ant-pagination {
          color: ${darkColor.white} !important;
        }
  
        && .ant-pagination .ant-pagination-item a{
          color: ${darkColor.white} !important;
        }
  
        && .ant-pagination .ant-pagination-item-active {
          background-color: transparent !important;
        }
  
        && .ant-pagination-item-link, .ant-pagination-item-ellipsis {
          color: ${darkColor.white} !important;
        }
  
        && .ant-select-selector {
          color: ${darkColor.white} !important;
          background-color: ${darkColor.primary} !important;
          border-color: ${darkColor.primary} !important;
        }
  
        && .ant-select-dropdown {
          color: ${darkColor.white} !important;
          background-color: ${darkColor.primary} !important;
        }
  
        && .ant-select-item {
          color: ${darkColor.white} !important;
        }
  
        &&& .ant-select-item-option-selected {
          background-color: ${darkColor.borderTable} !important;
        }

        &&& .ant-btn-default {
          background-color: ${darkColor.primary} !important;
          border-color: ${darkColor.backgroundColor} !important;
          color: ${darkColor.white};
        }

        &&& .ant-steps-item-title {
          color: ${darkColor.white} !important;
        }

        &&& .ant-steps-item-wait .ant-steps-item-container .ant-steps-item-icon {
          background-color: ${darkColor.primaryOpactity} !important;
        }

        &&& .ant-collapse-content-box {
          background-color: ${darkColor.bgTable} !important;
        }

        &&& .ant-empty-description {
          color: ${darkColor.white} !important;
        }

        &&& .ant-tabs-tab-btn {
          color: ${darkColor.white} !important;
        }

        &&& .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: ${darkColor.primary} !important;
        }
    `

    return ''
  }}
`

export const ButtonSteps = styled.button`
  height: 40px;
  width: 150px;
  border: 1px solid ${lightColor.primary};
  background-color: ${lightColor.primary};
  color: ${lightColor.white};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 20px;
  &:hover {
    transition: all 0.3s;
    box-shadow: rgba(0, 0, 0, 0.94) 6px 2px 16px -5px;
  }

  && i {
    background-color: #fff !important;
  }
`

export const Title = styled.p`
    font-size: 16px;
    font-weight: bold;
    color: #272d3b;
`

export const Description = styled.p`
    font-size: 12px;
    font-weight: bold;
    color: #6f7782;
    margin-bottom: 10px;
    line-height: 14px;
    width: 100%;
    text-align: left;
`

export const CardCreateUpload = styled(Upload.Dragger)`
    width: 292px;
    border-radius: 12px;
    // border: 1px solid #d6d6d6;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 36px 26px 56px;
    margin: 20px auto;

    img {
        width: 60px;
        height: 60px;
    }

    h4 {
        color: #242424;
        font-family: "Open Sans";
        font-size: 20px;
        font-weight: 600;
    }

    p {
        margin-bottom: 0;
        color: #666666;
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 400;
        text-align: center;
    }

    i {
        color: #6f7782;
        font-family: "Open Sans";
        font-size: 16px;
        font-style: italic;
        text-align: center;
        width: 100%;
    }
`

export const ButtonLink = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border-radius: 5px;
    margin: 10px auto 0;
    background-color: transparent;
    border: 1px solid #6600cc;
    width: 100%;
    height: 50px;
    
    a {
        padding: 0;
        margin-left: 10px;
    }

    svg {
        color: #6600cc;
    }
`

export const BtnExport = styled.a`
  padding: 10px;
  color: #6600cc;

  &:hover {
    color: #666666;
  }
`