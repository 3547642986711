import styled from 'styled-components'

interface Iprops {
  visible: boolean
}

export const Fade = styled.div<Iprops>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: all 0.8s;
`
