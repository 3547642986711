import { Alert, Button, message, Spin, Table } from 'antd'
import { useState } from 'react'
import { SimpleSearchWithoutForm } from '../form/SimpleSearchWithoutForm'
import type { ColumnsType } from 'antd/es/table'
import api_v2 from '../../api/api-v2'
import { lightColor } from '../../utils/styleColors'

interface IProps {
  promoters: any[]
}

interface ISelecteds {
  from: number
  to: number
  transfer_to?: number
  workspace_slug: string
}
export const MultipleMigrate = ({ promoters }: IProps) => {
  const [loading, setLoading] = useState(false)
  const [selections, setSelctions] = useState<ISelecteds[]>([])
  const [count, setCount] = useState<number>(1)

  const columns: ColumnsType<any> = [
    {
      title: 'Colaborador',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Ambiente atual',
      dataIndex: ['workspace', 'name'],
      key: 'workspace',
    },
    {
      title: 'Migrar para',
      render: (value, row) => {
        return (
          <SimpleSearchWithoutForm
            placeholder="Selecione o ambiente"
            path="workspaces"
            isMult={false}
            onChange={(target) => {
              const index = selections.findIndex((promoter) => promoter.from === row.id)
              if (index > -1) {
                const newList = selections
                newList[index].to = target.value
                setSelctions(newList)
              } else {
                const newList = selections
                newList.push({
                  from: row.id,
                  to: target.value,
                  workspace_slug: row.workspace.slug,
                })

                setSelctions(newList)
              }
            }}
            header={{
              headers: {
                workspace: row.workspace.slug,
              },
            }}
          />
        )
      },
    },
  ]

  const submit = async () => {
    try {
      if (selections?.length !== promoters?.length) {
        return message.error('Selecione o ambiente de todos os promotores.')
      }
      setLoading(true)

      for await (const [index, body] of selections.entries()) {
        await api_v2.put(
          `promoter/migrate/${body.from}`,
          { workspace_id: body.to },
          {
            headers: {
              workspace: body.workspace_slug,
            },
          }
        )

        setCount(index + 1)
      }

      message.success('Sucesso ao migrar promotores')

      setTimeout(() => {
        window.location.reload()
      }, 1500)
    } catch (error) {
      setLoading(false)
    }
  }

  return (
    <>
      {!loading && (
        <>
          <Table
            dataSource={promoters}
            columns={columns}
            scroll={{
              y: 400,
            }}
          />
          <Alert
            message={'Atenção! Após o promotor ser migrado o roteiro atual será excluído.'}
            type="error"
            style={{ marginTop: 10 }}
          />
        </>
      )}

      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
          }}
        >
          <Spin />
          Migrando {count} de {selections?.length}
        </div>
      ) : (
        <Button
          style={{
            backgroundColor: lightColor.primary,
            color: lightColor.white,
            fontWeight: 'bold',
            fontSize: 14,
            width: '100%',
            marginTop: 20,
            height: 40,
          }}
          onClick={async () => submit()}
        >
          Salvar
        </Button>
      )}
    </>
  )
}
