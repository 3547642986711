import React, { useEffect, useState } from 'react'
import { ButtonSteps, DrawerStyle } from './style'
import { Spin, Steps, message } from 'antd'
import { UploadDocument } from './uploadDocument'
import { IAmountHeaderImport } from '../defaultFilters/actionsButton/selectColumns'
import api from '../../api'
import { useTabActions } from '../../context/tabActions'
import { lightColor } from '../../utils/styleColors'
import { getUserId } from '../../utils/login'

interface IProps {
  title: string
  open: boolean
  onOpen(bool: boolean): void
  context: string
}
export const ImportDrawer = ({ title, onOpen, open, context }: IProps) => {
  const { setActiveKey } = useTabActions()
  const [current, setCurrent] = useState(0)
  const [file, setFile] = useState<any>()
  const [isProccesing] = useState(false)
  const [loading, setLoading] = useState(false)
  const [header, setHeader] = useState<any>([])
  const [amountHeader, setAmountHeader] = useState<IAmountHeaderImport[]>([])

  const next = async () => {
    if (current === 0 && !file) {
      return message.error('É obrigatório subir um arquivo.')
    }
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  // const parseNDJSON = () => {
  //   let ndjsonBuffer = ''

  //   return new TransformStream({
  //     transform(ndjsonChunk, controller) {
  //       ndjsonBuffer += ndjsonChunk
  //       const jsonValues = ndjsonBuffer.split('\n')
  //       jsonValues.slice(0, -1).forEach((jsonValue) => controller.enqueue(JSON.parse(jsonValue)))

  //       ndjsonBuffer = jsonValues[jsonValues.length - 1]
  //     },
  //     flush(controller) {
  //       if (!ndjsonBuffer) return
  //       controller.enqueue(JSON.parse(ndjsonBuffer))
  //     },
  //   })
  // }

  const handleSendFile = async () => {
    const form = new FormData()
    form.append('file', file)
    form.append('user_id', getUserId())

    setTimeout(() => {
      onOpen(false)
      setActiveKey('1')
    }, 500)

    await fetch(`https://ms-import.rock-at.com/import/${context}/stream`, {
      method: 'POST',
      body: form as any,
      headers: {
        user_id: getUserId(),
      },
    })
  }

  const stepsAdd = [
    {
      title: 'Importação',
      content: (
        <UploadDocument
          setFile={setFile}
          header={header}
          setAmountHeader={setAmountHeader}
          amountHeader={amountHeader}
          context={context}
          setLoading={setLoading}
          file={file}
        />
      ),
    },
    // {
    //   title: 'Processamento',
    //   content: (
    //     <ViewProcess
    //       contextType={context}
    //       amountHeader={amountHeader}
    //       dataProccessSuccess={memoSuccess}
    //       dataProccessWarning={dataProccessWarning}
    //       dataProccessError={dataProccessError}
    //       isProccesing={isProccesing}
    //     />
    //   ),
    // },
  ]

  const getContext = async () => {
    try {
      const { data } = await api.get(`/ms-import/template/${context}`)
      if (data.header && data.header.length > 0) {
        setHeader([...data.header])
      }
    } catch (error) {
      message.error('Erro ao carregar importação.')
    }
  }

  useEffect(() => {
    if (isProccesing) {
      setCurrent(1)
    } else {
      // setDataProccessError([])
      // setDataProccessSuccess([])
      // setDataProccessWarning([])
      setCurrent(0)
    }
    getContext()
    setFile(undefined)
  }, [open])

  // useEffect(() => {
  //   if (current === stepsAdd.length - 1) handleSendFile()
  // }, [current])

  return (
    <DrawerStyle
      title={`Importar ${title}`}
      placement="left"
      closable={true}
      onClose={() => onOpen(false)}
      open={open}
      key="left"
      width="100%"
      destroyOnClose={true}
    >
      <div
        style={{
          padding: '0 74px',
        }}
      >
        <Steps
          current={current}
          items={stepsAdd.map((item) => ({
            key: item.title,
            title: item.title,
          }))}
        />
        <div>{stepsAdd[current].content}</div>
        <div
          style={{
            marginTop: 24,
            display: 'flex',
            alignItems: 'center',
            justifyContent: current > 0 ? 'space-between' : 'flex-end',
          }}
        >
          {current > 0 && (
            <ButtonSteps style={{ margin: '0 8px' }} onClick={() => prev()}>
              Voltar
            </ButtonSteps>
          )}
          {current < stepsAdd?.length - 1 && <ButtonSteps onClick={async () => next()}>Avançar</ButtonSteps>}
          {current === stepsAdd?.length - 1 && (
            <ButtonSteps
              onClick={() => handleSendFile()}
              disabled={loading || !file ? true : false}
              style={{
                backgroundColor: loading || !file ? lightColor.blackText : lightColor.primary,
                cursor: loading || !file ? 'initial' : 'pointer',
              }}
            >
              {loading && <Spin />}
              Finalizar
            </ButtonSteps>
          )}
        </div>
      </div>
    </DrawerStyle>
  )
}
