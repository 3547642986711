import styled from 'styled-components'
import { darkColor, lightColor } from '../../../utils/styleColors'
import { useDark } from '../../../context/contextDark'

interface Iprops {
  active: boolean
}
export const ContainerFilterWorkspace = styled.div<Iprops>`
  background-color: ${({ active }) => (active ? lightColor.primary : 'transparent')};
  color: ${({ active }) => active && lightColor.white};
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  padding: 5px 10px;
  &:hover {
    background-color: ${lightColor.primary};
    color: ${lightColor.white};
    transition: all 0.3s;
  }

  span {
    max-width: 140px;
    white-space: nowrap;
    padding-right: 12px;
    font-size: 14px;
    font-weight: 400;
  }

  ${() => {
    const { isDarkMode } = useDark()

    if (isDarkMode)
      return `
      color: ${darkColor.white};
    `

    return ''
  }}
`

export const Portal = styled.div`
  width: 100%;
  width: 509px;
  min-height: 132px;
  background-color: ${lightColor.white};
  border-radius: 8px;
  color: ${lightColor.blackTextBold};
  padding: 16px;
  box-sizing: content-box;

  h3 {
    font-weight: bold;
  }

  box-shadow: 0 6px 20px -2px rgba(0, 0, 0, 0.2);

  ${() => {
    const { isDarkMode } = useDark()

    if (isDarkMode)
      return `
      background-color: ${darkColor.bgTable};
      color: ${darkColor.white} !important;

      p {
        color: ${darkColor.white} !important;
      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        color: ${darkColor.white} !important;
        background-color: ${darkColor.bgTable} !important;
      }

      .ant-select-arrow {
        color: ${darkColor.white} !important;
      }

      .ant-input {
        color: ${darkColor.white} !important;
        background-color: ${darkColor.bgTable} !important;
      }
    `

    return ''
  }}
`

export const Workspace = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px 0px;
  border: 1px solid ${lightColor.white};

  p {
    font-size: 15px;
    text-align: center;
    margin: 0;
  }

  :hover {
    border: 1px solid ${lightColor.primary};
    transition: all 0.3s;
  }
`

export const OcultButton = styled.div`
  margin-top: 10px;
  padding: 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 170px;
  cursor: pointer;
  border: 1px solid ${lightColor.white};
  p {
    margin: 0;
    margin-left: 10px;
    font-size: 14px;
    color: ${lightColor.blackTextBold};
  }

  :hover {
    transition: all 0.3s;
    border-color: ${lightColor.primary};
  }
`
