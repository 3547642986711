import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import { Controller } from 'react-hook-form'
import { darkColor, lightColor } from '../../utils/styleColors'
import { useDark } from '../../context/contextDark'
import { ContainerDate } from './style'

interface IProps {
  required?: string
  name: string
  label: string
  control: any
  disabled?: boolean
  type?: string
  style?: any
}

export const HookDate = ({ control, name, label, required, disabled }: IProps) => {
  const { isDarkMode } = useDark()

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required || undefined,
      }}
      render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
        <ContainerDate>
          <p
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              color: lightColor.blackTextBold,
              marginBottom: 8,
              opacity: 0.74,
            }}
          >
            {label}
          </p>
          <DatePicker
            onChange={(date) => onChange(dayjs(date).format('YYYY-MM-DD'))}
            ref={ref}
            format="DD/MM/YYYY"
            id={name}
            value={value && dayjs(value, 'YYYY/MM/DD')}
            placeholder={`${label}`}
            disabled={disabled}
            dropdownClassName={isDarkMode ? 'dark-drop-down' : ''}
            style={{
              width: '100%',
              background: isDarkMode ? darkColor.bgTable : lightColor.white,
            }}
          />

          {!!error && (
            <p
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                color: lightColor.redBold,
                marginBottom: 8,
                opacity: 0.74,
              }}
            >
              {error?.message?.toString()}
            </p>
          )}
        </ContainerDate>
      )}
    />
  )
}
