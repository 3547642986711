import { ContainerModal } from './style'
import { MultiplePutAway } from './multiplePutAway'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  promoters: any[]
}

export const ModalPutAwayNative = ({ visible, close, promoters }: IProps) => {
  return (
    <ContainerModal
      open={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      style={{ top: 60 }}
      destroyOnClose={true}
      width="90vw"
    >
      <h3>Afastar promotor</h3>
      {visible && <MultiplePutAway promoters={promoters} />}
    </ContainerModal>
  )
}
