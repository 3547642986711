import { useEffect, useRef } from 'react'
import { Controller } from 'react-hook-form'
import IntlCurrencyInput from 'react-intl-currency-input'
import { lightColor } from '../../utils/styleColors'

interface IProps {
  required?: string
  name: string
  label: string
  control: any
  disabled?: boolean
  type?: string
  style?: any
}

export const HookInputMoney = ({ control, name, label, required, disabled }: IProps) => {
  const inputRef = useRef<any>(null)

  useEffect(() => {
    inputRef.current.disabled = disabled
    inputRef.current.className = 'inputMask'
  }, [disabled])

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required || undefined,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div>
          <p
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              color: lightColor.blackTextBold,
              marginBottom: 8,
              opacity: 0.74,
            }}
          >
            {label}
          </p>

          <IntlCurrencyInput
            currency="BRL"
            config={{
              locale: 'pt-BR',
              formats: {
                number: {
                  BRL: {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                },
              },
            }}
            value={value}
            onChange={(a, float) => onChange(float)}
            max={0}
            defaultValue={value}
            inputRef={inputRef}
          />
          {!!error && (
            <p
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                color: lightColor.redBold,
                marginBottom: 8,
                opacity: 0.74,
              }}
            >
              {error?.message?.toString()}
            </p>
          )}
        </div>
      )}
    />
  )
}
