import { Spin, Upload, UploadFile } from 'antd'
import { useEffect, useState } from 'react'
import { Controller } from 'react-hook-form'
import { app } from '../../utils/firebase'
import { getDownloadURL, getStorage, ref as refStorage, uploadBytes } from 'firebase/storage'
import { v4 as uuidv4 } from 'uuid'
import { RcFile } from 'antd/es/upload'
import { lightColor } from '../../utils/styleColors'

interface IProps {
  required?: string
  name: string
  label: string
  control: any
  disabled?: boolean
  style?: any
}

export const HookFile = ({ control, name, label, required }: IProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj as RcFile)
        reader.onload = () => resolve(reader.result as string)
      })
    }
    const image = new Image()
    image.src = src
    const imgWindow = window.open(src)
    imgWindow?.document.write(image.outerHTML)
  }

  useEffect(() => {
    if (control._formValues[name])
      setFileList([
        {
          uid: '-1',
          name: 'sdf',
          status: 'done',
          url: control._formValues[name],
        },
      ])
  }, [control])

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required || undefined,
      }}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <div>
          <p
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              color: lightColor.blackTextBold,
              marginBottom: 8,
              opacity: 0.74,
            }}
          >
            {label}
          </p>
          <Upload
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-circle"
            fileList={fileList}
            onPreview={onPreview}
            onChange={async (evt) => {
              try {
                if (evt.file.status === 'removed') {
                  setFileList([])
                  onChange(null)
                  return
                }

                const file = getStorage(app, 'gs://rocketpdv-dev.appspot.com')
                const files: any = evt.file
                const storageRef = refStorage(file, `workspace/${uuidv4()}${files.name}`)
                setLoading(true)
                await uploadBytes(storageRef, files.originFileObj).then(async (snapshot) => {
                  await getDownloadURL(snapshot.ref).then((downloadURL) => {
                    setFileList([
                      {
                        uid: '-1',
                        name: files.name,
                        status: 'done',
                        url: downloadURL,
                      },
                    ])
                    onChange(downloadURL)
                  })
                })
                setLoading(false)
              } catch (error) {
                setLoading(false)
              }
            }}
          >
            {/* {loading && <Spin />} */}
            {fileList?.length === 0 && '+ Upload'}
          </Upload>

          {loading && <Spin style={{ marginLeft: 40 }} />}
          {!!error && (
            <p
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                color: lightColor.redBold,
                marginBottom: 8,
                opacity: 0.74,
              }}
            >
              {error?.message?.toString()}
            </p>
          )}
        </div>
      )}
    />
  )
}
