import React, { useEffect, useMemo, useState } from 'react'
import { TableDefault } from '../Components/table'
import api from '../api'
import { DefaultFilters } from '../Components/defaultFilters'
import DefaultPage from '../Components/defaultPage'
import { UserSwitchOutlined, ClockCircleOutlined, ApiOutlined, LockOutlined } from '@ant-design/icons'
import { ActionsNative } from '../Components/ActionsNative'
import { ModalPromoterOff } from '../Components/ModalPromoterOff'
import { ModalMigratePromoterNative } from '../Components/ModalMigratePromoterNative'
import { ModalPasswordNative } from '../Components/ModalPasswordNative'
import { ModalPutAwayNative } from '../Components/ModalPutAwayNative'
import { debounce } from 'lodash'
import { Header } from '../Components/header'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { DeafultTitleTab } from '../Components/deafultTitleTab'
import { BsHouseDoor } from 'react-icons/bs'
import { useDark } from '../context/contextDark'
import { darkColor, lightColor } from '../utils/styleColors'
import { ImportDrawer } from '../Components/ImportDrawer'
import { ExportXLS } from '../Components/DrawerExport'
import { ModalCreatePromoter } from '../Components/ModalCreatePromoter'
import { IPropsFilter, IPropsPageContext } from './statusDay'
import { initialPagination } from '../utils/initials'
import { TaggFilter } from '../Components/TagFilter'
import { addIsScriptIsPermited } from '../utils/blockUser'
import { ImportOff } from '../Components/ImportDrawer/ImportOff'
import { ImportPut } from '../Components/ImportDrawer/ImportPut'
import FileSaver from 'file-saver'
export interface ISelect {
  value: string
  label: string
}

export interface IPropsRenderPage {
  colunms: any[]
  filter_options: any[]
  sort_options: any[]
}

const PromoterPage = ({ context, title }: IPropsPageContext) => {
  const [columnOrigin, setColumnOrigin] = useState<IPropsRenderPage>({} as IPropsRenderPage)
  const [columnInfo, setColumnInfo] = useState<any[]>([])
  const [columns, setColumns] = useState<any>([])
  const [selectds, setSelecteds] = useState<any>([])
  const [data, setData] = useState<any>([])
  const [filters, setFilters] = useState<IPropsFilter>({ ...initialPagination } as IPropsFilter)
  const [openPromoterOff, setOpenPromoterOff] = useState(false)
  const [loading, setLoading] = useState(false)
  const [openMigratePromoter, setOpenMigratePromoter] = useState(false)
  const [openRemove, setOpenRemove] = useState(false)
  const [openChangePassword, setOpenChangePassword] = useState(false)
  const [uniquePromoter, setUniquePromoter] = useState<any>({})
  const [openImport, setOpenImport] = useState(false)
  const [openImportOff, setOpenImportOff] = useState(false)
  const [openImportPut, setOpenImportPut] = useState(false)
  const [openExport, setOpenExport] = useState(false)
  const [openCreatePromoter, setOpenCreatePromoter] = useState(false)
  const [searchValue, setSearchValue] = useState<string>('')

  const getColumns = async () => {
    const { data } = await api.get(`/ms-import/view/${context}`)
    setColumnOrigin(data)
    setColumns(data.colunms)
  }

  const changeColumn = (list: string[]) => {
    const newList: any = []
    if (columnOrigin.colunms) {
      columnOrigin.colunms.forEach((column: any) => {
        column.ellipsis = true
        if (column.required) newList.push(column)
        if (list.indexOf(column.title) > -1) newList.push(column)
      })

      setColumns([...newList])
    }
  }

  const getPromoters = async () => {
    try {
      setLoading(true)
      const { workspace, order, filter, page, pageSize } = filters
      const body: any = {}
      if (workspace && workspace.length > 0) body.workspace_id = workspace?.map((item) => item.id.toString())

      if (order && order.length > 0)
        body.sort = order?.map((item) => ({
          field: item.column?.value,
          direction: item.order?.value,
        }))

      if (filter && filter.length)
        body.filter = filter.map((item) => ({
          field: item.column?.value,
          condition: item.condition?.value,
          value: item.value,
        }))

      if (body.filter?.indexOf((item: any) => item.field === 'name') === -1 && searchValue)
        body.filter = [
          ...body.filter,
          {
            field: 'name',
            condition: 'LIKE',
            value: searchValue,
          },
        ]

      if (!body.filter && searchValue)
        body.filter = [
          {
            field: 'name',
            condition: 'LIKE',
            value: searchValue,
          },
        ]
      if (page || pageSize)
        body.pagination = {
          page,
          perpage: pageSize,
        }
      const { data } = await api.post('/ms-promoter/list', body)
      setData(data.result.map((item: any) => ({ ...item, key: item.id })))
    } catch (error) {
      console.log(error)
      return null
    } finally {
      setLoading(false)
    }
  }

  const getColumnsInfo = async () => {
    const { data } = await api.get(`/ms-import/template/${context}`)
    setColumnInfo(data?.header || [])
  }

  useEffect(() => {
    getPromoters()
    getColumns()
    getColumnsInfo()
  }, [])

  useEffect(() => {
    getPromoters()
  }, [filters])

  const onSearch = async (value: any) => {
    try {
      setLoading(true)
      const { workspace, order, filter, page, pageSize } = filters
      const body: any = {}
      if (workspace && workspace.length > 0) body.workspace_id = workspace?.map((item) => item.id.toString())

      if (order && order.length > 0)
        body.sort = order?.map((item) => ({
          field: item.column?.value,
          direction: item.order?.value,
        }))

      if (filter && filter.length) {
        const filterList = []
        filter.forEach((item) => {
          if (item.column?.value === 'name') return

          filterList.push({
            field: item.column?.value,
            condition: item.condition?.value,
            value: item.value,
          })
        })
      }

      if (page || pageSize)
        body.pagination = {
          page,
          perpage: pageSize,
        }

      body.filter = [
        {
          field: 'name',
          condition: 'LIKE',
          value: value,
        },
      ]

      setSearchValue(value)
      const { data } = await api.post('/ms-promoter/list', body)
      setData(data.result.map((item: any) => ({ ...item, key: item.id })))
    } catch (error) {
      return null
    } finally {
      setLoading(false)
    }
  }

  const debouncedChangeHandler = useMemo(() => debounce(onSearch, 300), [])

  return (
    <>
      <DefaultFilters
        columns={columns}
        updateColumn={(list) => changeColumn(list)}
        changeWorkspace={(workspace) => setFilters({ ...filters, workspace })}
        changeOrder={(order) => setFilters({ ...filters, order })}
        changeFilter={(filter) => setFilters({ ...filters, filter })}
        filter={filters.filter}
        columnsOrigin={columnOrigin}
        setSearch={async (value) => debouncedChangeHandler(value)}
        clickButton={() => setOpenCreatePromoter(true)}
        simpleFilter={[
          {
            name: 'nome',
            type: 'text',
            label: 'Nome do promotor',
            mask: '',
          },
          {
            name: 'cpf',
            type: 'text',
            label: 'CPF do promotor',
            mask: '999.999.999-99',
          },
          {
            name: 'uf_sigla',
            type: 'select',
            label: 'UF',
            options: [
              { value: 'AC', label: 'AC' },
              { value: 'AL', label: 'AL' },
              { value: 'AP', label: 'AP' },
              { value: 'AM', label: 'AM' },
              { value: 'BA', label: 'BA' },
              { value: 'CE', label: 'CE' },
              { value: 'DF', label: 'DF' },
              { value: 'ES', label: 'ES' },
              { value: 'GO', label: 'GO' },
              { value: 'MA', label: 'MA' },
              { value: 'MT', label: 'MT' },
              { value: 'MS', label: 'MS' },
              { value: 'MG', label: 'MG' },
              { value: 'PA', label: 'PA' },
              { value: 'PB', label: 'PB' },
              { value: 'PR', label: 'PR' },
              { value: 'PE', label: 'PE' },
              { value: 'PI', label: 'PI' },
              { value: 'RJ', label: 'RJ' },
              { value: 'RN', label: 'RN' },
              { value: 'RS', label: 'RS' },
              { value: 'RO', label: 'RO' },
              { value: 'RR', label: 'RR' },
              { value: 'SC', label: 'SC' },
              { value: 'SP', label: 'SP' },
              { value: 'SE', label: 'SE' },
              { value: 'TO', label: 'TO' },
            ],
          },
          {
            name: 'is_active',
            type: 'select',
            label: 'Ativo',
            options: [
              { value: 'true', label: 'Sim' },
              { value: 'false', label: 'Não' },
            ],
          },
          {
            name: 'removals',
            type: 'select',
            label: 'Afastado',
            options: [
              { value: 'true', label: 'Sim' },
              { value: 'false', label: 'Não' },
            ],
          },
        ]}
        actionsMenu={[
          {
            disabled: addIsScriptIsPermited(),
            label: 'Importar',
            key: '1',
            onClick: () => {
              setOpenImport(!openImport)
            },
          },
          {
            label: 'Exportar',
            key: '2',
            onClick: () => {
              setOpenExport(!openExport)
            },
          },
          {
            disabled: addIsScriptIsPermited(),
            label: 'Desligamento',
            key: '3',
            onClick: () => {
              setOpenImportOff(!openImportOff)
            },
          },
          {
            label: 'Exportar desligamento',
            key: '6',
            onClick: async () => {
              try {
                setLoading(true)
                const body: any = {}

                const { workspace, order, filter } = filters
                if (workspace && workspace.length > 0) {
                  body.workspace_id = workspace?.map((item) => item.id.toString())
                }

                if (order && order.length > 0) {
                  body.sort = order?.map((item) => ({
                    field: item.column?.value,
                    direction: item.order?.value,
                  }))
                }

                if (filter && filter.length) {
                  body.filter = filter.map((item) => ({
                    field: item.column?.value,
                    condition: item.condition?.value,
                    value: item.value,
                  }))
                }

                const { data } = await api.post('/ms-import/export/desligamento', body, {
                  responseType: 'arraybuffer',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                })

                const blob = new Blob([data], {
                  type: 'application/xlsx',
                })
                FileSaver.saveAs(blob, 'desligamento.xlsx')
              } catch (erro) {
                console.log(erro)
              } finally {
                setLoading(false)
              }
            },
          },
          {
            disabled: addIsScriptIsPermited(),
            label: 'Afastamento',
            key: '4',
            onClick: () => {
              setOpenImportPut(!openImportPut)
            },
          },
          {
            label: 'Exportar afastamentos',
            key: '2',
            onClick: async () => {
              try {
                setLoading(true)
                const body: any = {}

                const { workspace, order, filter } = filters
                if (workspace && workspace.length > 0) {
                  body.workspace_id = workspace?.map((item) => item.id.toString())
                }

                if (order && order.length > 0) {
                  body.sort = order?.map((item) => ({
                    field: item.column?.value,
                    direction: item.order?.value,
                  }))
                }

                if (filter && filter.length) {
                  body.filter = filter.map((item) => ({
                    field: item.column?.value,
                    condition: item.condition?.value,
                    value: item.value,
                  }))
                }

                const { data } = await api.post('/ms-import/export/afastamento', body, {
                  responseType: 'arraybuffer',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                })

                const blob = new Blob([data], {
                  type: 'application/xlsx',
                })
                FileSaver.saveAs(blob, 'afastamento.xlsx')
              } catch (erro) {
                console.log(erro)
              } finally {
                setLoading(false)
              }
            },
          },
        ]}
      />
      <TaggFilter filters={filters} setFilters={setFilters} />
      <TableDefault
        reload={async () => getPromoters()}
        headerOrigin={columnInfo}
        pagination={false}
        data={data}
        column={columns}
        loading={loading}
        total={data?.length}
        checks={{
          keyCheckItem(data) {
            setSelecteds(data)
          },
          keyCheckAll(data) {
            setSelecteds(data)
          },
          selectAllPages() {
            return data.map((item: any) => item.key)
          },
        }}
        menu={[
          {
            label: 'Desligar',
            onClick: (data) => {
              setUniquePromoter(data)
              setOpenPromoterOff(true)
            },
            key: 2,
            icon: <ApiOutlined />,
          },
          {
            label: 'Afastar',
            onClick: (data) => {
              setUniquePromoter(data)
              setOpenRemove(true)
            },
            key: 1,
            icon: <ClockCircleOutlined />,
          },

          {
            label: 'Mudar senha',
            onClick: (data) => {
              setUniquePromoter(data)
              setOpenChangePassword(true)
            },
            key: 3,
            icon: <LockOutlined />,
          },
          {
            label: 'Migrar ambiente',
            onClick: (data) => {
              setUniquePromoter(data)
              setOpenMigratePromoter(true)
            },
            key: 4,
            icon: <UserSwitchOutlined />,
          },
        ]}
      />
      <ActionsNative visible={selectds?.length > 0} promoters={selectds} />
      <ModalPromoterOff visible={openPromoterOff} close={setOpenPromoterOff} promoters={[uniquePromoter]} />
      <ModalMigratePromoterNative
        visible={openMigratePromoter}
        close={setOpenMigratePromoter}
        promoters={[uniquePromoter]}
      />
      <ModalPasswordNative visible={openChangePassword} close={setOpenChangePassword} promoters={[uniquePromoter]} />
      <ModalPutAwayNative promoters={[uniquePromoter]} visible={openRemove} close={setOpenRemove} />
      <ImportDrawer title={title} open={openImport} onOpen={setOpenImport} context={context} />
      <ImportOff open={openImportOff} onOpen={setOpenImportOff} />
      <ImportPut open={openImportPut} onOpen={setOpenImportPut} />
      <ExportXLS
        setOpenExport={setOpenExport}
        context="promoter"
        openExport={openExport}
        title={title}
        filters={filters}
      />
      <ModalCreatePromoter
        visible={openCreatePromoter}
        close={setOpenCreatePromoter}
        reload={async () => getPromoters()}
      />
    </>
  )
}

const Promoter = (body: IPropsPageContext) => {
  const { isDarkMode } = useDark()

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <DeafultTitleTab title="Padrão" icon={<BsHouseDoor fontSize={20} />} />,
      children: PromoterPage(body),
      style: {
        color: isDarkMode ? darkColor.white : lightColor.blackTextBold,
      },
    },
  ]

  return (
    <DefaultPage>
      <Header title={body.titlePage} />
      <div className={isDarkMode ? 'dark-tab' : 'light-tab'}>
        <Tabs defaultActiveKey="1" items={items} animated={true} />
      </div>
    </DefaultPage>
  )
}

export default Promoter
