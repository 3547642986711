import { Modal, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { ContentTab } from './contentTab'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { lightColor } from '../../../utils/styleColors'

interface IProps {
  header: any[]
  valueRow: any
  reload(): void
  open: boolean
  change: boolean
  setChange: any
}

const InfoCard = ({ header, valueRow, reload, open, change, setChange }: IProps) => {
  const [group, setGroup] = useState<string[]>([])
  const [key, setKey] = useState('0')
  const [modal, contextHolder] = Modal.useModal()
  const [defaultValuesRow, setDefaultValuesRow] = useState<any>(valueRow)

  useEffect(() => {
    if (header && header.length > 0) {
      const list: string[] = []

      header.forEach((item) => {
        if (list.indexOf(item.group) === -1) list.push(item.group)
      })
      setGroup(list)
    }
  }, [header])

  useEffect(() => {
    setChange(false)
  }, [open])

  useEffect(() => {
    setChange(false)
  }, [key])

  useEffect(() => {
    setDefaultValuesRow(valueRow)
  }, [valueRow])
  return (
    <>
      <Tabs
        defaultActiveKey="1"
        tabPosition="left"
        activeKey={key}
        style={{ marginTop: 20 }}
        onChange={(activeKey) => {
          if (!change) return setKey(activeKey)
          modal.confirm({
            title: 'Deseja mudar de tab ?',
            icon: <ExclamationCircleFilled />,
            content: 'Você realizou mudanças no cadastro, ao mudar de tab você irá perder essas mudanças.',
            onOk() {
              setChange(false)
              setKey(activeKey)
            },
            onCancel() {
              return null
            },
          })
        }}
        destroyInactiveTabPane={true}
        items={group.map((item, i) => {
          return {
            label: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {item}{' '}
                {i.toString() === key && change && (
                  <div
                    style={{
                      width: 8,
                      height: 8,
                      borderRadius: 4,
                      backgroundColor: lightColor.yellowPrimary,
                      marginLeft: 10,
                    }}
                  />
                )}
              </div>
            ),
            key: i.toString(),
            disabled: i === 28,
            children: (
              <ContentTab
                names={header.filter((names) => names.group === item)}
                valueRow={defaultValuesRow}
                reload={reload}
                changeTab={setChange}
                change={change}
                setDefaultValuesRow={setDefaultValuesRow}
              />
            ),
          }
        })}
      />
      {contextHolder}
    </>
  )
}

export default InfoCard
