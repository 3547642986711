import styled from 'styled-components'
import { darkColor, lightColor } from '../../../utils/styleColors'
import { useDark } from '../../../context/contextDark'

interface Iprops {
  active: boolean
}
export const ContainerFilterWorkspace = styled.div<Iprops>`
  background-color: ${({ active }) => (active ? lightColor.primary : 'transparent')};
  color: ${({ active }) => active && lightColor.white};
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
  padding: 5px 10px;
  &:hover {
    background-color: ${lightColor.primary};
    color: ${lightColor.white};
    transition: all 0.3s;
  }

  span {
    max-width: 140px;
    white-space: nowrap;
    padding-right: 12px;
    font-size: 14px;
    font-weight: 400;
  }

  ${() => {
    const { isDarkMode } = useDark()

    if (isDarkMode)
      return `
      color: ${darkColor.white};
    `

    return ''
  }}
`

export const Portal = styled.div`
  width: 459px;
  min-height: 132px;
  max-height: 250px;
  overflow: auto;
  background-color: ${lightColor.white};
  border-radius: 8px;
  color: ${lightColor.blackTextBold};
  padding: 16px;
  box-sizing: content-box;

  h3 {
    font-weight: bold;
  }

  ${() => {
    const { isDarkMode } = useDark()

    if (isDarkMode)
      return `
      background-color: ${darkColor.bgTable};
      color: ${darkColor.white};
    `

    return ''
  }}
`

interface IPropsActive {
  active: boolean
}

export const Workspace = styled.div<IPropsActive>`
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px 4px;
  border: 1px solid ${({ active }) => (active ? lightColor.primary : lightColor.white)};
  cursor: pointer;

  p {
    font-size: 14px;
    text-align: center;
    margin: 0;
  }

  :hover {
    border: 1px solid ${lightColor.primary};
    transition: all 0.3s;
  }
`
