import React from 'react'
import { Menu } from '../menu'
import { darkColor, lightColor } from '../../utils/styleColors'
import { useDark } from '../../context/contextDark'

export interface ISelect {
  value: string
  label: string
}

function DefaultPage({ children }: any) {
  const { isDarkMode } = useDark()

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        backgroundColor: isDarkMode ? darkColor.backgroundColor : lightColor.white,
        minHeight: '100vh',
      }}
    >
      <Menu />
      <div
        style={{
          marginLeft: 30,
          width: '95%',
          padding: '10px 40px 10px 80px',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default DefaultPage
