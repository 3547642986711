import { cloneElement, ReactElement } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { lightColor } from '../../utils/styleColors'
interface ActiveLinkProps {
  children: ReactElement
  shoudMatchExactHref?: boolean
  href: string
}

export default function ActiveLink({ children, shoudMatchExactHref = true, href }: ActiveLinkProps) {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  let isActive = false

  if (shoudMatchExactHref && pathname === href) {
    isActive = true
  }

  if (!shoudMatchExactHref && pathname.startsWith(String(href))) {
    isActive = true
  }
  return (
    <div onClick={() => navigate(href)} style={{ cursor: 'pointer' }}>
      {cloneElement(children, {
        color: isActive ? lightColor.activeLink : lightColor.white,
      })}
    </div>
  )
}
