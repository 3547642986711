import { useEffect, useState } from 'react'
import { ContainerFilterWorkspace, OcultButton, Portal } from './style'
import { BiFilterAlt } from 'react-icons/bi'
import { Button, Col, Input, Row, Select } from 'antd'
import { IoMdAdd } from 'react-icons/io'
import { GrFormClose } from 'react-icons/gr'
import { BsEraser } from 'react-icons/bs'
import { IPropsRenderPage, ISelect } from '../../../pages/promoter'
import { Popover } from 'react-tiny-popover'
import { CiCircleRemove } from 'react-icons/ci'
import { darkColor, lightColor } from '../../../utils/styleColors'
import { useDark } from '../../../context/contextDark'
import { CgArrowsExchangeAltV } from 'react-icons/cg'
import { useForm } from 'react-hook-form'
import { HookInput } from '../../form/hookInput'
import { HookSelectedFixed } from '../../form/hookSelectedFixed'
import { DrawerStyle } from '../../ImportDrawer/style'
import { HookSelectedRemote } from '../../form/HookSelectedRemote'
import { ContainerModal } from '../../ModalMigratePromoterNative/style'

export interface IFilter {
  column: ISelect | undefined
  condition: ISelect | undefined
  value: string | undefined
  where?: ISelect
}

export interface IPropsTypeFilter {
  name: string
  type: string
  label: string
  mask?: string
  url?: string
  options?: {
    value: any
    label: string
  }[]
}

interface IProps {
  columns: any
  columnsOrigin: IPropsRenderPage
  changeFilter(filter: IFilter[]): void
  simpleFilter?: IPropsTypeFilter[]
  filter: any
}

export const Filter = ({ columnsOrigin, changeFilter, simpleFilter, filter }: IProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [count, setCount] = useState<number>(0)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [listFilter, setListFilter] = useState<IFilter[]>([
    { column: undefined, condition: undefined, value: undefined },
  ])

  const calcFilter = () => {
    if (listFilter?.length > -1) return setCount(listFilter?.length)
    if (listFilter[0].column && listFilter[0].condition && listFilter[0].value) return setCount(1)
    setCount(0)
  }
  useEffect(() => {
    if (
      listFilter.filter((item) => item.column === undefined || item.condition === undefined || item.value === undefined)
        .length === 0
    ) {
      changeFilter(listFilter)
    } else {
      changeFilter([])
    }
    calcFilter()
  }, [listFilter])
  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        content={() => (
          <FormFilter
            columns={columnsOrigin.filter_options}
            listFilter={listFilter}
            setListFilter={setListFilter}
            setIsPopoverOpen={setIsPopoverOpen}
            setOpen={setOpen}
          />
        )}
        positions={['bottom']}
      >
        <ContainerFilterWorkspace active={false} onClick={() => setOpen(true)}>
          <BiFilterAlt style={{ marginRight: 10 }} />
          <span>Filtro {count > 1 && `/ ${count}`}</span>
        </ContainerFilterWorkspace>
      </Popover>
      <FilterDrawer
        onCloseDrawer={(value: any) => setOpen(value)}
        open={open}
        simpleFilter={simpleFilter || []}
        setListFilter={changeFilter}
        onCloseModal={setOpenModal}
        hasAdvanced={columnsOrigin.filter_options?.length > 0}
        listFilter={listFilter}
        filter={filter}
      />
      <FilterModal
        modalOpen={openModal}
        onCloseModal={setOpenModal}
        columns={columnsOrigin.filter_options}
        listFilter={listFilter}
        setListFilter={setListFilter}
        setOpen={setOpen}
      />
    </>
  )
}

interface IPropsFormFilter {
  columns: any
  listFilter: any
  setListFilter: any
  setIsPopoverOpen: any
  setOpen: any
}

const FormFilter = ({ columns, listFilter, setListFilter, setIsPopoverOpen, setOpen }: IPropsFormFilter) => {
  const { isDarkMode } = useDark()
  const [typeFilter, setTypeFilter] = useState<'ADVANCED' | 'SIMPLE'>('ADVANCED')
  return (
    <Portal>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 10,
        }}
      >
        <h3 style={{ margin: 0 }}>Escolher colunas para exibir</h3>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <OcultButton
            style={{
              width: 120,
              marginBottom: 13,
            }}
            onClick={() => {
              setListFilter([{ column: undefined, condition: undefined, value: undefined }])
            }}
          >
            <BsEraser />
            <p>Limpar filtro</p>
          </OcultButton>
          <CiCircleRemove
            style={{
              color: lightColor.red,
              fontSize: 20,
              marginLeft: 10,
              cursor: 'pointer',
            }}
            onClick={() => setIsPopoverOpen(false)}
          />
        </div>
      </div>
      {listFilter?.length > 0 &&
        listFilter.map((list: any, index: any) => (
          <Row gutter={[15, 10]} style={{ marginBottom: 15 }} key={index}>
            <Col span={3}>
              {index === 0 ? (
                <p style={{ fontSize: 15, width: '100%' }}>Onde</p>
              ) : (
                <Select
                  style={{ width: '100%' }}
                  value={list.where ? list.where : undefined}
                  onChange={(value, selected) => {
                    const newList: any = listFilter
                    newList[index].where = selected
                    setListFilter([...newList])
                  }}
                  popupClassName={isDarkMode ? 'dark-drop-down' : ''}
                  options={[
                    { label: 'e', value: 'AND' },
                    { label: 'Ou', value: 'or' },
                  ]}
                />
              )}
            </Col>
            <Col span={6}>
              <Select
                style={{ width: '100%' }}
                onChange={(value, selected) => {
                  const newList: any = listFilter
                  newList[index].column = selected
                  setListFilter([...newList])
                }}
                popupClassName={isDarkMode ? 'dark-drop-down' : ''}
                value={list.column ? list.column : undefined}
                options={[
                  {
                    label: 'Colunas',
                    options: columns?.map((column: any) => ({
                      value: column.key,
                      label: column.label,
                    })),
                  },
                ]}
              />
            </Col>
            <Col span={6}>
              <Select
                style={{ width: '100%' }}
                onChange={(value, selected) => {
                  const newList: any = listFilter
                  newList[index].condition = selected

                  newList[index].value = undefined
                  setListFilter([...newList])
                }}
                value={list.condition ? list.condition : undefined}
                popupClassName={isDarkMode ? 'dark-drop-down' : ''}
                options={[
                  {
                    label: 'Condição',
                    options: [
                      { label: 'Igual á', value: '=' },
                      { label: 'Diferente de', value: '!=' },
                      { label: 'Contem', value: 'LIKE' },
                      { label: 'N/ contem', value: 'NOT LIKE' },
                    ],
                  },
                ]}
              />
            </Col>
            <Col span={6}>
              <Input
                value={list.value ? list.value : undefined}
                onChange={(value) => {
                  const newList: any = listFilter
                  newList[index].value = value.target.value
                  setListFilter([...newList])
                }}
              />
            </Col>
            <Col span={1}>
              {index > 0 && (
                <GrFormClose
                  fontSize={20}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const newList: any = []
                    listFilter.forEach((item: any, indexRemove: any) => {
                      if (indexRemove !== index) newList.push(item)
                    })

                    setListFilter([...newList])
                  }}
                  color={isDarkMode ? darkColor.white : lightColor.red}
                />
              )}
            </Col>
          </Row>
        ))}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <OcultButton
          onClick={() =>
            setListFilter([
              ...listFilter,
              {
                column: undefined,
                condition: undefined,
                value: undefined,
              },
            ])
          }
        >
          <IoMdAdd />
          <p>Adicionar novo filtro</p>
        </OcultButton>
        <OcultButton
          onClick={() => {
            setTypeFilter(typeFilter === 'ADVANCED' ? 'SIMPLE' : 'ADVANCED')
            setIsPopoverOpen(false)
            setOpen(true)
          }}
        >
          <CgArrowsExchangeAltV style={{ fontSize: 30 }} />
          <p>{typeFilter === 'ADVANCED' ? 'Alterar para filtro simplificado' : 'Alterar para filtro avançado'} </p>
        </OcultButton>
      </div>
    </Portal>
  )
}

interface IpropsDrawer {
  onCloseDrawer: any
  open: any
  setListFilter: any
  simpleFilter: IPropsTypeFilter[]
  hasAdvanced: boolean
  onCloseModal: any
  listFilter: any
  filter: any
}

const FilterDrawer = ({
  onCloseDrawer,
  open,
  simpleFilter,
  setListFilter,
  hasAdvanced,
  onCloseModal,
  listFilter,
  filter,
}: IpropsDrawer) => {
  const { handleSubmit, reset, control, resetField } = useForm({})

  async function submit(body: any) {
    // eslint-disable-next-line prefer-const
    let newListFilter: any = []
    for (const item in body) {
      if (body[item])
        newListFilter.push({
          column: { value: item, label: item },
          condition: { label: 'Igual', value: '=' },
          value: body[item],
        })
    }
    setListFilter(newListFilter)
    onCloseDrawer(false)
  }

  useEffect(() => {
    reset()
  }, [open])

  useEffect(() => {
    if (filter && filter.length > 0) {
      const exist = filter.map((item: any) => item.column.label)

      simpleFilter.forEach((item) => {
        console.log(exist.includes(item.name), 'aqui')
        if (!exist.includes(item.name)) resetField(item.name)
      })
    } else {
      reset()
    }
  }, [filter])

  return (
    <DrawerStyle
      title="Filtro simplificado"
      placement="right"
      closable={true}
      onClose={() => onCloseDrawer(false)}
      open={open}
      destroyOnClose={true}
      mask={false}
      maskClosable={true}
    >
      <form autoComplete="off" onSubmit={handleSubmit(submit)}>
        <Row gutter={[20, 20]}>
          {simpleFilter.map((item, index: number) => (
            <Col span={24} key={index}>
              {item.type === 'text' && (
                <HookInput name={item.name} label={item.label} control={control} mask={item.mask} />
              )}
              {item.type === 'select' && (
                <HookSelectedFixed name={item.name} label={item.label} control={control} options={item.options || []} />
              )}
              {item.type === 'select-api' && (
                <HookSelectedRemote name={item.name} label={item.label} control={control} url={item.url || ''} />
              )}
            </Col>
          ))}
          <Col span={hasAdvanced ? 12 : 24}>
            <Button
              htmlType="submit"
              style={{
                width: '100%',
                height: 30,
                borderColor: lightColor.primary,
                backgroundColor: lightColor.primary,
                color: '#fff',
              }}
            >
              Filtrar
            </Button>
          </Col>
          {hasAdvanced && (
            <Col span={12}>
              <Button
                onClick={() => {
                  onCloseDrawer(false)
                  onCloseModal(true)
                }}
                style={{ width: '100%', height: 30, borderColor: lightColor.primary, display: 'flex', fontSize: 12 }}
              >
                <CgArrowsExchangeAltV style={{ fontSize: 20 }} />
                <p>Filtro avançado </p>
              </Button>
            </Col>
          )}
        </Row>
      </form>
    </DrawerStyle>
  )
}

interface IPropsFilterModal {
  modalOpen: boolean
  onCloseModal: any
  columns: any
  listFilter: any
  setListFilter: any
  setOpen: any
}
const FilterModal = ({ modalOpen, onCloseModal, columns, listFilter, setListFilter, setOpen }: IPropsFilterModal) => {
  const [typeFilter, setTypeFilter] = useState<'ADVANCED' | 'SIMPLE'>('ADVANCED')
  const { isDarkMode } = useDark()
  return (
    <ContainerModal
      footer={null}
      title="Filtro avançado"
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      open={modalOpen}
      closable={true}
      onCancel={() => onCloseModal(!modalOpen)}
      width="700px"
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 10,
          marginTop: 20,
        }}
      >
        <h3 style={{ margin: 0, textAlign: 'left' }}>Escolher colunas para exibir</h3>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <OcultButton
            style={{
              width: 120,
              marginBottom: 13,
            }}
            onClick={() => {
              setListFilter([{ column: undefined, condition: undefined, value: undefined }])
            }}
          >
            <BsEraser />
            <p>Limpar filtro</p>
          </OcultButton>
        </div>
      </div>
      {listFilter?.length > 0 &&
        listFilter.map((list: any, index: any) => (
          <Row gutter={[15, 10]} style={{ marginBottom: 15 }} key={index}>
            <Col span={3}>
              {index === 0 ? (
                <p style={{ fontSize: 15, width: '100%' }}>Onde</p>
              ) : (
                <Select
                  style={{ width: '100%' }}
                  value={list.where ? list.where : undefined}
                  onChange={(value, selected) => {
                    const newList: any = listFilter
                    newList[index].where = selected
                    setListFilter([...newList])
                  }}
                  popupClassName={isDarkMode ? 'dark-drop-down' : ''}
                  options={[
                    { label: 'e', value: 'AND' },
                    { label: 'Ou', value: 'or' },
                  ]}
                />
              )}
            </Col>
            <Col span={6}>
              <Select
                style={{ width: '100%' }}
                onChange={(value, selected) => {
                  const newList: any = listFilter
                  newList[index].column = selected
                  setListFilter([...newList])
                }}
                popupClassName={isDarkMode ? 'dark-drop-down' : ''}
                value={list.column ? list.column : undefined}
                options={[
                  {
                    label: 'Colunas',
                    options: columns?.map((column: any) => ({
                      value: column.key,
                      label: column.label,
                    })),
                  },
                ]}
              />
            </Col>
            <Col span={6}>
              <Select
                style={{ width: '100%' }}
                onChange={(value, selected) => {
                  const newList: any = listFilter
                  newList[index].condition = selected

                  newList[index].value = undefined
                  setListFilter([...newList])
                }}
                value={list.condition ? list.condition : undefined}
                popupClassName={isDarkMode ? 'dark-drop-down' : ''}
                options={[
                  {
                    label: 'Condição',
                    options: [
                      { label: 'Igual á', value: '=' },
                      { label: 'Diferente de', value: '!=' },
                      { label: 'Contem', value: 'LIKE' },
                      { label: 'N/ contem', value: 'NOT LIKE' },
                    ],
                  },
                ]}
              />
            </Col>
            <Col span={6}>
              <Input
                value={list.value ? list.value : undefined}
                onChange={(value) => {
                  const newList: any = listFilter
                  newList[index].value = value.target.value
                  setListFilter([...newList])
                }}
              />
            </Col>
            <Col span={1}>
              {index > 0 && (
                <GrFormClose
                  fontSize={20}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    const newList: any = []
                    listFilter.forEach((item: any, indexRemove: any) => {
                      if (indexRemove !== index) newList.push(item)
                    })

                    setListFilter([...newList])
                  }}
                  color={isDarkMode ? darkColor.white : lightColor.red}
                />
              )}
            </Col>
          </Row>
        ))}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <OcultButton
          onClick={() =>
            setListFilter([
              ...listFilter,
              {
                column: undefined,
                condition: undefined,
                value: undefined,
              },
            ])
          }
        >
          <IoMdAdd />
          <p>Adicionar novo filtro</p>
        </OcultButton>
        <OcultButton
          onClick={() => {
            setListFilter([{ column: undefined, condition: undefined, value: undefined }])
            setTypeFilter(typeFilter === 'ADVANCED' ? 'SIMPLE' : 'ADVANCED')
            setOpen(true)
            onCloseModal(false)
          }}
        >
          <CgArrowsExchangeAltV style={{ fontSize: 30 }} />
          <p>{typeFilter === 'ADVANCED' ? 'Alterar para filtro simplificado' : 'Alterar para filtro avançado'} </p>
        </OcultButton>
      </div>
    </ContainerModal>
  )
}
