import React, { useEffect, useState } from 'react'
import { Space, Table, Tabs, TabsProps, Tooltip } from 'antd'
import { FileExcelOutlined } from '@ant-design/icons'
import { Resizable } from 're-resizable'
import { ContainerTabAction, ContainerTable } from './styled'
import { AiOutlineCloseCircle, AiOutlineExpandAlt } from 'react-icons/ai'
import { ListProcess } from '../../context/tabActions'
import type { ColumnsType } from 'antd/es/table'
import { socket } from '../../socket'
import { useDark } from '../../context/contextDark'
import api from '../../api'
import { getUserId } from '../../utils/login'
import moment from 'moment'

interface IProps {
  activeKey: string | undefined
  setActiveKey(value: string): void
}
export const FooterActions = ({ activeKey, setActiveKey }: IProps) => {
  const { isDarkMode } = useDark()
  const [list, setList] = useState<ListProcess[]>([])
  const [defaultHeight, setDefaultHeight] = useState<string>('500px')

  const getList = async () => {
    try {
      const { data } = await api.post('/ms-import/import/history', {
        user_id: getUserId(),
      })
      setList([...list, ...data])
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const handleResponse = (data: ListProcess) => {
      setList((oldList) => {
        const findIndex = oldList.findIndex((value) => value.id === data.id)
        if (findIndex > -1) {
          if (oldList[findIndex].timestamp > data.timestamp) return oldList
          return [data, ...oldList.slice(0, findIndex), ...oldList.slice(findIndex + 1)]
        } else {
          return [data, ...oldList]
        }
      })
    }

    getList()

    socket.on('organization_id_response', handleResponse)

    return () => {
      socket.off('organization_id_response', handleResponse)
    }
  }, [])

  const columns: ColumnsType<ListProcess> = [
    {
      title: 'Contexto',
      dataIndex: 'context',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
    },
    {
      title: 'Linhas Processadas',
      dataIndex: 'percent',
    },
    {
      title: 'Arquivo de enviado',
      dataIndex: 'calback_url',
      render: (value) => {
        if (value)
          return (
            <Tooltip placement="top" title={'Arquivo enviado'}>
              <FileExcelOutlined
                style={{ cursor: 'pointer', fontSize: 20, color: '#00C49F' }}
                onClick={() => window.open(value, '_blank')}
              />
            </Tooltip>
          )
      },
    },
    {
      title: 'Arquivo de erro',
      dataIndex: 'calback_url_error',
      render: (value) => {
        if (value)
          return (
            <Tooltip placement="top" title={'Arquivo com erro'}>
              <FileExcelOutlined
                style={{ cursor: 'pointer', fontSize: 20, color: 'red' }}
                onClick={() => window.open(value, '_blank')}
              />
            </Tooltip>
          )
      },
    },
    {
      title: 'Data de envio',
      dataIndex: 'created_at',
      render: (value) => moment(value).format('DD/MM/YYYY HH:mm:ss'),
    },
  ]

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <div style={{ padding: '0 10px' }}>Atividade</div>,
      children: (
        <ContainerTabAction>
          <Resizable
            defaultSize={{
              width: '100%',
              height: defaultHeight,
            }}
            minHeight={defaultHeight}
            minWidth="calc(100vw - 66px)"
          >
            <ContainerTable>
              <div className="header">
                <p>Log de importação</p>
                <Space direction="horizontal" size={20}>
                  <div style={{ cursor: 'pointer' }} onClick={() => setActiveKey('-1')}>
                    <AiOutlineCloseCircle fontSize={20} />
                  </div>
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() =>
                      setDefaultHeight(defaultHeight === 'calc(100vh - 60px)' ? '500px' : 'calc(100vh - 60px)')
                    }
                  >
                    <AiOutlineExpandAlt fontSize={20} />
                  </div>
                </Space>
              </div>

              <Table columns={columns} dataSource={list} scroll={{ y: 300 }} />
            </ContainerTable>
          </Resizable>
        </ContainerTabAction>
      ),
    },
  ]

  return (
    <>
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          width: 'calc(100vw - 66px)',
          left: 66,
          zIndex: activeKey !== '-1' ? 9 : 5,
          background: isDarkMode ? '#292f4c' : '#fff',
        }}
      >
        <Tabs
          tabPosition="bottom"
          onTabClick={(active) => {
            setDefaultHeight('500px')
            setActiveKey(activeKey === active ? '-1' : active)
          }}
          activeKey={activeKey}
          items={items}
          animated={true}
          style={{
            color: isDarkMode ? '#fff' : '#000',
          }}
        />
      </div>
    </>
  )
}
