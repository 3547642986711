import { Switch } from 'antd'
import { Controller } from 'react-hook-form'
import { lightColor } from '../../utils/styleColors'

interface IProps {
  required?: string
  name: string
  label: string
  control: any

  disabled?: boolean
  style?: any
}

export const HookToogle = ({ control, name, label, required, disabled }: IProps) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required || undefined,
      }}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <div>
          <p
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              color: lightColor.blackTextBold,
              marginBottom: 8,
              opacity: 0.74,
            }}
          >
            {label}
          </p>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Switch onChange={onChange} checked={value} disabled={disabled} />
            <p
              style={{
                margin: '0 10px',
                fontWeight: 'bold',
                color: lightColor.blackSwicth,
                opacity: 0.74,
                fontSize: '13px',
              }}
            >
              {value ? 'Sim' : 'Não'}
            </p>
          </div>
          {!!error && (
            <p
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                color: lightColor.redBold,
                marginBottom: 8,
                opacity: 0.74,
              }}
            >
              {error?.message?.toString()}
            </p>
          )}
        </div>
      )}
    />
  )
}
