import { ContainerFilterWorkspace, Portal } from './style'
import { Checkbox, Input, Popover } from 'antd'
import { useEffect, useState } from 'react'
import { AiOutlineEyeInvisible, AiOutlineSearch } from 'react-icons/ai'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import { IPropsRenderPage } from '../../../pages/promoter'
import { useDark } from '../../../context/contextDark'
import { darkColor } from '../../../utils/styleColors'

const CheckboxGroup = Checkbox.Group

interface IProps {
  columns: any
  updateColumn(list: any): void
  columnsOrigin: IPropsRenderPage
}

export const OcultColumn = ({ updateColumn, columnsOrigin }: IProps) => {
  const [selecteds, setSelecteds] = useState<CheckboxValueType[]>([])
  const [origin, setOrigin] = useState<any[]>([])
  const [clone, setClone] = useState<any>([])
  const [search, setSearch] = useState('')
  const { isDarkMode } = useDark()

  const onChange = (list: CheckboxValueType[]) => {
    if (search) {
      const find = selecteds.filter((item: any) => !item.toLowerCase().includes(search.toLowerCase()))

      setSelecteds([...find, ...list])
    } else {
      setSelecteds(list)
    }
  }

  const onCheckAllChange = () => {
    if (selecteds?.length === origin?.length) {
      setSelecteds([])
    } else {
      setSelecteds(origin)
    }
  }

  useEffect(() => {
    updateColumn(selecteds)
  }, [selecteds])

  useEffect(() => {
    if (columnsOrigin.colunms) {
      const columns = columnsOrigin.colunms.filter((column: any) => !column.required).map((column: any) => column.title)
      setOrigin(columns)
      setClone(columns)
      setSelecteds(columns)
    }
  }, [columnsOrigin])

  return (
    <>
      <Popover
        content={
          <Portal>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <h3 style={{ margin: 0 }}>Escolher colunas para exibir</h3>
            </div>
            <Input
              placeholder="pesquisar coluna"
              prefix={<AiOutlineSearch />}
              value={search}
              onChange={(evt) => {
                const value = evt.target.value
                const newList = origin.filter((item: any) => item.toLowerCase().includes(value.toLowerCase()))
                setClone([...newList])
                setSearch(value)
              }}
            />
            <Checkbox onChange={onCheckAllChange} checked={selecteds?.length === origin?.length}>
              Selecionar todos
            </Checkbox>
            <CheckboxGroup style={{ display: 'flex' }} options={clone} value={selecteds} onChange={onChange} />
          </Portal>
        }
        trigger="click"
        overlayInnerStyle={{
          backgroundColor: isDarkMode ? darkColor.bgTable : '#fff',
        }}
      >
        <ContainerFilterWorkspace active={false}>
          <AiOutlineEyeInvisible style={{ marginRight: 10 }} fontSize={18} />
          <span>
            Ocultar
            {selecteds?.length - origin?.length < 0 && ` / ${(selecteds?.length - origin?.length) * -1}`}
          </span>
        </ContainerFilterWorkspace>
      </Popover>
    </>
  )
}
