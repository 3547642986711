import { createContext, ReactNode, useContext, useState } from 'react'
import { IopenDrawer } from '../Components/table'

interface OpenInfoProviderProps {
  children: ReactNode
}

type OpenInfoContextData = {
  openDrawer: IopenDrawer
  setOpenDrawer: any
}

const OpenInfoContext = createContext({} as OpenInfoContextData)

export function OpenInfoProvider({ children }: OpenInfoProviderProps) {
  const [openDrawer, setOpenDrawer] = useState<IopenDrawer>({
    open: false,
    row: {},
  })

  return (
    <OpenInfoContext.Provider
      value={{
        openDrawer,
        setOpenDrawer,
      }}
    >
      {children}
    </OpenInfoContext.Provider>
  )
}

export const useOpenInfo = () => useContext(OpenInfoContext)
