import React from 'react'
import { ButtonActions, ContainerActionsColor } from './style'
import { useDark } from '../../../context/contextDark'
import { ItemType } from 'antd/es/menu/hooks/useItems'

export interface IPropsDrawer {
  actionsMenu?: ItemType[]
  clickButton?(): void
}

export interface IProccessFile {
  type: 'SUCCESS' | 'ERROR' | 'WARNING'
  row: any
  errors: Array<{ key: string; message: string }>
}

export const Import = ({ actionsMenu, clickButton }: IPropsDrawer) => {
  const { isDarkMode } = useDark()

  return (
    <ContainerActionsColor>
      <ButtonActions
        overlayClassName={isDarkMode ? 'dark-drop-down' : ''}
        menu={{
          items: actionsMenu,
        }}
        onClick={clickButton}
      >
        {/* Adicionar */}
        Ações
      </ButtonActions>
    </ContainerActionsColor>
  )
}
