import axios from 'axios'
import { getToken } from '../utils/login'

const api_v2_witout_native = axios.create({
  baseURL: process.env.REACT_APP_API_V2_URL,
  // baseURL: 'http://localhost:9000/',
  headers: {
    'Content-Type': 'application/json',
  },
})

api_v2_witout_native.interceptors.request.use((config: any) => {
  const token = getToken()
  const headers = { ...config.headers }
  if (token) headers.Authorization = `Bearer ${token}`

  return { ...config, headers }
})

export default api_v2_witout_native
