import { Checkbox, Input, Spin, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { ContainerCheck, ContainerFilter } from './style'
import { CheckboxValueType } from 'antd/es/checkbox/Group'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import api from '../../../api'
import { IPropsDrawer } from '.'
import FileSaver from 'file-saver'
import { ButtonSteps } from '../../ImportDrawer/style'
import { IPropsFilter } from '../../../pages/statusDay'

const CheckboxGroup = Checkbox.Group

export interface IAmountHeaderImport {
  id: string
  label: string
  key: string
  required: boolean
  group: string
}

interface IProps extends IPropsDrawer {
  columnsHeader: any
  changeHeader(data: IAmountHeaderImport[]): void
  amountHeader: IAmountHeaderImport[]
  setOpen: any
  type?: 'export'
  context: string
  filters?: IPropsFilter
}

export const SelectColumns = ({
  columnsHeader,
  changeHeader,
  context,
  amountHeader,
  filters,
  setOpen,
  type,
}: IProps) => {
  const [header, setHeader] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [headerOrigin, setHeaderOrigin] = useState<any>([])
  const [selecteds, setSelecteds] = useState<CheckboxValueType[]>([])
  const [search, setSearch] = useState('')
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(true)
  const [columnDescription] = useState([
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Obrigatório ?',
      dataIndex: 'required',
      key: 'required',
      render: (value: any) => <>{value ? 'Sim' : 'Não'}</>,
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
    },
  ])
  const [selectedsObjects, setSelectedsObjects] = useState<IAmountHeaderImport[]>([])

  const onChange = (list: CheckboxValueType[]) => {
    if (search) {
      const find = selecteds.filter((item: any) => !item.toLowerCase().includes(search.toLowerCase()))

      setSelecteds([...find, ...list])
    } else {
      setSelecteds(list)
    }
  }

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setSelecteds(
      e.target.checked
        ? headerOrigin.map((item: any) => item.key)
        : headerOrigin.filter((item: any) => item.required).map((item: any) => item.key)
    )
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  useEffect(() => {
    if (columnsHeader) {
      setHeader(columnsHeader)
      setHeaderOrigin(columnsHeader)
      setSelecteds(columnsHeader.map((item: any) => item.key))
    }
  }, [columnsHeader])

  useEffect(() => {
    const newList: IAmountHeaderImport[] = selecteds.map((item) => headerOrigin.find((find: any) => find.key === item))
    setIndeterminate(!!selectedsObjects?.length && selectedsObjects?.length < headerOrigin?.length)

    setSelectedsObjects([...newList])
    changeHeader([...newList])
  }, [selecteds])

  const listCheckbox = () => {
    const groups: any[] = []
    header.forEach((item: any) => {
      if (groups.findIndex((list) => list === item.group) === -1) {
        groups.push(item.group)
      }
    })
    return (
      <CheckboxGroup value={selecteds} onChange={onChange} style={{ width: '100%' }}>
        {groups.map((group: any, index: number) => (
          <ContainerCheck key={index}>
            <p style={{ fontSize: 12 }}>{group}</p>
            {header
              .filter((item: any) => item.group === group)
              .map((item: any, indexKey: number) => (
                <Checkbox value={item.key} disabled={item.required} key={indexKey}>
                  {item.label}
                </Checkbox>
              ))}
          </ContainerCheck>
        ))}
      </CheckboxGroup>
    )
  }

  const finish = async () => {
    setLoading(true)
    let url = `/ms-import/template/${context}/download`
    if (type === 'export') url = `/ms-import/export/${context}/download`
    const body: any = {
      amountHeader: amountHeader.map((item) => ({ key: item.key, label: item.label })),
    }

    if (type === 'export' && filters) {
      const { workspace, order, filter, rangePicker } = filters
      if (workspace && workspace.length > 0) {
        body.workspace_id = workspace?.map((item) => item.id.toString())
      }

      if (order && order.length > 0) {
        body.sort = order?.map((item) => ({
          field: item.column?.value,
          direction: item.order?.value,
        }))
      }

      if (filter && filter.length) {
        body.filter = filter.map((item) => ({
          field: item.column?.value,
          condition: item.condition?.value,
          value: item.value,
        }))
      }

      if (rangePicker && rangePicker.length) {
        body.range_date = rangePicker
      }
    }

    const { data } = await api.post(url, body, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const blob = new Blob([data], {
      type: 'application/xlsx',
    })
    FileSaver.saveAs(blob, `${context}.xlsx`)
    setLoading(false)
    setOpen()
  }

  return (
    <ContainerFilter>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginTop: 20,
          maxHeight: '65vh',
          overflow: 'auto',
        }}
      >
        <div
          style={{
            width: 350,
            maxHeight: '65vh',
            overflow: 'auto',
            paddingRight: 10,
          }}
        >
          <h3 style={{ margin: 0, fontSize: 16, marginBottom: 20 }}>
            Escolher colunas para {type === 'export' ? 'exportar' : 'importar'}
          </h3>
          <Input
            placeholder="pesquisar coluna"
            value={search}
            prefix={<AiOutlineSearch />}
            onChange={(evt) => {
              const value = evt.target.value
              const newList = headerOrigin.filter((item: any) => item.label.toLowerCase().includes(value.toLowerCase()))
              setSearch(value)
              setHeader([...newList])
            }}
          />
          <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
            Selecionar todos
          </Checkbox>
          {header?.length > 0 && <>{listCheckbox()}</>}
        </div>
        <div
          style={{
            width: 'calc(100% - 450px)',
            marginLeft: 100,
            // maxHeight: '50vh',
            // overflow: 'auto',
          }}
        >
          <h3 style={{ margin: 0, fontSize: 16, marginBottom: 20 }}>
            O cabeçalho do seu modelo de {type === 'export' ? 'exportação' : 'importação'} ficará assim:
          </h3>
          <Table
            columns={columnDescription}
            scroll={{ x: 'fit-content', y: '53vh' }}
            dataSource={selectedsObjects.map((item) => ({
              name: item.label,
              required: item.required,
              description: '',
            }))}
            pagination={false}
          />
        </div>
      </div>
      <ButtonSteps onClick={async () => finish()} style={{ margin: '100px 0 0 auto' }}>
        {loading && <Spin style={{ marginRight: 10 }} />}
        {type === 'export' ? 'Exportar' : 'Finalizar'}
      </ButtonSteps>
    </ContainerFilter>
  )
}
