export const lightColor = {
  primary: 'rgb(102, 0, 204)',
  white: '#fff',
  red: 'red',
  purple: 'purple',
  black: 'black',
  redLight: '#FED7D7',
  redPrimary: '#E53E3E',
  yellowLight: '#FEF697',
  yellowPrimary: '#FEA900',
  greenLight: '#D3F5D5',
  greenPrimary: '#29B345',
  purpleLight: '#F7E6FF',
  blackText: '#535353',
  redFilter: '#bc3556',
  redBold: '#911414',
  yellowFilter: '#fdb759',
  greenFilter: '#037f4c',
  blackTextBold: '#323338',
  borderColor: '#d0d4e4',
  shadow: 'rgba(0, 0, 0, 0.94)',
  blackSwicth: 'rgb(81, 81, 81)',
  selectBorder: '#d9d9d9',
  colorBlackOpacity: 'rgba(0,0,0,0.65)',
  activeLink: 'rgb(209, 175, 241)',
  activeBoldLink: 'rgba(61,1,120,0.6909357492997199)',
  menuBackground: '#473581',
  bgColorMenu: '#f6f7fb',
  borderMenuColor: '#c3c6d4',
  colorH3Modal: '#8f61e2',
  colorI: '#6f7782',
  colorH4: '#242424',
  grayLIght: '#666666',
  d6: '#d6d6d6',
  bgTable: 'rgba(241,243,244,0.871)',
  colorTable: '#5f6368',
  bgWOrkspaces: '#eeddfe',
  secondPrimary: '#A56EFC',
  eee: '#eee',
  boolGreen: 'rgb(22, 194, 102)',
  boolBgGreen: 'rgba(184, 255, 217, 0.5)',
  boolOrange: 'rgb(233, 131, 15)',
  boolBgOrange: 'rgb(237, 191, 149)',
  imageBg: 'rgba(102, 0, 204, 0.658)',
  borderTable: '#7158c1',
  blueImport: 'rgb(0, 136, 254)',
}

export const darkColor = {
  backgroundColor: '#1c1f3b',
  white: '#fff',
  primary: 'rgb(102, 0, 204)',
  bgTable: '#30324e',
  borderTable: '#797e93',
  backgroundMenu: '#292f4c',
  borderTableHover: 'rgb(98 101 145)',
  primaryOpactity: 'rgba(102, 0, 204, 0.2)',
  primaryLight: '#3a2867',
  boolBgGreen: 'rgba(184, 255, 217, 0.0)',
}
