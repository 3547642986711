import { ContainerFilterWorkspace, Portal, Workspace } from './style'
import { BiUserCircle } from 'react-icons/bi'
import { Avatar, Col, Popover, Row } from 'antd'
import { AiOutlineUser } from 'react-icons/ai'
import { useEffect, useState } from 'react'
import api_v2 from '../../../api/api-v2'
import { CiSquareRemove } from 'react-icons/ci'
import { darkColor, lightColor } from '../../../utils/styleColors'
import { useDark } from '../../../context/contextDark'

export interface IPropsWorkspace {
  avatar_url?: string
  id: number
  slug: string
  name: string
}

interface IProps {
  changeWorkspace: any
}
export const PersonWorkspace = ({ changeWorkspace }: IProps) => {
  const { isDarkMode } = useDark()

  const [workspaces, setWorkspaces] = useState<IPropsWorkspace[]>([])
  const [workspaceActive, setWorkspaceActive] = useState<IPropsWorkspace[]>([])

  const getWorkspaces = async () => {
    try {
      const { data } = await api_v2.get('/workspace')
      setWorkspaces(data.result)
    } catch (error) {
      return null
    }
  }

  useEffect(() => {
    getWorkspaces()
  }, [])

  useEffect(() => {
    changeWorkspace(workspaceActive)
  }, [workspaceActive])

  const addWorkspace = (works: IPropsWorkspace) => {
    if (workspaceActive.filter((actives) => actives.id === works.id).length === 0) {
      setWorkspaceActive([...workspaceActive, works])
    } else {
      setWorkspaceActive(workspaceActive.filter((actives) => actives.id !== works.id))
    }
  }

  return (
    <>
      <Popover
        content={
          <Portal>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <h3>Filtro rápido de ambiente</h3>
            </div>
            <p style={{ marginBottom: 20 }}>Filte elementos e sublementos por ambiente</p>
            <Row gutter={[10, 10]}>
              {workspaces?.length > 0 &&
                workspaces.map((item, index) => (
                  <Col span={6} key={index.toString()}>
                    <Workspace active={workspaceActive.filter((actives) => actives.id === item.id).length > 0}>
                      {workspaceActive.filter((actives) => actives.id === item.id).length > 0 && (
                        <CiSquareRemove
                          style={{
                            color: lightColor.red,
                            cursor: 'pointer',
                            fontSize: 18,
                            position: 'absolute',
                            right: 3,
                            top: 0,
                            zIndex: 9999,
                          }}
                          onClick={() => {
                            setWorkspaceActive(workspaceActive.filter((actives) => actives.id !== item.id))
                          }}
                        />
                      )}
                      {item.avatar_url ? (
                        <img
                          src={item.avatar_url}
                          alt={item.slug}
                          style={{
                            height: 32,
                            borderRadius: 15,
                            width: 32,
                          }}
                          onClick={() => addWorkspace(item)}
                        />
                      ) : (
                        <Avatar icon={<AiOutlineUser />} onClick={() => addWorkspace(item)} />
                      )}
                      <p
                        style={{
                          fontSize: 11,
                          width: '100%',
                          textAlign: 'center',
                        }}
                        onClick={() => addWorkspace(item)}
                      >
                        {item.name}
                      </p>
                    </Workspace>
                  </Col>
                ))}
            </Row>
          </Portal>
        }
        trigger="click"
        overlayInnerStyle={{ backgroundColor: isDarkMode ? darkColor.bgTable : '#fff' }}
      >
        <ContainerFilterWorkspace active={false}>
          <BiUserCircle style={{ marginRight: 10 }} fontSize={18} />
          <span>Ambiente {workspaceActive?.length > 0 && ` / ${workspaceActive?.length}`}</span>
        </ContainerFilterWorkspace>{' '}
      </Popover>
    </>
  )
}
