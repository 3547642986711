import { useEffect, useState } from 'react'
import { DrawerStyle } from '../ImportDrawer/style'
import { IPropsDrawer } from '../defaultFilters/actionsButton'
import { IAmountHeaderImport, SelectColumns } from '../defaultFilters/actionsButton/selectColumns'
import { message } from 'antd'
import api from '../../api'
import { IPropsFilter } from '../../pages/statusDay'

interface IProps extends IPropsDrawer {
  setOpenExport: any
  openExport: boolean
  title: string
  context: string
  filters: IPropsFilter
}

export const ExportXLS = ({ context, setOpenExport, openExport, title, filters }: IProps) => {
  const [header, setHeader] = useState<any>([])
  const [amountHeader, setAmountHeader] = useState<IAmountHeaderImport[]>([])

  const getContextExport = async () => {
    try {
      const { data } = await api.get(`/ms-import/template/${context}`)
      if (data.header && data.header.length > 0) {
        setHeader([...data.header])
      }
    } catch (error) {
      message.error('Erro ao carregar exportação.')
    }
  }

  useEffect(() => {
    getContextExport()
  }, [openExport])

  useEffect
  return (
    <DrawerStyle
      title={`Exportar ${title}`}
      placement="left"
      closable={true}
      onClose={() => setOpenExport(false)}
      open={openExport}
      key="left"
      width="100%"
      destroyOnClose={true}
    >
      <div
        style={{
          padding: '0 74px',
        }}
      >
        <SelectColumns
          columnsHeader={header}
          changeHeader={setAmountHeader}
          key="SelectCOmuns"
          amountHeader={amountHeader}
          context={context}
          setOpen={() => setOpenExport(!openExport)}
          type="export"
          filters={filters}
        />
      </div>
    </DrawerStyle>
  )
}
