import React, { useEffect, useMemo, useState } from 'react'
import { IPropsPagination, TableDefault } from '../Components/table'
import api from '../api'
import { DefaultFilters } from '../Components/defaultFilters'
import DefaultPage from '../Components/defaultPage'
import { debounce } from 'lodash'
import { Header } from '../Components/header'
import { Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { DeafultTitleTab } from '../Components/deafultTitleTab'
import { BsHouseDoor } from 'react-icons/bs'
import { ExportXLS } from '../Components/DrawerExport'
import dayjs from 'dayjs'
import { useDark } from '../context/contextDark'
import { darkColor, lightColor } from '../utils/styleColors'
import { initialPagination } from '../utils/initials'
import { IPropsFilter } from './statusDay'
import { IPropsGetTable } from '../utils/defaultsTypes'
import { TaggFilter } from '../Components/TagFilter'

export interface ISelect {
  value: string
  label: string
}

export interface IPropsRenderPage {
  colunms: any[]
  filter_options: any[]
  sort_options: any[]
}

export interface IPropsPageContext {
  context: string
  titlePage: string
  title: string
}

const VisitsPage = ({ context, title }: IPropsPageContext) => {
  const dateFormat = 'YYYY-MM-DD'
  const [columnOrigin, setColumnOrigin] = useState<IPropsRenderPage>({} as IPropsRenderPage)
  const [columns, setColumns] = useState<any>([])
  const [data, setData] = useState<IPropsGetTable>({} as IPropsGetTable)
  const [filters, setFilters] = useState<IPropsFilter>({
    rangePicker: [dayjs().format(dateFormat), dayjs().format(dateFormat)],
    ...initialPagination,
  } as IPropsFilter)
  const [loading, setLoading] = useState(false)
  const [openExport, setOpenExport] = useState(false)

  const getColumns = async () => {
    const { data } = await api.get(`/ms-import/view/${context}`)
    setColumnOrigin(data)
    setColumns(data.colunms)
  }

  const changeColumn = (list: string[]) => {
    const newList: any = []
    if (columnOrigin.colunms) {
      columnOrigin.colunms.forEach((column: any) => {
        column.ellipsis = true
        if (column.required) newList.push(column)
        if (list.indexOf(column.title) > -1) newList.push(column)
      })

      setColumns([...newList])
    }
  }

  const getVisits = async () => {
    try {
      setLoading(true)
      const { workspace, order, filter, rangePicker, page, pageSize } = filters
      const body: any = {}
      if (workspace && workspace.length > 0) body.workspace_id = workspace?.map((item) => item.id.toString())

      if (order && order.length > 0)
        body.sort = order?.map((item) => ({
          field: item.column?.value,
          direction: item.order?.value,
        }))

      if (filter && filter.length)
        body.filter = filter.map((item) => ({
          field: item.column?.value,
          condition: item.condition?.value,
          value: item.value,
        }))

      if (rangePicker && rangePicker.length) body.range_date = rangePicker

      if (page || pageSize)
        body.pagination = {
          page,
          perpage: pageSize,
        }

      const { data } = await api.post('/ms-report/visits', body)
      setData({ result: data.result.map((item: any) => ({ ...item, key: item.id })), total: data.total })
    } catch (error) {
      return null
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getVisits()
    getColumns()
  }, [])

  useEffect(() => {
    getVisits()
  }, [filters])

  const onSearch = async (value: any) => {
    try {
      setLoading(true)
      const { workspace, order, filter, rangePicker, page, pageSize } = filters
      const body: any = {}
      if (workspace && workspace.length > 0) body.workspace_id = workspace?.map((item) => item.id.toString())

      if (order && order.length > 0)
        body.sort = order?.map((item) => ({
          field: item.column?.value,
          direction: item.order?.value,
        }))

      if (filter && filter.length) {
        const filterList = []
        filter.forEach((item) => {
          if (item.column?.value === 'name') return

          filterList.push({
            field: item.column?.value,
            condition: item.condition?.value,
            value: item.value,
          })
        })
      }

      if (page || pageSize)
        body.pagination = {
          page,
          perpage: pageSize,
        }

      body.filter = [
        {
          field: 'name',
          condition: 'LIKE',
          value: value,
        },
      ]

      if (rangePicker && rangePicker.length) {
        body.range_date = rangePicker
      }
      const { data } = await api.post('/ms-report/visits', body)
      setData({ result: data.result.map((item: any) => ({ ...item, key: item.id })), total: data.total })
    } catch (error) {
      return null
    } finally {
      setLoading(false)
    }
  }

  const debouncedChangeHandler = useMemo(() => debounce(onSearch, 300), [])

  return (
    <>
      <DefaultFilters
        columns={columns}
        updateColumn={(list) => changeColumn(list)}
        changeWorkspace={(workspace) => setFilters({ ...filters, workspace })}
        changeOrder={(order) => setFilters({ ...filters, order })}
        changeFilter={(filter) => setFilters({ ...filters, filter })}
        columnsOrigin={columnOrigin}
        filter={filters.filter}
        setSearch={async (value) => debouncedChangeHandler(value)}
        actionsMenu={[
          {
            label: 'Exportar',
            key: '2',
            onClick: () => {
              setOpenExport(!openExport)
            },
          },
        ]}
        rangePick={(value) =>
          setFilters({
            ...filters,
            rangePicker: [dayjs(value[0]).format(dateFormat), dayjs(value[1]).format(dateFormat)],
          })
        }
        simpleFilter={[
          {
            name: 'name',
            type: 'text',
            label: 'Nome do promotor',
            mask: '',
          },
          {
            name: 'cpf',
            type: 'text',
            label: 'CPF do promotor',
            mask: '999.999.999-99',
          },
          {
            name: 'promoter_active',
            type: 'select',
            label: 'Promotor ativo ou inativo',
            options: [
              { value: 'true', label: 'Ativo' },
              { value: 'false', label: 'Inativo' },
            ],
          },
          {
            name: 'status_visit',
            type: 'select',
            label: 'Status',
            options: [
              { value: 'COMPLETE', label: 'Concluído' },
              { value: 'PENDENT', label: 'Pendente' },
              { value: 'IN_PROGRESS', label: 'Em progresso' },
              { value: 'JUSTIFIED_ABSENCE', label: 'Justificado' },
            ],
          },
          {
            name: 'network',
            type: 'text',
            label: 'Nome da rede',
            mask: '',
          },
          {
            name: 'flag',
            type: 'text',
            label: 'Nome da bandeira',
            mask: '',
          },
          {
            name: 'pdv',
            type: 'text',
            label: 'Nome do PDV',
            mask: '',
          },
          {
            name: 'profile',
            type: 'text',
            label: 'Perfil da equipe',
            mask: '',
          },
        ]}
      />
      <TaggFilter filters={filters} setFilters={setFilters} />
      <TableDefault
        reload={async () => getVisits()}
        headerOrigin={columnOrigin.colunms}
        pagination={({ page, pageSize }: IPropsPagination) => setFilters({ ...filters, page, pageSize })}
        data={data.result}
        column={columns}
        loading={loading}
        total={data.total || 0}
        checks={{
          keyCheckItem() {
            // setSelecteds(data)
          },
          keyCheckAll() {
            // setSelecteds(data)
          },
          selectAllPages() {
            return data.result.map((item: any) => item.key)
          },
        }}
        menu={[]}
      />
      <ExportXLS
        filters={filters}
        setOpenExport={setOpenExport}
        context={context}
        openExport={openExport}
        title={title}
      />
    </>
  )
}

const Visits = (body: IPropsPageContext) => {
  const { isDarkMode } = useDark()

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <DeafultTitleTab title="Padrão" icon={<BsHouseDoor fontSize={20} />} />,
      children: VisitsPage(body),
      style: {
        color: isDarkMode ? darkColor.white : lightColor.blackTextBold,
      },
    },
  ]

  return (
    <DefaultPage>
      <Header title={body.titlePage} />
      <div className={isDarkMode ? 'dark-tab' : 'light-tab'}>
        <Tabs defaultActiveKey="1" items={items} animated={true} />
      </div>
    </DefaultPage>
  )
}

export default Visits
