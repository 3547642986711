import axios from 'axios'
import { getToken, getUserId } from '../utils/login'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_GATEWAY,
  headers: {
    'Content-Type': 'application/json',
  },
})

api.interceptors.request.use((config: any) => {
  const token = getToken()
  const user_id = getUserId()
  const headers = { ...config.headers }
  if (token) headers.Authorization = `Bearer ${token}`
  if (token) headers.user_id = user_id
  if (token) headers.token = token

  return { ...config, headers }
})

export default api
