import styled from 'styled-components'
import { darkColor, lightColor } from '../../utils/styleColors'
import { useDark } from '../../context/contextDark'

export const ContainerHeader = styled.div`
  width: 100%;
  margin-bottom: 20px;
  color: ${() => {
    const { isDarkMode } = useDark()

    return isDarkMode ? darkColor.white : lightColor.blackTextBold
  }};
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    font-size: 32px;
    font-weight: 500;
    margin: 0;
    letter-spacing: -0.2px;
    -webkit-font-smoothing: antialiased;
  }
`
