/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { ContainerDate, ContainerLicenca, Header, TextDate } from './style'
import { CardLicenca } from './card'
import { Button, Drawer, Table, message } from 'antd'
import { darkColor, lightColor } from '../../utils/styleColors'
import { useDark } from '../../context/contextDark'
import { Switch } from './switch'
import { MoreLicence } from './moreLIcence'
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons'
import moment from 'moment'
import 'moment/locale/pt-br'
import api_v2 from '../../api/api-v2'
import { addIsScriptIsPermited } from '../../utils/blockUser'
import api from "../../api";
import FileSaver from "file-saver";

interface IProps {
  total: number
  campo: number
  disponiveis: number
}

const mount: any = {
  '2023-09': 472,
  '2023-08': 470,
  '2023-07': 502,
  '2023-06': 514,
  '2023-05': 506,
  '2023-04': 470,
  '2023-03': 462,
  '2023-02': 458,
  '2023-01': 438,
  '2022-12': 427,
}

export const Licenca = () => {
  moment.locale('pt-br')
  const { isDarkMode } = useDark()
  const [date, setDate] = useState<any>(moment(new Date()).format('YYYY-MM-DD'))
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const [dataOrigin, setDataOrigin] = useState<any>([])
  const [dataTable, setData] = useState<any[]>([])
  const [license, setLicense] = useState<IProps>({} as IProps)
  const [total, setTotal] = useState<number>(1)

  const columns: any = [
    {
      title: 'Ambientes',
      dataIndex: 'workspace_name',
      key: 'workspace_name',
    },
    {
      title: 'Colaboradores de campo',
      dataIndex: 'quantidade_em_uso',
      key: 'quantidade_em_uso',
      align: 'center',
    },
    {
      title: 'Licenças alocadas',
      dataIndex: 'quantidade_alocado',
      key: 'quantidade_alocado',
      align: 'center',
      render: (value: any, row: any) => (
        <Switch
          value={value}
          campo={row.quantidade_em_uso || 0}
          disponivel={license.disponiveis}
          change={(newValue: any) => {
            const newList = dataTable.map((item: any) => {
              if (item.workspace_name === row.workspace_name)
                return {
                  ...item,
                  quantidade_alocado: newValue,
                }

              return item
            })
            setData([...newList])
          }}
        />
      ),
    },
  ]

  // useEffect(() => {
  //   if (dataTable && dataTable.length > 0) {
  //     const totalCampos = dataTable.reduce(
  //       (previousValue: number, currentValue: any) => previousValue + currentValue.quantidade_em_uso,
  //       0
  //     )
  //     const totalAlocado = dataTable.reduce(
  //       (previousValue: number, currentValue: any) => previousValue + currentValue.quantidade_alocado,
  //       0
  //     )

  //     // setLicense({ campo: totalCampos, disponiveis: license.total - totalAlocado, total: license.total })
  //   }
  // }, [dataTable])

  function isDataEqual() {
    if (dataTable.length !== dataOrigin.length) {
      return false
    }

    for (let i = 0; i < dataTable.length; i++) {
      const objData: any = dataTable[i]
      const objDataOrigin: any = dataOrigin[i]

      // Verificar se as propriedades são iguais
      const keysData = Object.keys(objData)
      const keysDataOrigin = Object.keys(objDataOrigin)

      if (keysData.length !== keysDataOrigin.length) {
        return false
      }

      for (let j = 0; j < keysData.length; j++) {
        const key = keysData[j]

        if (objData[key] !== objDataOrigin[key]) {
          return false
        }
      }
    }

    return true
  }

  const getCards = async () => {
    try {
      const mounth = moment(date, 'YYYY-MM-DD').format('M')
      const year = moment(date, 'YYYY-MM-DD').format('YYYY')
      if ((parseInt(mounth) < 10 && year === '2023') || year === '2022') {
        if (mount[`${year}-${mounth === '12' ? '12' : `0${mounth}`}`]) {
          const get = mount[`${year}-${mounth === '12' ? '12' : `0${mounth}`}`]
          setLicense({
            total: get,
            campo: 0,
            disponiveis: 0,
          })
        }
        return
      }

      const mounth2 = moment(date, 'YYYY-MM-DD').format('MM')

      const { data } = await api_v2.get(`/licenca/cards?mes_referencia=${mounth2}&ano_referencia=${year}`)
      setLicense({
        total: data.licencas_contratada,
        campo: data.licencas_alocada,
        disponiveis: data.licencas_disponivel,
      })
    } catch (error) {
      console.log(error)
    }
  }
  const getLicences = async () => {
    try {
      setLoading(true)
      await getCards()
      const mounth = moment(date, 'YYYY-MM-DD').format('MM')
      const year = moment(date, 'YYYY-MM-DD').format('YYYY')
      const { data } = await api_v2.get(`/licenca?mes_referencia=${mounth}&ano_referencia=${year}`)
      setDataOrigin(data.result)
      setData(data.result)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async () => {
    try {
      setLoading(true)
      // eslint-disable-next-line prefer-const
      let newList: any = []
      dataTable.forEach((item: any, index: number) => {
        if (item.quantidade_alocado !== dataOrigin[index].quantidade_alocado) {
          return newList.push({
            action: item.quantidade_alocado > dataOrigin[index].quantidade_alocado ? 'INCREMENT' : 'DECREMENT',
            workspace_id: item.workspace_id,
            quantity: item.quantidade_alocado - dataOrigin[index].quantidade_alocado,
          })
        }
      })

      await api_v2.post('/licenca/save', newList)
      message.success('Alteração realizada com sucesso!!')
      getLicences()
    } catch (error) {
      console.log(error)
      message.error('Erro ao fazer edição')
    } finally {
      setLoading(false)
    }
  }

  const onContrat = async () => {
    try {
      setLoadingMore(true)
      await api_v2.post('/licenca/contratar', { quantity: total })
      message.success('Sucesso ao contratar licenças')
      setOpen(false)
      // getLicences()
    } catch (error) {
      console.log(error)
      message.error('Erro ao contratar licenças')
    } finally {
      setLoadingMore(false)
    }
  }

  useEffect(() => {
    getLicences()
  }, [date])

  function isBeforeFirstJan2023(date: any) {
    const targetDate = moment('2023-01', 'YYYY-MM')
    const inputDate = moment(date, 'YYYY-MM-DD')

    return inputDate.isBefore(targetDate, 'month')
  }

  function isBeforeMounthActually(date: any) {
    const targetDate = moment()
    const inputDate = moment(date, 'YYYY-MM-DD')

    return !inputDate.isBefore(targetDate, 'month')
  }

  return (
    <ContainerLicenca>
      <button
        style={{
          backgroundColor: darkColor.primary,
          marginBottom: 10,
          width: 120,
          height: 45,
          borderRadius: 4,
          display: "flex",
          alignItems: 'center',
          justifyContent: 'center',
          border: 'none',
          color: 'white',
          fontWeight: 'bold',
          cursor: 'pointer'
        }}
        onClick={ async () => {
          try {
          setLoading(true)

          const { data } = await api.post('/ms-import/export/license', {}, {
          responseType: 'arraybuffer',
          headers: {
          'Content-Type': 'application/json',
        },
        })

          const blob = new Blob([data], {
          type: 'application/xlsx',
        })
          FileSaver.saveAs(blob, 'license.xlsx')
        } catch (erro) {
          console.log(erro)
        } finally {
          setLoading(false)
        }
    }}
      >Exportar</button>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 20,
        }}
      >
        <CardLicenca value={license.total} title="Licenças contratadas" bgColor={darkColor.primary} />
        {isBeforeMounthActually(date) && <CardLicenca value={license.campo} title="Licenças alocadas nos ambientes" />}
        {isBeforeMounthActually(date) && (
          <CardLicenca
            value={license.disponiveis}
            title="Licenças disponíveis"
            // btn={
            //   <Button
            //     onClick={() => {
            //       setTotal(1)
            //       setOpen(!open)
            //     }}
            //     id="create-licence"
            //   >
            //     Contratar Licenças
            //   </Button>
            // }
          />
        )}
        {!isBeforeMounthActually(date) && (
          <Header>
            <ContainerDate>
              <CaretLeftOutlined
                style={{
                  color: isBeforeFirstJan2023(date) ? darkColor.borderTable : isDarkMode ? '#fff' : '#242424',
                }}
                onClick={() => {
                  const isBefore = isBeforeFirstJan2023(date)
                  if (isBefore) return
                  setDate(moment(date).subtract(1, 'M'))
                }}
              />
              <TextDate>{moment(date).format('MMM / YYYY').replace('/', 'de')}</TextDate>
              <CaretRightOutlined
                onClick={() => {
                  if (moment(date).format('MMM / YYYY') === moment().format('MMM / YYYY')) return
                  setDate(moment(date).add(1, 'M'))
                }}
                style={{
                  color:
                    moment(date).format('MMM / YYYY') === moment().format('MMM / YYYY')
                      ? darkColor.borderTable
                      : isDarkMode
                      ? '#fff'
                      : darkColor.backgroundColor,
                  cursor: moment(date).format('MMM / YYYY') === moment().format('MMM / YYYY') ? 'initial' : 'pointer',
                }}
              />
            </ContainerDate>
          </Header>
        )}
      </div>
      {isBeforeMounthActually(date) && (
        <Header>
          <ContainerDate>
            <CaretLeftOutlined
              style={{
                color: isBeforeFirstJan2023(date) ? darkColor.borderTable : isDarkMode ? '#fff' : '#242424',
              }}
              onClick={() => {
                const isBefore = isBeforeFirstJan2023(date)
                if (isBefore) return
                setDate(moment(date).subtract(1, 'M'))
              }}
            />
            <TextDate>{moment(date).format('MMM / YYYY').replace('/', 'de')}</TextDate>
            <CaretRightOutlined
              onClick={() => {
                if (moment(date).format('MMM / YYYY') === moment().format('MMM / YYYY')) return
                setDate(moment(date).add(1, 'M'))
              }}
              style={{
                color:
                  moment(date).format('MMM / YYYY') === moment().format('MMM / YYYY')
                    ? darkColor.borderTable
                    : isDarkMode
                    ? '#fff'
                    : darkColor.backgroundColor,
                cursor: moment(date).format('MMM / YYYY') === moment().format('MMM / YYYY') ? 'initial' : 'pointer',
              }}
            />
          </ContainerDate>
        </Header>
      )}
      {isBeforeMounthActually(date) && (
        <div
          style={{
            padding: 20,
            borderRadius: 20,
            background: isDarkMode ? '#292f4c' : lightColor.bgColorMenu,
          }}
        >
          <h3
            style={{
              color: !isDarkMode ? '#292f4c' : '#fff',
            }}
          >
            Licenças por ambiente
          </h3>
          <p
            style={{
              color: !isDarkMode ? '#292f4c' : '#fff',
            }}
          >
            Altere o número de licenças para cada ambiente
          </p>
          <Table dataSource={dataTable} columns={columns} loading={loading} pagination={false} />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: 20,
            }}
          >
            {/* <Button
              onClick={() => onSubmit()}
              style={{ height: 40, width: 120 }}
              disabled={addIsScriptIsPermited() || isDataEqual()}
            >
              Salvar
            </Button> */}
          </div>
        </div>
      )}
      <Drawer
        title="Contratar licenças"
        placement="right"
        closable={true}
        destroyOnClose={true}
        onClose={() => setOpen(!open)}
        open={open}
        width="40vw"
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <p>Informe a quantidade de licenças que você deseja contratar:</p>
            <Table
              columns={[
                {
                  title: 'Contrato',
                  render: (text) => <p>Licenças</p>,
                },
                {
                  title: 'Produto',
                  render: (text) => <p>Apps</p>,
                },
                {
                  title: 'Quantidade',
                  render: (text) => <MoreLicence setTotal={setTotal} total={total} />,
                },
              ]}
              dataSource={[{}]}
              pagination={false}
            />
          </div>

          <Button
            type="primary"
            size="large"
            onClick={() => onContrat()}
            loading={loadingMore}
            style={{
              width: '100%',
              height: 50,
            }}
          >
            Contratar
          </Button>
        </div>
      </Drawer>
    </ContainerLicenca>
  )
}
