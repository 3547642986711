/* eslint-disable */
export const TOKEN_KEY = '@rocketpdv:jwt'
export const USER_KEY = '@rock-at/user'

export const getToken = (): string | null => localStorage.getItem(TOKEN_KEY)
export const setToken = (token: string) => window.localStorage.setItem(TOKEN_KEY, token)
export const setUser = (user: any) => window.localStorage.setItem(USER_KEY, JSON.stringify(user))
export const getUser = () => {
  const user = window.localStorage.getItem(USER_KEY)
  if (user) return user
  return ''
}

export const getUserId = () => {
  const user = window.localStorage.getItem(USER_KEY)
  if (user) return JSON.parse(user).user_id
  return ''
}


export const getWorkspaceId = () => {
  const user = window.localStorage.getItem(USER_KEY)
  if (user) return JSON.parse(user).id
  return ''
}
