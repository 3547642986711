import { ContainerModal } from './style'
import { MultipleMigrate } from './multipleMigrate'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  promoters: any[]
}

export const ModalMigratePromoterNative = ({ visible, close, promoters }: IProps) => {
  return (
    <ContainerModal
      open={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      style={{ top: 60 }}
      destroyOnClose={true}
      width="80vw"
    >
      <h3>Migrar promotores</h3>
      {visible && <MultipleMigrate promoters={promoters} />}
    </ContainerModal>
  )
}
