import React from 'react'
import { useDark } from '../../context/contextDark'

interface IProps {
  value: any
  disponivel?: any
  change?: any
  campo?: any
}
export const Switch = ({ value, disponivel, change, campo }: IProps) => {
  const { isDarkMode } = useDark()

  // const changeValue = (type: 'sum' | 'sub') => {
  //   if (type === 'sum') {
  //     change(value + 1)
  //   } else {
  //     change(value - 1)
  //   }
  // }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {/* <RiSubtractLine
        onClick={() => {
          // changeValue('sub')
          if (value > campo) changeValue('sub')
        }}
        style={{
          marginRight: 5,
          cursor: value > campo ? 'pointer' : 'initial',
          color: value > campo ? darkColor.backgroundColor : darkColor.borderTable,
        }}
      /> */}
      <div
        style={{
          width: 70,
          height: 40,
          background: isDarkMode ? 'rgb(102, 0, 204)' : '#eaebf1',
          borderRadius: 4,
          textAlign: 'center',
          border: '3px solid',
          borderColor: isDarkMode ? 'rgb(102, 0, 204)' : '#eaebf1',
          fontSize: 13,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#272d3b !important',
        }}
      >
        {value}
      </div>
      {/* <IoMdAdd
        onClick={() => disponivel > 0 && changeValue('sum')}
        style={{
          marginLeft: 5,
          cursor: disponivel > 0 ? 'pointer' : 'initial',
          color: disponivel > 0 ? darkColor.backgroundColor : darkColor.borderTable,
        }}
      /> */}
    </div>
  )
}
