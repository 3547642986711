/* eslint-disable */
import { getUserId } from './login'

export const addIsScriptIsPermited = () => {
    const user = getUserId()
    const users = [
        394290, 383378, 374850, 390786, 390770, 383474, 383458, 390754, 383618, 383634, 383298, 428082, 383666, 374882,
        383522, 383170, 383570, 428930, 415058, 376514, 424370, 455074, 433938, 444818, 431890, 450386]
    return users.includes(user)

}
