/* eslint-disable */

import { BtnExport, ButtonLink, CardCreateUpload, Description, Title } from './style'
import * as XLSX from 'xlsx'
import { CloudUploadOutlined, CloudDownloadOutlined } from '@ant-design/icons'

import { Button, Modal, Select, message } from 'antd'
import { addIsScriptIsPermited } from '../../utils/blockUser'
import { useState } from 'react'
import api_v2_witout_native from '../../api/api-v2-witout-native'
import { getToken, getUserId } from '../../utils/login'
import { useTabActions } from '../../context/tabActions'


interface IProps {
  open: boolean
  onOpen(bool: boolean): void
}
export const ImportPut = ({ onOpen, open }: IProps) => {
  const { setActiveKey } = useTabActions()

  const checkFile = (list: any) => {
    let isWrong = false
    if (
      list[0][0] !== 'Colaborador(CPF)*' ||
      list[0][1] !== 'Motivo*' ||
      list[0][2] !== 'Período Início*' ||
      list[0][3] !== 'Período Fim*' ||
      list[0][4] !== 'Colaborador Substituto(CPF)*'
    ) {
      isWrong = true
    }




    return isWrong
  }

  const sendFile = async (file: any) => {
    if (file) {
      const form = new FormData()
      const fileRoute = file as Blob
      form.append('file', fileRoute)
      form.append('user_id', getUserId())


      try {
        await fetch(`https://ms-import.rock-at.com/import/promoter-put/stream`, {
          // await fetch(`http://localhost:7000/import/promoter-put/stream`, {
          method: 'POST',
          body: form as any,
          headers: {
            user_id: getUserId(),
            token: getToken() || ''
          },
        })

        setTimeout(() => {
          onOpen(false)
          setActiveKey('1')
        }, 500)

        Modal.success({
          title: 'Arquivo importado com sucesso!',
          content: 'Seu arquivo será processado, e ao finalizar você receberá um e-mail.',
          onOk() {
          },
        })
      } catch (error) {
        Modal.error({
          title: 'Ops!',
          content: 'Ocorreu um erro ao enviar arquivo, tente novamente mais tarde!',
          onOk() {
          },
        })
      }
    }

  }

  return (
    <Modal open={open} footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => onOpen(false)}
      closable={true}
      destroyOnClose={true}
    >
      <Title>Importar Afastamento</Title>
      <Description>Importe sua planilha automaticamente.</Description>
      <CardCreateUpload
        customRequest={async (file: any) => {
          const f = file.file
          const reader = new FileReader()

          let isWrong: any = false

          reader.onload = function (e: any) {
            if (addIsScriptIsPermited())
              return message.error('Você não tem autorização para mexer no roteiro.')
            var data = e.target.result
            let readedData = XLSX.read(data, { type: 'binary' })
            const wsname = readedData.SheetNames[0]
            const ws = readedData.Sheets[wsname]

            /* Convert array to json*/
            const dataParse: any = XLSX.utils.sheet_to_json(ws, { header: 1 })
            isWrong = checkFile(dataParse)
            if (isWrong) {
              message.error('Esse arquivo não é o modelo de importação correto.')
              return
            }
            sendFile(file.file)
          }

          reader.readAsBinaryString(f)
        }}
      >

        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Button style={{ backgroundColor: '#b56aff', marginTop: 6 }}>
            <CloudUploadOutlined />
            Importar
          </Button>
        </div>
      </CardCreateUpload>

      <ButtonLink>
        <CloudDownloadOutlined />
        <BtnExport
          href={
            'https://firebasestorage.googleapis.com/v0/b/rocketpdv-promoter-xlsx/o/native%2FAFASTAR_PROMOTOR.xlsx?alt=media&token=58d64375-2c20-41ec-b9d4-03a46ac5343c&_gl=1*1y4plmi*_ga*MTMxMDE2NTQyNy4xNjk2MjcxNzQy*_ga_CW55HF8NVT*MTY5Njg2NzI4OC4yLjEuMTY5Njg2NzQzOS42MC4wLjA.'
          }
          target='_blank'
        >
          Baixe o modelo de afastamento
        </BtnExport>
      </ButtonLink>

    </Modal>
  )
}
