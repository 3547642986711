import React from 'react'
import { IPropsFilter } from '../../pages/statusDay'
import { Tag } from 'antd'
import { CloseOutlined } from '@ant-design/icons'

interface IProps {
  filters: IPropsFilter
  setFilters(data: IPropsFilter): void
}

export const TaggFilter = ({ filters, setFilters }: IProps) => {
  const removeFilter = (index: number) => {
    // eslint-disable-next-line prefer-const
    let newList: any = []

    filters.filter?.forEach((item: any, key: number) => {
      if (index !== key) newList.push(item)
    })

    setFilters({ ...filters, filter: newList })
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 20,
        marginTop: 10,
      }}
    >
      {filters.filter &&
        filters.filter.length > 0 &&
        filters.filter.map((item: any, key: number) => (
          <Tag color="geekblue" onClick={() => removeFilter(key)} key={key} style={{ textTransform: 'uppercase' }}>
            {item.value === 'STORE'
              ? 'LOJA'
              : item.value === 'PRODUCT'
              ? 'PRODUTO'
              : item.value === 'true' && item.column.label === 'respondido'
              ? 'RESPONDIDO'
              : item.value === 'false' && item.column.label === 'respondido'
              ? 'NÃO RESPONDIDO'
              : item.value === 'true' && item.column.label === 'promoter_active'
              ? 'ATIVO'
              : item.value === 'false' && item.column.label === 'promoter_active'
              ? 'INATIVO'
              : item.value === 'COMPLETE' && item.column.label === 'status_visit'
              ? 'CONCLUÍDO'
              : item.value === 'PENDENT' && item.column.label === 'status_visit'
              ? 'PENDENTE'
              : item.value === 'IN_PROGRESS' && item.column.label === 'status_visit'
              ? 'EM PROGRESSO'
              : item.value === 'JUSTIFIED_ABSENCE' && item.column.label === 'status_visit'
              ? 'JUSTIFICADO'
              : item.value === 'true' && item.column.label === 'is_active'
              ? 'ATIVO'
              : item.value === 'false' && item.column.label === 'is_active'
              ? 'INATIVO'
              : item.value === 'true' && item.column.label === 'removals'
              ? 'AFASTADO'
              : item.value === 'false' && item.column.label === 'removals'
              ? 'NÃO AFASTADO'
              : item.value}
            <CloseOutlined style={{ color: 'red', cursor: 'pointer' }} />
          </Tag>
        ))}
    </div>
  )
}
