import { Modal, Tabs, TabsProps } from 'antd'
import React, { useState } from 'react'
import InfoCard from './inforCard'
// import Logs from './logs'
// import Conversation from './conversation'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { DrawerStyle } from '../ImportDrawer/style'

interface IProps {
  onCloseDrawer(bool: any): void
  open: boolean
  header: any[]
  valueRow: any
  defaultKey?: string
  reload(): void
}

const DrawerContentInfo = ({ onCloseDrawer, open, header, valueRow, defaultKey, reload }: IProps) => {
  const [change, setChange] = useState<boolean>(false)
  const [modal, contextHolder] = Modal.useModal()

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Informações',
      children: (
        <InfoCard
          header={header}
          valueRow={valueRow}
          reload={reload}
          open={open}
          change={change}
          setChange={setChange}
        />
      ),
    },
    // {
    //   key: '2',
    //   label: 'Log de atividades',
    //   children: <Logs />,
    // },
    // {
    //   key: '3',
    //   label: 'Conversas',
    //   children: <Conversation />,
    // },
  ]

  return (
    <DrawerStyle
      title="Informações detalhadas"
      placement="right"
      closable={true}
      onClose={() => {
        if (!change) return onCloseDrawer(false)
        modal.confirm({
          title: 'Deseja mudar de tab ?',
          icon: <ExclamationCircleFilled />,
          content: 'Você realizou mudanças no cadastro, ao mudar de tab você irá perder essas mudanças.',
          onOk() {
            setChange(false)
            onCloseDrawer(false)
          },
          onCancel() {
            return null
          },
        })
      }}
      open={open}
      destroyOnClose={true}
      mask={false}
      maskClosable={true}
      width="60vw"
    >
      <Tabs items={items} defaultActiveKey={defaultKey || '1'} />
      {contextHolder}
    </DrawerStyle>
  )
}

export default DrawerContentInfo
