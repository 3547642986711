import styled from 'styled-components'
import { lightColor } from '../../utils/styleColors'
import { useDark } from '../../context/contextDark'
import { darkColor } from '../../utils/styleColors'

export const ContainerTabAction = styled.div`
  border-top: 1px solid #f0f0f0;
  background-color: ${lightColor.white};

  ${() => {
    const { isDarkMode } = useDark()

    if (isDarkMode)
      return `
      background-color: ${darkColor.backgroundMenu};

      .ant-table-thead .ant-table-cell {
        background-color: ${darkColor.bgTable} !important;
      }
    
      .ant-table-container {
        border-left: 6px solid #7158c1 !important;
        background: ${darkColor.bgTable} !important;
      }
    
      .ant-table-cell {
        background: ${darkColor.bgTable} ;
        border-color: ${darkColor.borderTable} !important;
      }
      th {
        border-color: ${darkColor.borderTable} !important;

      }

      table {
        border-color: ${darkColor.borderTable} !important;
      }
      && .tableOutside {
        background: transparent !important;
      }

      && .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >thead>tr>th {
        color: ${darkColor.white} !important;
      }

      && .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tbody>tr>td {
        color: ${darkColor.white} !important;
      }

      && .ant-pagination {
        color: ${darkColor.white} !important;
      }

      && .ant-pagination .ant-pagination-item a{
        color: ${darkColor.white} !important;
      }

      && .ant-pagination .ant-pagination-item-active {
        background-color: transparent !important;
      }

      && .ant-pagination-item-link, .ant-pagination-item-ellipsis {
        color: ${darkColor.white} !important;
      }

      && .ant-select-selector {
        color: ${darkColor.white} !important;
        background-color: ${darkColor.primary} !important;
        border-color: ${darkColor.primary} !important;
      }

      && .ant-select-dropdown {
        color: ${darkColor.white} !important;
        background-color: ${darkColor.primary} !important;
      }

      && .ant-select-item {
        color: ${darkColor.white} !important;
      }


      && .ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >td {
        background: ${darkColor.primaryLight};
      }

      && .ant-table-wrapper .ant-table-tbody >tr.ant-table-row:hover >td {
        background: ${darkColor.primaryLight};
      }

      &&& .tableOutside {
        background: transparent !important;
      }

    `

    return ''
  }}

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: rgba(50, 51, 56, 0.3) -7px -5px 10px;
    color: ${lightColor.blackTextBold};
    ${() => {
      const { isDarkMode } = useDark()

      if (isDarkMode)
        return `
      color: ${lightColor.white};


    `

      return ''
    }}
    border-bottom: 1px solid #f0f0f0;
    height: 40px;
    padding: 0 10px;
    p {
      font-size: 16px;
      font-weight: 500;
      text-transform: uppercase;
      margin: 0;
    }
  }
`

export const ContainerTable = styled.div`
  ${() => {
    const { isDarkMode } = useDark()

    if (isDarkMode)
      return `
      background-color: ${darkColor.backgroundMenu};

      .ant-table-thead .ant-table-cell {
        background-color: ${darkColor.bgTable} !important;
      }
    
      .ant-table-container {
        border-left: 6px solid #7158c1 !important;
        background: ${darkColor.bgTable} !important;
      }
    
      .ant-table-cell {
        background: ${darkColor.bgTable} ;
        border-color: ${darkColor.borderTable} !important;
      }
      th {
        border-color: ${darkColor.borderTable} !important;

      }

      table {
        border-color: ${darkColor.borderTable} !important;
      }
      && .tableOutside {
        background: transparent !important;
      }

      && .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >thead>tr>th {
        color: ${darkColor.white} !important;
      }

      && .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tbody>tr>td {
        color: ${darkColor.white} !important;
      }

      && .ant-pagination {
        color: ${darkColor.white} !important;
      }

      && .ant-pagination .ant-pagination-item a{
        color: ${darkColor.white} !important;
      }

      && .ant-pagination .ant-pagination-item-active {
        background-color: transparent !important;
      }

      && .ant-pagination-item-link, .ant-pagination-item-ellipsis {
        color: ${darkColor.white} !important;
      }

      && .ant-select-selector {
        color: ${darkColor.white} !important;
        background-color: ${darkColor.primary} !important;
        border-color: ${darkColor.primary} !important;
      }

      && .ant-select-dropdown {
        color: ${darkColor.white} !important;
        background-color: ${darkColor.primary} !important;
      }

      && .ant-select-item {
        color: ${darkColor.white} !important;
      }


      && .ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >td {
        background: ${darkColor.primaryLight};
      }

      && .ant-table-wrapper .ant-table-tbody >tr.ant-table-row:hover >td {
        background: ${darkColor.primaryLight};
      }

      &&& .tableOutside {
        background: transparent !important;
      }

      &&& .ant-table-wrapper .ant-table-thead >tr>th {
        color: ${darkColor.white} !important;
      }

    `

    return ''
  }}
`
