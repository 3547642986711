import { Col, Row } from 'antd'
import React from 'react'
import { IPropsRenderPage } from '../../pages/promoter'
import { Import, IPropsDrawer } from './actionsButton'
import { Filter, IFilter, IPropsTypeFilter } from './filter'
import { OcultColumn } from './ocultColumn'
import { ISelectedsOrders, OrderColumn } from './orderColumn'
import { SearchHeader } from './searchHeader'
import { IPropsWorkspace, PersonWorkspace } from './workspaceFilter'
import { DatePick } from './datePick'

interface IProps extends IPropsDrawer {
  columns: any
  updateColumn(list: any): void
  changeWorkspace(workspace: IPropsWorkspace[]): void
  columnsOrigin: IPropsRenderPage
  setSearch(text: string): void
  changeOrder(order: ISelectedsOrders[]): void
  changeFilter(filter: IFilter[]): void
  rangePick?(date: any): void
  simpleFilter?: IPropsTypeFilter[]
  filter: any
}

export const DefaultFilters = ({
  columns,
  updateColumn,
  changeWorkspace,
  columnsOrigin,
  changeOrder,
  setSearch,
  changeFilter,
  actionsMenu,
  clickButton,
  rangePick,
  filter,
  simpleFilter,
}: IProps) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Row gutter={[10, 10]}>
        <Col span={4}>
          <div
            style={{
              display: 'flex',
            }}
          >
            <Import actionsMenu={actionsMenu} clickButton={clickButton} />

            <div
              style={{
                margin: '0 10px',
              }}
            >
              <SearchHeader setSearch={setSearch} />
            </div>

            <PersonWorkspace changeWorkspace={changeWorkspace} />
            {/* {columnsOrigin && columnsOrigin.filter_options && columnsOrigin.filter_options?.length > 0 && ( */}
            <Filter
              changeFilter={changeFilter}
              columns={columns}
              columnsOrigin={columnsOrigin}
              simpleFilter={simpleFilter}
              filter={filter}
            />
            {/* )} */}
            <OcultColumn columns={columns} updateColumn={updateColumn} columnsOrigin={columnsOrigin} />
            <OrderColumn columns={columns} columnsOrigin={columnsOrigin} changeOrder={changeOrder} />
          </div>
        </Col>
      </Row>
      {rangePick && <DatePick rangePick={rangePick} />}
    </div>
  )
}
