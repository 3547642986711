import { useEffect, useState } from 'react'
import { Select } from 'antd'
import { Controller } from 'react-hook-form'
import { lightColor } from '../../utils/styleColors'
import { useDark } from '../../context/contextDark'
import api_v2_witout_native from '../../api/api-v2-witout-native'

interface IProps {
  required?: string
  name: string
  label: string
  control: any
  disabled?: boolean
  type?: string
  style?: any
  url: string
  workspace?: any
}

interface Option {
  value: any
  label: string
}

export const HookSelectedRemote = ({ control, name, label, required, disabled, url, workspace, ...rest }: IProps) => {
  const { isDarkMode } = useDark()
  const [options, setOptions] = useState<Option[]>([])

  async function fetchOptions(searchValue: string) {
    try {
      const response = await api_v2_witout_native.get(`/search/${url}?search=${searchValue.trim()}`, {
        headers: {
          wokspaceId: workspace.id,
          workspace: workspace.slug,
        },
      })
      setOptions(response.data.map((item: Option) => ({ label: item.label, value: item.label })))
    } catch (error) {
      console.error('Erro ao obter as opções:', error)
    }
  }

  useEffect(() => {
    fetchOptions('')
  }, [url])

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required || undefined,
      }}
      render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
        <div>
          <p
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              color: lightColor.blackTextBold,
              marginBottom: 8,
              opacity: 0.74,
            }}
          >
            {label}
          </p>
          <Select
            ref={ref}
            id={name}
            onChange={(value) => {
              onChange(value ? value : null)
            }}
            value={value}
            placeholder={`${label}`}
            style={{
              width: '100%',
            }}
            disabled={disabled}
            options={options}
            {...rest}
            allowClear={true}
            className={isDarkMode ? 'dark-drop-down' : ''}
            filterOption={false}
            showSearch={true}
            onSearch={fetchOptions} // Corrigido aqui, usando o nome correto da função
          />
          {!!error && (
            <p
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                color: lightColor.redBold,
                marginBottom: 8,
                opacity: 0.74,
              }}
            >
              {error?.message?.toString()}
            </p>
          )}
        </div>
      )}
    />
  )
}
