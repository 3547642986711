import React from 'react'
import { ContainerCardLicenca, TitleCard, ValueCard } from './style'
import { darkColor } from '../../utils/styleColors'

interface IProps {
  bgColor?: string
  title: string
  value: string | number
  btn?: any
}
export const CardLicenca = ({ bgColor, title, value, btn }: IProps) => {
  return (
    <ContainerCardLicenca style={{ backgroundColor: bgColor || 'white' }}>
      <TitleCard style={{ color: bgColor ? 'white' : darkColor.borderTable }}>{title}</TitleCard>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        <ValueCard style={{ color: bgColor ? 'white' : darkColor.borderTable }}>{value}</ValueCard>
        {btn && btn}
      </div>
    </ContainerCardLicenca>
  )
}
