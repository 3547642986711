import { createContext, ReactNode, useContext, useState } from 'react'
import { FooterActions } from '../Components/FooterActions'

interface TabActionsProviderProps {
  children: ReactNode
}

type TabActionsContextData = {
  activeKey: string | undefined
  setActiveKey(value: string): void
}

const TabActionsContext = createContext({} as TabActionsContextData)

export type ListProcess = {
  id: string
  percent: string
  calback_url_error: string | null
  timestamp: Date
  context: string
}

export function TabActionsProvider({ children }: TabActionsProviderProps) {
  const [activeKey, setActiveKey] = useState<string | undefined>('-1')

  return (
    <TabActionsContext.Provider
      value={{
        activeKey,
        setActiveKey,
      }}
    >
      {children}

      <FooterActions activeKey={activeKey} setActiveKey={setActiveKey} />
    </TabActionsContext.Provider>
  )
}

export const useTabActions = () => useContext(TabActionsContext)
