import { Tooltip } from 'antd'
import ActiveLink from './ActiveLink'

interface NavLinkProps {
  icon: any
  children: string
  href: string
}
export default function NavLink({ icon, href, children }: NavLinkProps) {
  return (
    <Tooltip title={children} placement="right">
      <div
        style={{
          marginBottom: 15,
        }}
      >
        <ActiveLink href={href}>{icon}</ActiveLink>
      </div>
    </Tooltip>
  )
}
