import React, { useEffect } from 'react'
import { ContainerModal } from './style'
import { Button, Col, Row, message } from 'antd'
import { useForm } from 'react-hook-form'
import { HookInput } from '../form/hookInput'
import { lightColor } from '../../utils/styleColors'
import { HookSelectedFixed } from '../form/hookSelectedFixed'
import api from '../../api'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  reload?(): void
  type: string
  edit: any
}

export const ModalCreateJustify = ({ visible, close, reload, type, edit }: IProps) => {
  const { handleSubmit, formState, control, reset, setValue } = useForm({})
  const { isLoading } = formState

  async function submit(body: any) {
    try {
      let url = ''
      if (type === 'falta') url = '/ms-promoter/absences'
      if (type === 'afastamento') url = '/ms-promoter/removes'
      if (type === 'desligamento') url = '/ms-promoter/shut-down'
      if (type === 'tarefa') url = '/ms-promoter/justification-task'

      const { data } = await api.put(url, body)
      if (data.error) {
        message.error('Erro ao gerar falta')
        return
      }
      reset()
      message.success('Sucesso ao gerar justificativa')
      if (reload) reload()
    } catch (err: any) {
      message.error('Erro ao gerar falta')
    }
  }

  useEffect(() => {
    reset()
  }, [visible])

  useEffect(() => {
    if (edit && Object.keys(edit).length > 0) {
      if (edit.name) setValue('name', edit.name)
      setValue('id', edit.id)
      if (type === 'falta' || type === 'tarefa') {
        setValue('is_active_mobile', edit.is_active_mobile ? 'true' : 'false')
        setValue('picture_required', edit.picture_required ? 'true' : 'false')
        setValue('obs_required', edit.obs_required ? 'true' : 'false')
      }
    }
  }, [edit])

  return (
    <ContainerModal
      open={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      style={{ top: 60 }}
      destroyOnClose={true}
      width="50vw"
    >
      <h3>Adicionar {type}</h3>
      <form autoComplete="off" onSubmit={handleSubmit(submit)}>
        <Row gutter={[20, 20]} style={{ marginBottom: 20 }}>
          <Col span={24}>
            <HookInput name="name" label="Nome completo" control={control} required="Campo obrigatório" />
          </Col>
          {(type === 'falta' || type === 'tarefa') && (
            <Col span={8}>
              <HookSelectedFixed
                name="is_active_mobile"
                label="Ativo no mobile?"
                control={control}
                required="Campo obrigatório"
                options={[
                  { label: 'Sim', value: 'true' },
                  { label: 'Não', value: 'false' },
                ]}
              />
            </Col>
          )}
          {(type === 'falta' || type === 'tarefa') && (
            <Col span={8}>
              <HookSelectedFixed
                name="picture_required"
                label="Foto obrigatória"
                control={control}
                required="Campo obrigatório"
                options={[
                  { label: 'Sim', value: 'true' },
                  { label: 'Não', value: 'false' },
                ]}
              />
            </Col>
          )}
          {(type === 'falta' || type === 'tarefa') && (
            <Col span={8}>
              <HookSelectedFixed
                name="obs_required"
                label="Observação obrigatória"
                control={control}
                required="Campo obrigatório"
                options={[
                  { label: 'Sim', value: 'true' },
                  { label: 'Não', value: 'false' },
                ]}
              />
            </Col>
          )}
        </Row>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            loading={isLoading}
            htmlType="submit"
            style={{
              width: '100%',
              height: 40,
              fontSize: 17,
              backgroundColor: lightColor.primary,
              color: lightColor.white,
            }}
          >
            Salvar
          </Button>
        </div>
      </form>
    </ContainerModal>
  )
}
