import React from 'react'
import { ContainerDate, Date } from './style'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import { useDark } from '../../../context/contextDark'

interface IProps {
  rangePick?(date: any): void
}

export const DatePick = ({ rangePick }: IProps) => {
  const { isDarkMode } = useDark()
  const dateFormat = 'DD/MM/YYYY'

  const disabledDate = (current: Dayjs) => {
    const date = dayjs().subtract(1, 'day')
    const tooLate = current.diff(date, 'days') > 0
    return tooLate
  }

  return (
    <ContainerDate>
      <Date
        presets={[
          {
            label: 'Hoje',
            value: [dayjs(), dayjs()],
          },
          {
            label: 'Esta semana',
            value: [dayjs().startOf('week'), dayjs().endOf('week')],
          },
          {
            label: 'Este mês',
            value: [dayjs().startOf('month'), dayjs().endOf('month')],
          },
        ]}
        format={dateFormat}
        defaultValue={[dayjs().subtract(0, 'day'), dayjs().subtract(0, 'day')]}
        onChange={rangePick}
        disabledDate={disabledDate}
        allowClear={false}
        dropdownClassName={isDarkMode ? 'dark-calendar' : ''}
      />
    </ContainerDate>
  )
}
