import DefaultPage from '../Components/defaultPage'
import { Header } from '../Components/header'
import { Button, Dropdown, Menu, Modal, Table, Tag, Tooltip, message } from 'antd'
import type { MenuProps } from 'antd'
import { useDark } from '../context/contextDark'
import { darkColor, lightColor } from '../utils/styleColors'
import { useEffect, useState } from 'react'
import { BiExit } from 'react-icons/bi'
import { ClockCircleOutlined, ApiOutlined, ExclamationCircleFilled } from '@ant-design/icons'
import { FiGitPullRequest } from 'react-icons/fi'
import { ContainerConfig } from '../Components/Config/style'
import { ModalCreateJustify } from '../Components/ModalCreateJustify'
import { Licenca } from '../Components/licenca'
import { BsKey } from 'react-icons/bs'
import api from '../api'
import { AiOutlineEdit, AiOutlinePoweroff } from 'react-icons/ai'
import { MoreOutlined } from '@ant-design/icons'
import { CiCircleRemove } from 'react-icons/ci'

type MenuItem = Required<MenuProps>['items'][number]
interface IPropsTableResult {
  result: any
  total: number
}
const Config = () => {
  const { isDarkMode } = useDark()
  const [active, setActive] = useState<string>('falta')
  const [createFaults, setCreateFaults] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [edit, setEdit] = useState<any>({})
  const [resultTable, setResultTable] = useState<IPropsTableResult>({} as IPropsTableResult)
  const [modal, contextHolder] = Modal.useModal()

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group'
  ): MenuItem {
    return {
      key,
      icon,
      children,
      label,
      type,
    } as MenuItem
  }

  const items: MenuProps['items'] = [
    getItem(
      <p style={{ margin: 0, color: isDarkMode ? darkColor.white : lightColor.blackText }}>Justificativa</p>,
      'grp',
      null,
      [
        getItem('Falta', 'falta', <BiExit />),
        getItem('Afastamento', 'afastamento', <ClockCircleOutlined />),
        getItem('Desligamento', 'desligamento', <ApiOutlined />),
        getItem('Tarefa', 'tarefa', <FiGitPullRequest />),
      ],
      'group'
    ),
    getItem(
      <p style={{ margin: 0, color: isDarkMode ? darkColor.white : lightColor.blackText }}>Avançado</p>,
      'grp',
      null,
      [getItem('Licenças', 'licenças', <BsKey />)],
      'group'
    ),
  ]

  const deleteJustify = async (url: string, value: any) => {
    try {
      setLoading(true)
      const { data } = await api.post(url, value)
      if (data.error) {
        message.error('Erro ao deletar justificativa')
        return
      }
      getResult()
      message.success('Sucesso ao deletar justificativa')
    } catch (err: any) {
      message.error(err.response.data.message)
    } finally {
      setLoading(false)
    }
  }

  const columns: any = {
    falta: [
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Ativo',
        dataIndex: 'is_active',
        key: 'is_active',
        align: 'center',
        render: (value: any) => (value ? <Tag color="green">Sim</Tag> : <Tag color="red">Não</Tag>),
      },
      {
        title: 'Ativo no mobile',
        dataIndex: 'is_active_mobile',
        key: 'is_active_mobile',
        align: 'center',
        render: (value: any) => (value ? <Tag color="green">Sim</Tag> : <Tag color="red">Não</Tag>),
      },
      {
        title: 'Foto obrigatória',
        dataIndex: 'picture_required',
        key: 'picture_required',
        align: 'center',
        render: (value: any) => (value ? <Tag color="green">Sim</Tag> : <Tag color="red">Não</Tag>),
      },
      {
        title: 'Observação obrigatória',
        dataIndex: 'obs_required',
        key: 'obs_required',
        align: 'center',
        render: (value: any) => (value ? <Tag color="green">Sim</Tag> : <Tag color="red">Não</Tag>),
      },
      {
        title: 'Ações',
        align: 'center',
        render: (value: any) => (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="1"
                  icon={<CiCircleRemove />}
                  onClick={() => {
                    modal.confirm({
                      title: 'Deseja deletar justificativa ?',
                      icon: <ExclamationCircleFilled />,
                      async onOk() {
                        deleteJustify('/ms-promoter/delete-absences', value)
                      },
                      onCancel() {
                        return null
                      },
                    })
                  }}
                >
                  <Tooltip placement="top" title={'Deletar'}>
                    Deletar
                  </Tooltip>
                </Menu.Item>
                <Menu.Item
                  key="2"
                  icon={<AiOutlineEdit />}
                  onClick={() => {
                    setEdit(value)
                    setCreateFaults(true)
                  }}
                >
                  <Tooltip placement="top" title={'Editar'}>
                    Editar
                  </Tooltip>
                </Menu.Item>
                <Menu.Item
                  key="3"
                  icon={<AiOutlinePoweroff />}
                  onClick={async () => {
                    try {
                      const { data } = await api.put('/ms-promoter/absences', {
                        ...value,
                        is_active: value.is_active ? false : true,
                      })
                      if (data.error) {
                        message.error('Erro ao editar falta')
                        return
                      }
                      getFaults()
                      message.success('Sucesso ao editar justificativa')
                    } catch (err: any) {
                      message.error(err.response.data.message)
                    }
                  }}
                >
                  <Tooltip placement="top" title={value.is_active ? 'Inativar' : 'Ativar'}>
                    {value.is_active ? 'Inativar' : 'Ativar'}
                  </Tooltip>
                </Menu.Item>
              </Menu>
            }
          >
            <Button size="small" shape="circle" icon={<MoreOutlined />} />
          </Dropdown>
        ),
      },
    ],
    afastamento: [
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Ativo',
        dataIndex: 'is_active',
        key: 'is_active',
        render: (value: any) => (value ? <Tag color="green">Sim</Tag> : <Tag color="red">Não</Tag>),
      },
      {
        title: 'Ações',
        align: 'center',
        render: (value: any) => (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="1"
                  icon={<CiCircleRemove />}
                  onClick={() => {
                    modal.confirm({
                      title: 'Deseja deletar justificativa ?',
                      icon: <ExclamationCircleFilled />,
                      async onOk() {
                        deleteJustify('/ms-promoter/delete-removes', value)
                      },
                      onCancel() {
                        return null
                      },
                    })
                  }}
                >
                  <Tooltip placement="top" title={'Deletar'}>
                    Deletar
                  </Tooltip>
                </Menu.Item>
                <Menu.Item
                  key="2"
                  icon={<AiOutlineEdit />}
                  onClick={() => {
                    setEdit(value)
                    setCreateFaults(true)
                  }}
                >
                  <Tooltip placement="top" title={'Editar'}>
                    Editar
                  </Tooltip>
                </Menu.Item>
                <Menu.Item
                  key="3"
                  icon={<AiOutlinePoweroff />}
                  onClick={async () => {
                    try {
                      const { data } = await api.put('/ms-promoter/removes', {
                        ...value,
                        is_active: value.is_active ? false : true,
                      })
                      if (data.error) {
                        message.error('Erro ao editar falta')
                        return
                      }
                      getRemoves()
                      message.success('Sucesso ao editar justificativa')
                    } catch (err: any) {
                      message.error(err.response.data.message)
                    }
                  }}
                >
                  <Tooltip placement="top" title={value.is_active ? 'Inativar' : 'Ativar'}>
                    {value.is_active ? 'Inativar' : 'Ativar'}
                  </Tooltip>
                </Menu.Item>
              </Menu>
            }
          >
            <Button size="small" shape="circle" icon={<MoreOutlined />} />
          </Dropdown>
        ),
      },
    ],
    desligamento: [
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Ativo',
        dataIndex: 'is_active',
        key: 'is_active',
        render: (value: any) => (value ? <Tag color="green">Sim</Tag> : <Tag color="red">Não</Tag>),
      },
      {
        title: 'Ações',
        align: 'center',
        render: (value: any) => (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="1"
                  icon={<CiCircleRemove />}
                  onClick={() => {
                    modal.confirm({
                      title: 'Deseja deletar justificativa ?',
                      icon: <ExclamationCircleFilled />,
                      async onOk() {
                        deleteJustify('/ms-promoter/delete-shut-down', value)
                      },
                      onCancel() {
                        return null
                      },
                    })
                  }}
                >
                  <Tooltip placement="top" title={'Deletar'}>
                    Deletar
                  </Tooltip>
                </Menu.Item>
                <Menu.Item
                  key="2"
                  icon={<AiOutlineEdit />}
                  onClick={() => {
                    setEdit(value)
                    setCreateFaults(true)
                  }}
                >
                  <Tooltip placement="top" title={'Editar'}>
                    Editar
                  </Tooltip>
                </Menu.Item>
                <Menu.Item
                  key="3"
                  icon={<AiOutlinePoweroff />}
                  onClick={async () => {
                    try {
                      const { data } = await api.put('/ms-promoter/shut-down', {
                        ...value,
                        is_active: value.is_active ? false : true,
                      })
                      if (data.error) {
                        message.error('Erro ao editar falta')
                        return
                      }
                      getShutDown()
                      message.success('Sucesso ao editar justificativa')
                    } catch (err: any) {
                      message.error(err.response.data.message)
                    }
                  }}
                >
                  <Tooltip placement="top" title={value.is_active ? 'Inativar' : 'Ativar'}>
                    {value.is_active ? 'Inativar' : 'Ativar'}
                  </Tooltip>
                </Menu.Item>
              </Menu>
            }
          >
            <Button size="small" shape="circle" icon={<MoreOutlined />} />
          </Dropdown>
        ),
      },
    ],
    tarefa: [
      {
        title: 'Nome',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Ativo',
        dataIndex: 'is_active',
        key: 'is_active',
        align: 'center',
        render: (value: any) => (value ? <Tag color="green">Sim</Tag> : <Tag color="red">Não</Tag>),
      },
      {
        title: 'Ativo no mobile',
        dataIndex: 'is_active_mobile',
        key: 'is_active_mobile',
        align: 'center',
        render: (value: any) => (value ? <Tag color="green">Sim</Tag> : <Tag color="red">Não</Tag>),
      },
      {
        title: 'Foto obrigatória',
        dataIndex: 'picture_required',
        key: 'picture_required',
        align: 'center',
        render: (value: any) => (value ? <Tag color="green">Sim</Tag> : <Tag color="red">Não</Tag>),
      },
      {
        title: 'Observação obrigatória',
        dataIndex: 'obs_required',
        key: 'obs_required',
        align: 'center',
        render: (value: any) => (value ? <Tag color="green">Sim</Tag> : <Tag color="red">Não</Tag>),
      },
      {
        title: 'Ações',
        align: 'center',
        render: (value: any) => (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="1"
                  icon={<CiCircleRemove />}
                  onClick={() => {
                    modal.confirm({
                      title: 'Deseja deletar justificativa ?',
                      icon: <ExclamationCircleFilled />,
                      async onOk() {
                        deleteJustify('/ms-promoter/delete-justification-task', value)
                      },
                      onCancel() {
                        return null
                      },
                    })
                  }}
                >
                  <Tooltip placement="top" title={'Deletar'}>
                    Deletar
                  </Tooltip>
                </Menu.Item>
                <Menu.Item
                  key="2"
                  icon={<AiOutlineEdit />}
                  onClick={() => {
                    setEdit(value)
                    setCreateFaults(true)
                  }}
                >
                  <Tooltip placement="top" title={'Editar'}>
                    Editar
                  </Tooltip>
                </Menu.Item>
                <Menu.Item
                  key="3"
                  icon={<AiOutlinePoweroff />}
                  onClick={async () => {
                    try {
                      const { data } = await api.put('/ms-promoter/justification-task', {
                        ...value,
                        is_active: value.is_active ? false : true,
                      })
                      if (data.error) {
                        message.error('Erro ao editar falta')
                        return
                      }
                      getTask()
                      message.success('Sucesso ao editar justificativa')
                    } catch (err: any) {
                      message.error(err.response.data.message)
                    }
                  }}
                >
                  <Tooltip placement="top" title={value.is_active ? 'Inativar' : 'Ativar'}>
                    {value.is_active ? 'Inativar' : 'Ativar'}
                  </Tooltip>
                </Menu.Item>
              </Menu>
            }
          >
            <Button size="small" shape="circle" icon={<MoreOutlined />} />
          </Dropdown>
        ),
      },
    ],
  }

  const btnFaults = ['falta', 'afastamento', 'desligamento', 'tarefa']

  const getFaults = async () => {
    try {
      setLoading(true)
      const { data } = await api.post('/ms-promoter/absences', {
        perpage: 10,
        page: 1,
      })
      setResultTable(data)
    } catch (error) {
      setResultTable({ total: 0, result: [] })
      message.error('Erro ao listas justificativa')
    } finally {
      setLoading(false)
    }
  }

  const getRemoves = async () => {
    try {
      setLoading(true)
      const { data } = await api.post('/ms-promoter/removes', {
        perpage: 10,
        page: 1,
      })
      setResultTable(data)
    } catch (error) {
      setResultTable({ total: 0, result: [] })
      message.error('Erro ao listas justificativa')
    } finally {
      setLoading(false)
    }
  }

  const getShutDown = async () => {
    try {
      setLoading(true)
      const { data } = await api.post('/ms-promoter/shut-down', {
        perpage: 10,
        page: 1,
      })
      setResultTable(data)
    } catch (error) {
      setResultTable({ total: 0, result: [] })
      message.error('Erro ao listas justificativa')
    } finally {
      setLoading(false)
    }
  }
  const getTask = async () => {
    try {
      setLoading(true)
      const { data } = await api.post('/ms-promoter/justification-task', {
        perpage: 10,
        page: 1,
      })
      setResultTable(data)
    } catch (error) {
      setResultTable({ total: 0, result: [] })
      message.error('Erro ao listas justificativa')
    } finally {
      setLoading(false)
    }
  }

  const handleTable = async (page: number, pageSize: number) => {
    try {
      setLoading(true)
      let url = ''

      if (active === 'falta') url = '/ms-promoter/absences'
      if (active === 'afastamento') url = '/ms-promoter/removes'
      if (active === 'desligamento') url = '/ms-promoter/shut-down'
      if (active === 'tarefa') url = '/ms-promoter/justification-task'

      const pagination = {
        pageSize,
        page,
      }
      const { data } = await api.post(url, pagination)
      setResultTable(data)
    } catch (error) {
      message.error('Erro ao listas justificativa')
    } finally {
      setLoading(false)
    }
  }

  const getResult = () => {
    if (active === 'falta') getFaults()
    if (active === 'afastamento') getRemoves()
    if (active === 'desligamento') getShutDown()
    if (active === 'tarefa') getTask()
  }

  useEffect(() => {
    getResult()
  }, [active])

  return (
    <DefaultPage>
      <Header title="Configurações" />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        {btnFaults.includes(active) && (
          <Button
            size="large"
            style={{
              marginBottom: 30,
              marginTop: 30,
            }}
            onClick={() => setCreateFaults(!createFaults)}
          >
            Adicionar
          </Button>
        )}
      </div>
      <ContainerConfig
        style={{
          height: '85%',
        }}
      >
        <Menu
          onClick={(e) => setActive(e.key)}
          style={
            isDarkMode
              ? {
                  width: 256,
                  height: '80%',
                  backgroundColor: darkColor.backgroundMenu,
                  color: darkColor.white,
                  border: 'none',
                  borderRadius: 5,
                }
              : { width: 256, height: '80%', backgroundColor: lightColor.bgColorMenu, borderRadius: 5, border: 'none' }
          }
          mode="inline"
          items={items}
          selectedKeys={[active]}
        />
        <div
          style={{
            width: '100%',
            marginLeft: 50,
            height: '100%',
          }}
        >
          {btnFaults.includes(active) && (
            <Table
              dataSource={resultTable.result}
              columns={columns[active]}
              loading={loading}
              pagination={{
                total: resultTable.total,
                size: 'small',
                showSizeChanger: true,
                onChange: handleTable,
                showTotal: (totalData, [, totalPerPage]) => `Exibindo ${totalPerPage} de ${totalData} de registros`,
              }}
            />
          )}
          {active === 'licenças' && <Licenca />}
        </div>
      </ContainerConfig>
      <ModalCreateJustify
        type={active}
        edit={edit}
        visible={createFaults}
        close={() => {
          setCreateFaults(false)
          setEdit({})
        }}
        reload={() => {
          getResult()
          setCreateFaults(false)
          setEdit({})
        }}
      />
      {contextHolder}
    </DefaultPage>
  )
}

export default Config
