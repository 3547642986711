import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'

import Promoter from '../pages/promoter'
import Loading from '../pages/loading'
import StatusDay from '../pages/statusDay'
import Workspaces from '../pages/workspaces'
import Tasks from '../pages/tasks'
import Visits from '../pages/visits'
import Config from '../pages/config'

export const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/promoter"
          element={<Promoter context="promoter" title="Promotor" titlePage="Base de promotores" />}
        />
        <Route path="/visits" element={<Visits context="visits" title="Visitas" titlePage="Visitas" />} />
        <Route
          path="/status_day"
          element={<StatusDay context="status_day" title="Status Day" titlePage="Status Day" />}
        />
        <Route path="/workspaces" element={<Workspaces />} />
        <Route path="/config" element={<Config />} />
        <Route path="/tasks" element={<Tasks context="tasks" title="Atividades" titlePage="Base de atividades" />} />
        <Route path="/loading" element={<Loading />} />
      </Routes>
    </Router>
  )
}
