import 'antd/dist/reset.css'
import './globalStyle.css'
import { ConfigProvider } from 'antd'
import { AppRoutes } from './routes'
import { DarkProvider } from './context/contextDark'
import pt_BR from 'antd/lib/locale/pt_BR'
import { lightColor } from './utils/styleColors'
import { TabActionsProvider } from './context/tabActions'
import { OpenInfoProvider } from './context/openInfoDrawer'

const App: React.FC = () => {
  return (
    <ConfigProvider
      locale={pt_BR}
      theme={{
        token: {
          colorPrimary: lightColor.primary,
        },
      }}
    >
      <DarkProvider>
        <OpenInfoProvider>
          <TabActionsProvider>
            <AppRoutes />
          </TabActionsProvider>
        </OpenInfoProvider>
      </DarkProvider>
    </ConfigProvider>
  )
}

export default App
