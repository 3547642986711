import { Alert, Button, DatePicker, message, Select, Spin, Table, Input, Checkbox } from 'antd'
import { useEffect, useState } from 'react'
import { SimpleSearchWithoutForm } from '../form/SimpleSearchWithoutForm'
import { ContainerModal } from './style'
import type { ColumnsType } from 'antd/es/table'
import { lightColor } from '../../utils/styleColors'
import dayjs from 'dayjs'
import { SelectOffReason } from '../../utils/selectsUfs'
import api from '../../api'

const { TextArea } = Input
const dateFormat = 'DD/MM/YYYY'

interface IProps {
  visible: boolean
  close: any
  promoters: any[]
}

interface ISelecteds {
  user_id: number
  colaborador_substituto?: number
  motivo_demissao?: number | null
  data_demissao?: string | null
  observacao_demissao?: string | null
  ambiente_id?: number
  transferir_roteiro?: boolean
}

export const ModalPromoterOff = ({ visible, close, promoters }: IProps) => {
  const [selections, setSelctions] = useState<ISelecteds[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setSelctions([])
  }, [visible])

  const columns: ColumnsType<any> = [
    {
      title: 'Colaborador',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Data do desligamento*',
      render: (value, row) => {
        return (
          <>
            <DatePicker
              format={dateFormat}
              defaultValue={dayjs()}
              onChange={(valueDate, format) => {
                const index = selections.findIndex((promoter) => promoter.user_id === row.id)
                if (index > -1) {
                  const newList = selections
                  newList[index].data_demissao = dayjs(valueDate).format('YYYY-MM-DD')
                  setSelctions(newList)
                } else {
                  const newList = [
                    ...selections,
                    {
                      user_id: row.id,
                      data_demissao: dayjs(valueDate).format('YYYY-MM-DD'),
                      ambiente_id: row.workspace.id,
                    },
                  ]

                  setSelctions(newList)
                }
              }}
            />
          </>
        )
      },
    },
    {
      title: 'Motivo do desligamento*',
      render: (value, row) => {
        return (
          <Select
            onChange={(target) => {
              const index = selections.findIndex((promoter) => promoter.user_id === row.id)
              if (index > -1) {
                const newList = selections
                newList[index].motivo_demissao = target
                setSelctions(newList)
              } else {
                const newList = [
                  ...selections,
                  {
                    user_id: row.id,
                    motivo_demissao: target,
                    ambiente_id: row.workspace.id,
                  },
                ]

                setSelctions(newList)
              }
            }}
            placeholder={'motivo'}
            style={{
              width: '100%',
            }}
            options={SelectOffReason}
            allowClear={true}
          />
        )
      },
    },
    {
      title: 'Observação do desligamento',
      render: (value, row) => {
        return (
          <TextArea
            placeholder="Observação"
            onChange={(evt) => {
              const index = selections.findIndex((promoter) => promoter.user_id === row.id)
              if (index > -1) {
                const newList = selections
                newList[index].observacao_demissao = evt.target.value
                setSelctions(newList)
              } else {
                const newList = [
                  ...selections,
                  {
                    user_id: row.id,
                    observacao_demissao: evt.target.value,
                    ambiente_id: row.workspace.id,
                  },
                ]

                setSelctions(newList)
              }
            }}
          />
        )
      },
    },
    {
      title: 'Colaborador substituto',
      render: (value, row) => {
        return (
          <SimpleSearchWithoutForm
            placeholder="nome colaborador"
            path="promoters"
            onChange={(target) => {
              const index = selections.findIndex((promoter) => promoter.user_id === row.id)
              if (index > -1) {
                const newList = [...selections] // Criar uma nova cópia do array
                newList[index] = { ...newList[index], colaborador_substituto: target.value } // Atualizar apenas a propriedade necessária
                setSelctions(newList)
              } else {
                const newList = [
                  ...selections,
                  {
                    user_id: row.id,
                    colaborador_substituto: target.value,
                    ambiente_id: row.workspace.id,
                  },
                ]
                setSelctions(newList)
              }
            }}
            isMult={false}
            config={'&is_active=true'}
            header={{
              headers: {
                workspace: row.workspace.slug,
              },
            }}
          />
        )
      },
    },
    {
      title: 'Assumir roteiro',
      render: (value, row) => {
        return (
          <Checkbox
            disabled={selections.find((promoter) => promoter.user_id === row.id)?.colaborador_substituto ? false : true}
            onChange={(evt) => {
              const index = selections.findIndex((promoter) => promoter.user_id === row.id)
              if (index > -1) {
                const newList = selections
                newList[index].transferir_roteiro = evt.target.checked
                setSelctions(newList)
              } else {
                const newList = [
                  ...selections,
                  {
                    user_id: row.id,
                    transferir_roteiro: evt.target.checked,
                    ambiente_id: row.workspace.id,
                  },
                ]

                setSelctions(newList)
              }
            }}
          >
            Assumir roteiro
          </Checkbox>
        )
      },
    },
  ]

  const transferScript = async () => {
    try {
      const body = selections.map((item) => ({
        ...item,
        data_demissao: item.data_demissao || dayjs().format('YYYY-MM-DD'),
        demitido_via: 'NATIVO',
        observacao_demissao: item.observacao_demissao || '',
        transferir_roteiro: item.transferir_roteiro || false,
        colaborador_substituto: item.colaborador_substituto || '',
      }))

      const nomotivo_demissao = body.findIndex((item) => !item.motivo_demissao)
      if (nomotivo_demissao > -1) {
        return message.error('Selecione o motivo de desligamento de todos os promotores.')
      }

      if (body?.length !== promoters?.length) {
        return message.error('Preencha todos os campos obrigatórios')
      }
      setLoading(true)
      for await (const user of body) {
        const url = `/ms-promoter/demitir/${user.user_id}`
        const body = {
          ambiente_id: user.ambiente_id,
          motivo_demissao: user.motivo_demissao,
          data_demissao: user.data_demissao,
          observacao_demissao: user.observacao_demissao,
          demitido_via: user.demitido_via,
          transferir_roteiro: user.transferir_roteiro,
          colaborador_substituto: user.colaborador_substituto,
        }
        await api.post(url, body)
      }
      message.success('Promotor desligado com sucesso')
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    } catch (error) {
      return null
    } finally {
      setLoading(false)
    }
  }

  return (
    <ContainerModal
      open={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      style={{ top: 10 }}
      destroyOnClose={true}
      width="70vw"
    >
      <h3>Desligar promotor</h3>
      <div>
        <Table
          dataSource={promoters}
          columns={columns}
          scroll={{
            y: 400,
          }}
        />
        <Alert
          message={
            'Atenção! Caso deixe alguma opção vazia, o colaborador será apenas inativado sem transferência de roteiro.'
          }
          type="warning"
          style={{
            marginTop: 10,
            marginBottom: 10,
          }}
        />
        {loading ? (
          <Spin />
        ) : (
          <Button
            style={{
              backgroundColor: lightColor.primary,
              color: lightColor.white,
              fontWeight: 'bold',
              fontSize: 14,
              width: '100%',
              marginTop: 20,
              height: 40,
            }}
            onClick={async () => transferScript()}
          >
            Desligar e transferir
          </Button>
        )}
      </div>
    </ContainerModal>
  )
}
