import React from 'react'
import { IoMdAdd } from 'react-icons/io'
import { RiSubtractLine } from 'react-icons/ri'
import { darkColor } from '../../utils/styleColors'

interface IProps {
  total: number
  setTotal(body: number): void
}
export const MoreLicence = ({ total, setTotal }: IProps) => {
  const changeValue = (type: 'sum' | 'sub') => {
    if (type === 'sum') {
      setTotal(total + 1)
    } else {
      setTotal(total - 1)
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <RiSubtractLine
        onClick={() => total > 1 && changeValue('sub')}
        style={{
          marginRight: 5,
          cursor: total > 1 ? 'pointer' : 'initial',
          color: total > 1 ? darkColor.backgroundColor : darkColor.borderTable,
        }}
      />
      <div
        style={{
          width: 70,
          height: 40,
          background: '#eaebf1',
          borderRadius: 4,
          textAlign: 'center',
          border: '3px solid',
          borderColor: '#eaebf1',
          fontSize: 13,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {total}
      </div>
      <IoMdAdd
        onClick={() => changeValue('sum')}
        style={{
          marginLeft: 5,
          cursor: 'pointer',
          color: darkColor.backgroundColor,
        }}
      />
    </div>
  )
}
