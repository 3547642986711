import { Select } from 'antd'
import { Controller } from 'react-hook-form'
import { lightColor } from '../../utils/styleColors'
import { useDark } from '../../context/contextDark'

interface IProps {
  required?: string
  name: string
  label: string
  control: any
  options: Array<{
    value: any
    label: string
  }>
  disabled?: boolean
  type?: string
  style?: any
}

export const HookSelectedFixed = ({ control, name, label, required, disabled, options, ...rest }: IProps) => {
  const { isDarkMode } = useDark()

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: required || undefined,
      }}
      render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
        <div>
          <p
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              color: lightColor.blackTextBold,
              marginBottom: 8,
              opacity: 0.74,
            }}
          >
            {label}
          </p>
          <Select
            ref={ref}
            showSearch
            id={name}
            filterOption={(input, option) => (option?.label.toLocaleLowerCase() ?? '').includes(input.toLowerCase())}
            onChange={(value) => {
              onChange(value ? value : null)
            }}
            value={value}
            placeholder={`${label}`}
            style={{
              width: '100%',
            }}
            disabled={disabled}
            options={options}
            {...rest}
            allowClear={true}
            className={isDarkMode ? 'dark-drop-down' : ''}
          />
          {!!error && (
            <p
              style={{
                fontSize: 14,
                fontWeight: 'bold',
                color: lightColor.redBold,
                marginBottom: 8,
                opacity: 0.74,
              }}
            >
              {error?.message?.toString()}
            </p>
          )}
        </div>
      )}
    />
  )
}
