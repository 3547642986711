import styled from 'styled-components'
import { Modal, Upload } from 'antd'
import { darkColor, lightColor } from '../../utils/styleColors'
import { useDark } from '../../context/contextDark'

export const ContainerModal = styled(Modal)`
  top: 20 !important;

  h3 {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: ${lightColor.colorH3Modal};
  }

  &&& .ant-modal {
    top: 20 !important;
  }

  &&& .ant-modal-content {
    border-radius: 12px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }

  &&& .ant-modal-body {
    border-radius: 12px;
  }

  &&& .ant-modal-close-x {
    color: ${lightColor.red};
  }

  &&& .ant-upload.ant-upload-drag {
    width: auto !important;
    height: auto !important;
    padding: 28px 5px;
  }

  ${() => {
    const { isDarkMode } = useDark()

    if (isDarkMode)
      return `
      && .ant-modal-content {
        background-color: ${darkColor.bgTable} !important;
      }

      p {
        color: ${darkColor.white} !important;
      }

      input {
        color: ${darkColor.white} !important;
        background-color: ${darkColor.bgTable} !important;
      }

      input::placeholder {
        color: rgb(177 177 177) !important;
      }

      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        color: ${darkColor.white} !important;
        background-color: ${darkColor.bgTable} !important;
      }

      .ant-select-arrow {
        color: ${darkColor.white} !important;
      }
      .ant-btn-default {
        border-color ${darkColor.primary} !important;
      }
    `

    return ''
  }}
`
export const ContainerStep1 = styled.div`
  display: flex;
  width: 650px;
  height: 350px;
  align-items: center;
  justify-content: center;
`

export const ContainerUpload = styled.div`
  &&& .ant-btn {
    width: 100% !important;
  }
`

export const CardCreate = styled.div`
  width: 292px;
  height: 316px;
  border-radius: 12px;
  border: 1px solid ${lightColor.borderColor};
  background-color: ${lightColor.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 36px 26px 56px;

  img {
    width: 60px;
    height: 60px;
  }

  h4 {
    color: ${lightColor.blackTextBold};
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 600;
  }

  p {
    margin-bottom: 0;
    color: ${lightColor.blackSwicth};
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }

  i {
    color: ${lightColor.colorI};
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: italic;
    text-align: center;
    width: 100%;
  }
`

export const CardCreateUpload = styled(Upload.Dragger)`
  width: 292px;
  height: 316px;
  border-radius: 12px;
  border: 1px solid ${lightColor.borderColor};
  background-color: ${lightColor.white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 36px 26px 56px;

  img {
    width: 60px;
    height: 60px;
  }

  h4 {
    color: ${lightColor.colorH4};
    font-family: 'Open Sans';
    font-size: 20px;
    font-weight: 600;
  }

  p {
    margin-bottom: 0;
    color: ${lightColor.colorI};
    font-family: 'Open Sans';
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }

  i {
    color: ${lightColor.colorI};
    font-family: 'Open Sans';
    font-size: 16px;
    font-style: italic;
    text-align: center;
    width: 100%;
  }
`

export const Button = styled.button`
  cursor: pointer;
  padding: 7px;
  border-radius: 5px;
  background-color: ${lightColor.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${lightColor.white};
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 400;
  border: none;

  svg {
    font-size: 20px;
  }
`

export const ContainerStep2 = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;

  img {
    width: 92px;
    height: 92px;
  }

  h3 {
    color: ${lightColor.colorH4};
    font-family: 'Open Sans';
    font-size: 22px;
    font-weight: 700;
  }

  input {
    background-color: ${lightColor.bgColorMenu};
  }

  &&& .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    min-height: 38px !important;
    background-color: ${lightColor.bgColorMenu};
  }
`

export const LabelCheck = styled.div`
  text-align: center;
  font-weight: bold;
  margin: 10px 0;
`

export const InputCheckbox = styled.div`
  label,
  span {
    display: flex;
    margin: 0 auto;
  }
`
