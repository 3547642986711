/* eslint-disable */
import { Button, Col, message, Modal, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { HookInput } from '../../form/hookInput'
import { HookSelectedFixed } from '../../form/hookSelectedFixed'
import { HookDate } from '../../form/hookDate'
import { HookToogle } from '../../form/hookToogle'
import { HookInputMoney } from '../../form/hookInputMoney'
import { HookFile } from '../../form/hookFile'
import { lightColor } from '../../../utils/styleColors'
// import api from '../../../api'
import { HookSelectedRemote } from '../../form/HookSelectedRemote'
import api from '../../../api'
import { addIsScriptIsPermited } from '../../../utils/blockUser'
// import { HookSelectedRemote } from '../../form/HookSelectedRemote'

interface IProps {
  names: any[]
  valueRow: any
  reload(): void
  changeTab: any
  change: boolean
  setDefaultValuesRow: any
}
export const ContentTab = ({ names, valueRow, reload, changeTab, change, setDefaultValuesRow }: IProps) => {
  const [loading, setLoading] = useState(false)
  const [modal, contextHolder] = Modal.useModal()

  const { handleSubmit, reset, control, setValue, watch, getValues } = useForm({})

  async function submit(body: any) {
    setLoading(true)
    try {
      const { data } = await api.post(`${names[0].api_change_group}/${valueRow.id}`, body)

      if (data.error) {
        modal.error({
          title: 'Erro',
          content: data.messages.map((msg: string, index: number) => <p key={index}>{msg}</p>),
        })
      } else {
        message.success(data.messages[0])
      }
      reload()
      setDefaultValuesRow({ ...valueRow, ...body })
      changeTab(false)
    } catch (err) {
      message.error('Erro ao alterar dados')

      setLoading(false)
    }
    setLoading(false)
  }

  function acessarPropriedade(objeto: any, propriedade: any) {
    // Dividindo a string em partes
    const partes = propriedade.split('.');
    // Inicializa o valor como o objeto original
    let valor = objeto;
    // Itera sobre as partes e acessa a propriedade do objeto
    for (const parte of partes) {
      // Se a propriedade não existe, retorna undefined
      if (!valor || !valor.hasOwnProperty(parte)) {
        return undefined;
      }
      valor = valor[parte];
    }
    return valor;
  }

  useEffect(() => {
    reset()
    if (valueRow && names && !change) {
      console.log(names, 'names')
      names.forEach((item) => {

        // const dataIndex = typeof item.key === 'string' ? item.key : item.key?.join('.')
        // const value =
        //   typeof item.key === 'string'
        //     ? valueRow[item.key]
        //     : valueRow[item.key[0]] && item.key[1]
        //       ? valueRow[item.key[0]][item.key[1]]
        //       : undefined

        const dataIndex = item.key.split('.')
        const value = acessarPropriedade(valueRow, item.key);
          try {
            setValue(typeof item.key === 'string' ? item.key : item.key?.join('.'), value)

          } catch (e) {
            console.log(e, value, dataIndex)
          }
      })
    }
  }, [valueRow])

  const checkChange = () => {
    let changeValue = false
    Object.keys(watch()).forEach((keyObject) => {
      if (typeof valueRow[keyObject] !== 'object' && valueRow[keyObject] !== getValues(keyObject)) changeValue = true
    })
    changeTab(changeValue)
  }

  useEffect(() => {
    checkChange()
  }, [watch()])

  return (
    <form
      autoComplete="off"
      onSubmit={handleSubmit(submit)}
      style={{
        paddingLeft: 20,
        paddingRight: 20,
        maxHeight: 'calc(100vh - 210px)',
        overflow: 'auto',
      }}
    >
      <Row gutter={[20, 20]}>
        {names &&
          names?.length > 0 &&
          names.map((item, index) => (
            <Col span={12} key={index}>
              {item.api_search_field && (
                <HookSelectedRemote
                  name={typeof item.key === 'string' ? item.key : item.key?.join('.')}
                  label={item.label}
                  control={control}
                  required={item.required ? 'Campo obrigatório' : undefined}
                  disabled={!item.editable || item.editable === false}
                  url={item.api_search_field}
                  workspace={valueRow.workspace}
                />
              )}
              {item.type === 'image' && (
                <HookFile
                  name={typeof item.key === 'string' ? item.key : item.key?.join('.')}
                  label={item.label}
                  control={control}
                  required={item.required ? 'Campo obrigatório' : undefined}
                  disabled={!item.editable || item.editable === false}
                />
              )}
              {item.type === 'money' && (
                <HookInputMoney
                  name={typeof item.key === 'string' ? item.key : item.key?.join('.')}
                  label={item.label}
                  control={control}
                  required={item.required ? 'Campo obrigatório' : undefined}
                  disabled={!item.editable || item.editable === false}
                />
              )}
              {item.type === 'boolean' && (
                <HookToogle
                  name={typeof item.key === 'string' ? item.key : item.key?.join('.')}
                  label={item.label}
                  control={control}
                  required={item.required ? 'Campo obrigatório' : undefined}
                  disabled={!item.editable || item.editable === false}
                />
              )}
              {item.type === 'date' && (
                <HookDate
                  name={typeof item.key === 'string' ? item.key : item.key?.join('.')}
                  label={item.label}
                  control={control}
                  required={item.required ? 'Campo obrigatório' : undefined}
                  disabled={!item.editable || item.editable === false}
                />
              )}
              {!item.api_search_field && item.type === 'string' && (
                <HookInput
                  name={typeof item.key === 'string' ? item.key : item.key?.join('.')}
                  label={item.label}
                  control={control}
                  mask={item.mask}
                  required={item.required ? 'Campo obrigatório' : undefined}
                  disabled={!item.editable || item.editable === false}
                />
              )}
              {item.type === 'select' && (
                <HookSelectedFixed
                  name={typeof item.key === 'string' ? item.key : item.key?.join('.')}
                  label={item.label}
                  control={control}
                  required={item.required ? 'Campo obrigatório' : undefined}
                  options={item.select_values && item.select_values.length > 0 ? item.select_values : []}
                  disabled={!item.editable || item.editable === false}
                />
              )}
            </Col>
          ))}
      </Row>

      <div
        style={{
          marginTop: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Button htmlType="submit" style={{ width: 150, height: 40, borderColor: lightColor.primary }} loading={loading} disabled={addIsScriptIsPermited()}>
          Salvar
        </Button>
      </div>
      {contextHolder}
    </form>
  )
}
