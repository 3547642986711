import { Spin } from 'antd'
import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { setToken, setUser } from '../utils/login'
import { lightColor } from '../utils/styleColors'

export default function Loading() {
  const history = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const token = searchParams.get('token')
    const user = searchParams.get('user')
    if (token && user) {
      try {
        setToken(token.toString())
        setUser(JSON.parse(user.toString()))

        setTimeout(() => {
          history('/promoter')
        }, 1500)
      } catch (error) {
        null
      }
    }
  }, [])

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100vh',
        zIndex: 9999,
        position: 'absolute',
        width: '100vw',
        background: `linear-gradient(90deg, ${lightColor.activeLink} 0%, ${lightColor.activeBoldLink} 100%)`,
      }}
    >
      <Spin size="large" style={{ color: lightColor.white }} />
      <p
        style={{
          marginTop: 20,
          fontSize: '18px',
          fontWeight: 'bold',
          color: lightColor.white,
        }}
      >
        CARREGANDO...
      </p>
    </div>
  )
}
