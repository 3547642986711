import styled from 'styled-components'
import { darkColor, lightColor } from '../../utils/styleColors'
import { useDark } from '../../context/contextDark'

export const ContainerBase = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  display: flex;
`

export const ContainerMenu = styled.div`
  width: 66px;
  background-color: ${lightColor.menuBackground};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 15px 0;
  height: 100vh;

  ${() => {
    const { isDarkMode } = useDark()

    if (isDarkMode)
      return `
        background-color: ${darkColor.backgroundMenu};
      
    `

    return ''
  }}
`

export const ContainerMenuTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
`

export const ContainerColumnTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`

export const ContainerColumnBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
`
interface IPropsExtend {
  open: boolean
}

export const ContainerMenuActions = styled.div<IPropsExtend>`
  display: flex;
  height: 100vh;
  width: ${({ open }) => (open ? '300px' : '29px')};
  background-color: ${lightColor.bgColorMenu};
  border-right: 1px solid ${lightColor.borderMenuColor};

  transition: all 0.5s;
`
