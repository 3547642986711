import styled from 'styled-components'
import { useDark } from '../../context/contextDark'

export const ContainerLicenca = styled.div`
  margin-bottom: 100px;

  ${() => {
    const { isDarkMode } = useDark()

    if (isDarkMode)
      return `

      .ant-table-cell {
        color: #fff !important;
      }

      .ant-btn  {
        color: #fff !important;
      }

      #create-licence {
        color: #272d3b !important;

      }
     
    `

    return ''
  }}
`

export const ContainerCardLicenca = styled.div`
  border-radius: 8px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
  height: 154px;
  font-style: normal;
  font-weight: bold;
  padding: 16px;
  width: 100%;
`

export const TitleCard = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 26px;
`
export const ValueCard = styled.p`
  margin: 0;
  font-size: 60px;
  line-height: 42px;
  align-self: center;
  margin-top: 30px;
`

export const Header = styled.div`
  width: 100%;
  height: 86px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 25px;
`

export const ContainerDate = styled.div`
  width: 280px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #272d3b;
  font-size: 23px;
`

export const TextDate = styled.p`
  color: #242424;
  font-size: 27px;
  font-weight: 600;
  margin: 0;

  ${() => {
    const { isDarkMode } = useDark()

    if (isDarkMode)
      return `
      color: #fff;
      
    `

    return ''
  }}
`
