import React from 'react'
import { ContainerHeaderTab } from './style'
interface IProps {
  title: string
  icon: any
}
export const DeafultTitleTab = ({ title, icon }: IProps) => {
  return (
    <ContainerHeaderTab>
      {icon}
      <p>{title}</p>
    </ContainerHeaderTab>
  )
}
