import styled from 'styled-components'
import { useDark } from '../../context/contextDark'
import { darkColor } from '../../utils/styleColors'

export const ContainerDate = styled.div`
  ${() => {
    const { isDarkMode } = useDark()

    if (isDarkMode)
      return `
        &&& .ant-picker-dropdown .ant-picker-panel {
            background-color: ${darkColor.bgTable} !important;
        }
    `

    return ''
  }}
`
