import React from 'react'
import { ContainerSearceh } from './style'
import { BsSearch } from 'react-icons/bs'
import { Input } from 'antd'

interface IProps {
  setSearch(text: string): void
}
export const SearchHeader = ({ setSearch }: IProps) => {
  return (
    <ContainerSearceh>
      <Input placeholder="Pesquisar" prefix={<BsSearch />} onChange={(evt) => setSearch(evt.target.value)} />
    </ContainerSearceh>
  )
}
