import { ContainerFilterWorkspace, OcultButton, Portal } from './style'
import { Col, Row, Select } from 'antd'
import { useEffect, useState } from 'react'
import { IoMdAdd } from 'react-icons/io'
import { HiChevronUpDown } from 'react-icons/hi2'
import { IPropsRenderPage, ISelect } from '../../../pages/promoter'
import { Popover } from 'react-tiny-popover'
import { CiCircleRemove } from 'react-icons/ci'
import { GrFormClose } from 'react-icons/gr'
import { darkColor, lightColor } from '../../../utils/styleColors'
import { useDark } from '../../../context/contextDark'

interface IProps {
  columns: any
  columnsOrigin: IPropsRenderPage
  changeOrder: any
}

export interface ISelectedsOrders {
  column: ISelect | null
  order: ISelect | null
}

export const OrderColumn = ({ columnsOrigin, changeOrder }: IProps) => {
  const { isDarkMode } = useDark()

  const [selecteds, setSelecteds] = useState<ISelectedsOrders[]>([])
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const getColumns = () => {
    const columnList: any = []

    columnsOrigin.sort_options.forEach((column: any) => {
      if (selecteds.findIndex((item) => item.column?.label === column.label) === -1) {
        columnList.push({ label: column.label, value: column.key })
      }
    })

    return columnList
  }

  useEffect(() => {
    if (selecteds.filter((item) => item.column === null || item.order === null).length === 0) {
      changeOrder(selecteds)
    }
  }, [selecteds])
  return (
    <>
      <Popover
        isOpen={isPopoverOpen}
        content={
          <Portal>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
            >
              <h3 style={{ margin: 0 }}>Classificar por</h3>
              <CiCircleRemove
                style={{
                  color: lightColor.red,
                  fontSize: 20,
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
                onClick={() => setIsPopoverOpen(false)}
              />
            </div>
            {selecteds?.length === 0 ? (
              <p>
                Classifique seus elementos por prioridade, data de criação, preço ou qualquer coluna que você tenha no
                seu quadro.
              </p>
            ) : (
              <>
                {selecteds?.length > 0 &&
                  selecteds.map((list, index) => (
                    <Row gutter={[15, 10]} style={{ marginBottom: 15 }} key={index}>
                      <Col span={11}>
                        <Select
                          style={{ width: '100%' }}
                          value={list.column}
                          onChange={(value, selected) => {
                            const newList: any = selecteds
                            newList[index].column = selected
                            setSelecteds([...newList])
                          }}
                          popupClassName={isDarkMode ? 'dark-drop-down' : ''}
                          options={getColumns()}
                        />
                      </Col>
                      <Col span={11}>
                        <Select
                          style={{ width: '100%' }}
                          onChange={(value, selected) => {
                            const newList: any = selecteds
                            newList[index].order = selected
                            setSelecteds([...newList])
                          }}
                          popupClassName={isDarkMode ? 'dark-drop-down' : ''}
                          value={list.order}
                          options={[
                            { label: 'Crescente', value: 'ASC' },
                            { label: 'Decrescente', value: 'DESC' },
                          ]}
                        />
                      </Col>
                      <Col span={2}>
                        <GrFormClose
                          fontSize={20}
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            const newList: any = []
                            selecteds.forEach((item: any, indexRemove: any) => {
                              if (indexRemove !== index) newList.push(item)
                            })

                            setSelecteds([...newList])
                          }}
                          color={isDarkMode ? darkColor.white : lightColor.red}
                        />
                      </Col>
                    </Row>
                  ))}
              </>
            )}
            <OcultButton
              onClick={() =>
                setSelecteds([
                  ...selecteds,
                  {
                    column: null,
                    order: null,
                  },
                ])
              }
            >
              <IoMdAdd />
              <p>Adicionar nova classificação</p>
            </OcultButton>
          </Portal>
        }
        positions={['bottom']}
      >
        <ContainerFilterWorkspace active={false} onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
          <HiChevronUpDown style={{ marginRight: 10 }} fontSize={20} />
          <span>Ordernar {selecteds?.length > 0 && `/ ${selecteds?.length}`}</span>
        </ContainerFilterWorkspace>
      </Popover>
    </>
  )
}
