/* eslint-disable */
import { UserSwitchOutlined, ClockCircleOutlined, ApiOutlined, LockOutlined, PoweroffOutlined } from '@ant-design/icons'
import { useState } from 'react'
import { ModalMigratePromoterNative } from '../ModalMigratePromoterNative'
import { ModalPasswordNative } from '../ModalPasswordNative'
import { ModalPromoterOff } from '../ModalPromoterOff'
import { ModalPutAwayNative } from '../ModalPutAwayNative'
import { Fade } from './style'
import { darkColor, lightColor } from '../../utils/styleColors'
import { useDark } from '../../context/contextDark'
import { Modal, message } from 'antd'
import api from '../../api'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { addIsScriptIsPermited } from '../../utils/blockUser'

interface IProps {
  visible: boolean
  promoters: any[]
}
export const ActionsNative: React.FC<IProps> = ({ visible, promoters }: IProps) => {
  const { isDarkMode } = useDark()
  const [modal, contextHolder] = Modal.useModal()

  const [openPromoterOff, setOpenPromoterOff] = useState(false)
  const [openMigratePromoter, setOpenMigratePromoter] = useState(false)
  const [openRemove, setOpenRemove] = useState(false)
  const [openChangePassword, setOpenChangePassword] = useState(false)

  return (
    <>
      {!addIsScriptIsPermited() &&
        <Fade visible={visible}>
          {visible && (
            <>
              <div
                style={{
                  width: '65%',
                  height: '66px',
                  position: 'fixed',
                  backgroundColor: isDarkMode ? darkColor.bgTable : lightColor.white,
                  bottom: 40,
                  zIndex: 9,
                  left: '50%',
                  transform: 'translate(-50%, 0)',
                  borderRadius: 6,
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <div
                  style={{
                    width: '10%',
                    height: '100%',
                    backgroundColor: lightColor.purple,
                    borderRadius: '6px 0 0 6px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: lightColor.white,
                    fontSize: 30,
                  }}
                >
                  {promoters?.length}
                </div>

                <div
                  style={{
                    width: '30%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  Promotores selecionados
                </div>

                <div
                  style={{
                    width: '70%',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: 20,
                    color: lightColor.black,
                    border: 'none',
                    background: 'transparent',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      flexDirection: 'column',
                      cursor: 'pointer',
                      color: isDarkMode ? darkColor.white : lightColor.blackTextBold,
                    }}
                    onClick={() => setOpenPromoterOff(!openPromoterOff)}
                  >
                    <ApiOutlined style={{ fontSize: 20 }} />
                    Desligar
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      flexDirection: 'column',
                      cursor: 'pointer',
                      color: isDarkMode ? darkColor.white : lightColor.blackTextBold,
                    }}
                    onClick={() =>
                      modal.confirm({
                        title: 'Reativar promotor',
                        icon: <ExclamationCircleFilled />,
                        content: `Você deseja reativar o total de: ${promoters.length} promotores ?`,
                        async onOk() {
                          for await (const item of promoters) {
                            await api.post(`/ms-promoter/reativar/${item.id}`)
                          }
                          message.success('Promotor reativado com sucesso')

                          setTimeout(() => {
                            window.location.reload()
                          }, 1500)
                        },
                        onCancel() {
                          return null
                        },
                      })
                    }
                  >
                    <PoweroffOutlined style={{ fontSize: 20 }} />
                    Reativar
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      flexDirection: 'column',
                      cursor: 'pointer',
                      color: isDarkMode ? darkColor.white : lightColor.blackTextBold,
                      border: 'none',
                      background: 'transparent',
                    }}
                    onClick={() => setOpenRemove(!openRemove)}
                  >
                    <ClockCircleOutlined style={{ fontSize: 20 }} />
                    Afastar
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      flexDirection: 'column',
                      cursor: 'pointer',
                      color: isDarkMode ? darkColor.white : lightColor.blackTextBold,
                      border: 'none',
                      background: 'transparent',
                    }}
                    onClick={() => setOpenChangePassword(!openChangePassword)}
                  >
                    <LockOutlined style={{ fontSize: 20 }} />
                    Mudar senha
                  </div>
                  <button
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      flexDirection: 'column',
                      cursor: 'pointer',
                      color: isDarkMode ? darkColor.white : lightColor.blackTextBold,
                      border: 'none',
                      background: 'transparent',
                    }}
                    onClick={() => setOpenMigratePromoter(!openMigratePromoter)}
                  >
                    <UserSwitchOutlined style={{ fontSize: 20 }} />
                    Migrar ambiente
                  </button>
                </div>
              </div>
              <ModalPromoterOff visible={openPromoterOff} close={setOpenPromoterOff} promoters={promoters} />
              <ModalMigratePromoterNative
                visible={openMigratePromoter}
                close={setOpenMigratePromoter}
                promoters={promoters}
              />
              <ModalPasswordNative visible={openChangePassword} close={setOpenChangePassword} promoters={promoters} />
              <ModalPutAwayNative promoters={promoters} visible={openRemove} close={setOpenRemove} />
            </>
          )}
          {contextHolder}
        </Fade>
      }
    </>

  )
}
