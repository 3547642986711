import { Button, DatePicker, message, Spin, Table } from 'antd'
import { useState } from 'react'
import { SimpleSearchWithoutForm } from '../form/SimpleSearchWithoutForm'
import type { ColumnsType } from 'antd/es/table'
import moment from 'moment'
import api_v2 from '../../api/api-v2'
import dayjs from 'dayjs'
import { lightColor } from '../../utils/styleColors'

const dateFormat = 'DD/MM/YYYY'
interface IProps {
  promoters: any[]
}

interface ISelecteds {
  user_id: number
  removal_id: number
  transfer_to?: number
  date?: string[]
}
export const MultiplePutAway = ({ promoters }: IProps) => {
  const [loading, setLoading] = useState(false)
  const [selections, setSelctions] = useState<ISelecteds[]>([])

  const columns: ColumnsType<any> = [
    {
      title: 'Colaborador',
      dataIndex: 'nome',
      key: 'nome',
    },
    {
      title: 'Motivo',
      render: (value, row) => {
        return (
          <SimpleSearchWithoutForm
            placeholder="Selecione o motivo"
            path="removals"
            isMult={false}
            value={
              selections[selections.findIndex((promoter) => promoter.user_id === row.id)]?.removal_id ||
              row.removal_name
            }
            onChange={(target) => {
              const index = selections.findIndex((promoter) => promoter.user_id === row.id)
              if (index > -1) {
                const newList = selections
                newList[index].removal_id = target.value
                setSelctions(newList)
              } else {
                const newList = selections
                newList.push({
                  user_id: row.id,
                  removal_id: target.value,
                })

                setSelctions([...newList])
              }
            }}
            header={{
              headers: {
                workspace: row.workspace.slug,
              },
            }}
          />
        )
      },
    },
    {
      title: 'Período',
      render: (value, row) => {
        let valueInput: any = []
        const index = selections.findIndex((promoter) => promoter.user_id === row.id)

        if (row.removal_end_date && row.removal_start_date) {
          valueInput = [moment(new Date(row.removal_start_date)), moment(new Date(row.removal_end_date))]
        }
        if (index > -1) {
          valueInput = selections[index].date
        }
        return (
          <>
            <DatePicker.RangePicker
              format={dateFormat}
              disabled={selections.findIndex((promoter) => promoter.user_id === row.id) === -1}
              value={valueInput?.length > 0 ? [dayjs(valueInput[0]), dayjs(valueInput[1])] : undefined}
              onChange={(value, format) => {
                const index = selections.findIndex((promoter) => promoter.user_id === row.id)
                if (index > -1) {
                  const newList = selections
                  newList[index].date = format
                  setSelctions(newList)
                }
              }}
            />
          </>
        )
      },
    },
    {
      title: 'Colaborador Substituto',
      render: (value, row) => {
        return (
          <SimpleSearchWithoutForm
            placeholder="nome colaborador"
            path="promoters"
            disabled={selections.findIndex((promoter) => promoter.user_id === row.id) === -1}
            onChange={(target) => {
              const index = selections.findIndex((promoter) => promoter.user_id === row.id)
              if (index > -1) {
                const newList = selections
                newList[index].transfer_to = target.value
                setSelctions([...newList])
              }
            }}
            isMult={false}
            config={'&is_active=true'}
            header={{
              headers: {
                workspace: row.workspace.slug,
              },
            }}
          />
        )
      },
    },
  ]

  const submit = async () => {
    try {
      if (selections?.length !== promoters?.length) {
        return message.error('Selecione o motivo de todos os promotores.')
      }
      const noDate = selections.findIndex((item) => !item.date)
      if (noDate > -1) {
        return message.error('Selecione data de afastamento de todos os promotores.')
      }
      setLoading(false)

      for await (const body of selections) {
        if (body.date)
          await api_v2.post(`user/removal/${body.user_id}`, {
            removal: body.removal_id,
            range: [
              moment(body.date[0], 'DD/MM/YYYY').format('YYYY-MM-DD'),
              moment(body.date[1], 'DD/MM/YYYY').format('YYYY-MM-DD'),
            ],
          })
      }

      message.success('Sucesso ao afastar promotor')
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    } catch (error) {
      return null
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Table
        dataSource={promoters}
        columns={columns}
        scroll={{
          y: 400,
        }}
      />

      {loading ? (
        <Spin />
      ) : (
        <Button
          style={{
            backgroundColor: lightColor.primary,
            color: lightColor.white,
            fontWeight: 'bold',
            fontSize: 14,
            width: '100%',
            marginTop: 20,
            height: 40,
          }}
          onClick={async () => submit()}
        >
          Afastar promotor
        </Button>
      )}
    </>
  )
}
