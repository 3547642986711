import styled from 'styled-components'
import { darkColor } from '../../../utils/styleColors'
import { useDark } from '../../../context/contextDark'
import { Dropdown } from 'antd'
const { Button } = Dropdown

export const ContainerFilter = styled.div`
  padding-top: 30px;

  & .ant-checkbox-group {
    flex-direction: column;
    margin: 0;
    margin-top: 10px;
  }

  & .ant-checkbox-wrapper {
    margin: 0;
    margin-top: 10px;
  }
`

export const ContainerCheck = styled.div`
  margin-top: 20px;
  display: block;
  label {
    width: 100%;
  }
  p {
    margin: 0;
  }
  & .ant-checkbox-group {
    flex-direction: column;
    margin: 0;
    margin-top: 10px;
  }

  & .ant-checkbox-wrapper {
    margin: 0;
    margin-top: 10px;
  }
`

interface IpropsCollapse {
  color: string
}
export const BackgroundCollapses = styled.div<IpropsCollapse>`
  margin-top: 30px;

  & .ant-collapse-header-text {
    color: ${({ color }) => color};
    font-weight: bold;
    font-size: 17px;
  }

  & .ant-collapse {
    background-color: transparent !important;
    border: none !important;
  }

  & .ant-collapse > .ant-collapse-item {
    border: none !important;
  }

  & .ant-collapse .ant-collapse-content {
    border-top: none !important;
  }

  & .ant-collapse-expand-icon {
    color: ${({ color }) => color};
  }
`

export const ContainerTableImport = styled.div`
  max-height: 500px;
  overflow: auto;
`

export const ContainerActionsColor = styled.div`
  ${() => {
    const { isDarkMode } = useDark()

    if (isDarkMode)
      return `
      && .ant-btn-default {
        background-color: ${darkColor.primary} !important;
        border-color: ${darkColor.backgroundColor} !important;
        color: ${darkColor.white};
      }

      && .ant-dropdown-menu-item {
        background-color: red !important;
      }
    `

    return ''
  }}
`
export const ButtonActions = styled(Button)``
