import DefaultPage from '../Components/defaultPage'
import { Header } from '../Components/header'
import { Avatar, Col, List, Popover, Row, Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { DeafultTitleTab } from '../Components/deafultTitleTab'
import { BsHouseDoor } from 'react-icons/bs'
import { MdSearch } from 'react-icons/md'
import { useEffect, useState } from 'react'
import { ContainerListWorkspaces, Metric } from '../Components/CardViwWorkspaces/style'
import { BiTag } from 'react-icons/bi'
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai'
import api_v2 from '../api/api-v2'
import { darkColor, lightColor } from '../utils/styleColors'
import { useDark } from '../context/contextDark'

interface IWorkspace {
  id: any
  avatar_url: any
  name: string
  dash_visits_percent: number
  dash_visits_assiduity_percent: number
  dash_tasks_percent: number
  dash_promoter_online: number
  dash_promoter_offline: number
  dash_promoter_without_script: number
  dash_visits_justify: number
  dash_visits_in_progress: number
  dash_visits_pendent: number
  dash_visits_complete: number
}

const WorkspaceInit = () => {
  const { isDarkMode } = useDark()

  const [loading, setLoading] = useState(false)
  const [workspaces, setWorkspaces] = useState<IWorkspace[]>([])
  const [defaultList, setDefaultList] = useState<any>([])

  const handleSelect = (team: IWorkspace) => {
    return team
    // localStorage.setItem('@rocketpdv:workspace', JSON.stringify(team))
    // history.push('/backoffice/')
  }

  useEffect(() => {
    async function getWorks() {
      setLoading(true)
      try {
        const { data } = await api_v2.get('/workspace')
        setWorkspaces(data.result)
        setDefaultList(data.result)
      } catch (err) {
        return err
      } finally {
        setLoading(false)
      }
    }

    getWorks()
  }, [])

  function onSearch(event: any) {
    const search = event.target.value
    const workspacesFilter = defaultList.filter((item: any) => {
      return !item.name.search(new RegExp(search, 'gi'))
    })

    setWorkspaces(workspacesFilter)
  }

  return (
    <ContainerListWorkspaces>
      <List
        header={
          <>
            <Row>
              <Col md={18}>
                <div>Ambientes ({workspaces.length || 0})</div>
              </Col>
              <Col md={6}>
                <div
                  style={{
                    marginBottom: 10,
                    borderRadius: 5,
                    width: 200,
                    height: 30,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'spacebetween',
                    backgroundColor: isDarkMode ? darkColor.bgTable : lightColor.bgWOrkspaces,
                    fontSize: 17,
                    color: isDarkMode ? darkColor.white : lightColor.black,
                    marginRight: 20,
                    borderColor: isDarkMode ? darkColor.white : 'transparent',
                    borderWidth: 1,
                    borderStyle: 'solid',
                  }}
                >
                  <input
                    placeholder="Buscar"
                    style={{
                      height: '100%',
                      width: '100%',
                      border: 'none',
                      backgroundColor: isDarkMode ? darkColor.bgTable : lightColor.bgWOrkspaces,
                      outline: 'none',
                      borderRadius: 5,
                      paddingLeft: 5,
                      fontSize: 17,
                    }}
                    onChange={onSearch}
                  />
                  <MdSearch size={20} color={lightColor.secondPrimary} />
                </div>
              </Col>
            </Row>
          </>
        }
        loading={loading}
        style={{
          background: isDarkMode ? darkColor.bgTable : lightColor.white,
          padding: 12,
          color: isDarkMode ? darkColor.white : lightColor.blackTextBold,
          borderRadius: 6,
          height: '80vh',
          overflow: 'scroll',
          overflowX: 'hidden',
        }}
        itemLayout="horizontal"
        dataSource={workspaces}
        renderItem={(item: IWorkspace) => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <Avatar
                  src={item.avatar_url}
                  style={{
                    background: lightColor.eee,
                    border: `1px solid ${lightColor.eee}`,
                  }}
                  size="large"
                />
              }
              title={
                <p
                  onClick={() => handleSelect(item)}
                  style={{ color: isDarkMode ? darkColor.white : lightColor.black }}
                >
                  {item.name}
                </p>
              }
              description={
                <>
                  <Row style={{ paddingBottom: 8 }}>
                    <Col md={6} style={{ color: isDarkMode ? darkColor.white : lightColor.black }}>
                      <Popover
                        title={<b style={{ color: isDarkMode ? darkColor.white : lightColor.black }}>Atendimento</b>}
                        placement="bottom"
                        overlayInnerStyle={{
                          background: isDarkMode ? darkColor.bgTable : lightColor.white,
                        }}
                        content={
                          <ul style={{ listStyle: 'none', color: isDarkMode ? darkColor.white : lightColor.black }}>
                            <li>{item.dash_visits_justify} Justificadas</li>
                            <li>{item.dash_visits_in_progress} Em Progresso</li>
                            <li>{item.dash_visits_pendent} Pendente</li>
                            <li>{item.dash_visits_complete} Concluídas</li>
                          </ul>
                        }
                      >
                        <b style={{ color: isDarkMode ? darkColor.white : lightColor.black }}>Atendimento</b>
                        <Metric>
                          <BiTag color={lightColor.secondPrimary} /> {item.dash_visits_percent}%
                        </Metric>
                      </Popover>
                    </Col>
                    <Col md={6} style={{ color: isDarkMode ? darkColor.white : lightColor.black }}>
                      <b>Assiduídade</b>
                      <Metric>
                        <BiTag color={lightColor.secondPrimary} /> {item.dash_visits_assiduity_percent}%
                      </Metric>
                    </Col>
                    <Col md={5} style={{ color: isDarkMode ? darkColor.white : lightColor.black }}>
                      <b>Tarefas</b>
                      <Metric>
                        <BiTag color={lightColor.secondPrimary} /> {item.dash_tasks_percent}%
                      </Metric>
                    </Col>
                    <Col md={7} style={{ color: isDarkMode ? darkColor.white : lightColor.black }}>
                      <b>Equipe de campo</b>
                      <Metric>
                        <div className="on_off">
                          <AiOutlineArrowUp color={lightColor.greenPrimary} fontSize={35} /> {item.dash_promoter_online}
                        </div>
                        <div className="on_off">
                          <AiOutlineArrowDown color={lightColor.redBold} fontSize={35} /> {item.dash_promoter_offline}
                        </div>
                      </Metric>
                      <small>{item.dash_promoter_without_script} Colaboradores sem roteiro</small>
                    </Col>
                  </Row>
                </>
              }
            />
          </List.Item>
        )}
      />
    </ContainerListWorkspaces>
  )
}

const Workspaces = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: <DeafultTitleTab title="Padrão" icon={<BsHouseDoor fontSize={20} />} />,
      children: WorkspaceInit(),
    },
  ]

  return (
    <DefaultPage>
      <Header title="Ambientes" />
      <Tabs defaultActiveKey="1" items={items} animated={true} />
    </DefaultPage>
  )
}

export default Workspaces
