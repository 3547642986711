import React, { useRef, useState, useMemo } from 'react'
import { Select, Spin, SelectProps } from 'antd'
import debounce from 'lodash/debounce'
import { Container, Label } from './style'
import api_v2 from '../../../api/api-v2'
import api_v2_witout_native from '../../../api/api-v2-witout-native'
import { lightColor } from '../../../utils/styleColors'
import { useDark } from '../../../context/contextDark'
// import { ExclamationCircleFilled } from '@ant-design/icons'
// const { confirm } = Modal
function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }: any) {
  const { isDarkMode } = useDark()

  const [fetching, setFetching] = useState(false)
  const [options, setOptions] = useState([])
  // const [getValue, setGetValue] = useState<any>('')
  const fetchRef = useRef(0)

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: any) => {
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setOptions([])
      setFetching(true)
      // setGetValue(value)
      fetchOptions(value).then((newOptions: any) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return
        }

        setOptions(newOptions)
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [fetchOptions, debounceTimeout])

  // const addValue = (
  //   <div
  //     style={{
  //       display: 'flex',
  //       alignItems: 'center',
  //       justifyContent: 'center',
  //       padding: '20px 0',
  //     }}
  //   >
  //     <p
  //       style={{ color: isDarkMode ? darkColor.white : lightColor.blackTextBold, cursor: 'pointer', fontSize: '16px' }}
  //       onClick={() =>
  //         confirm({
  //           title: 'Atenção',
  //           icon: <ExclamationCircleFilled />,
  //           content: `Você deseja adicionar ${getValue} na lista?`,
  //           onOk() {},
  //           onCancel() {
  //             return null
  //           },
  //           wrapClassName: 'maxIndex',
  //         })
  //       }
  //     >
  //       Clique aqui para adicionar:{' '}
  //       <b style={{ color: isDarkMode ? darkColor.primary : lightColor.primary, marginLeft: 10 }}>{getValue}</b>
  //     </p>
  //   </div>
  // )

  return (
    <>
      <Select
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={
          // fetching ? (
          //   <Spin size="small" />
          // ) : !fetching && options.length === 0 && getValue && getValue.length > 0 ? (
          //   addValue
          // ) : null
          fetching ? <Spin size="small" /> : null
        }
        {...props}
        options={options}
        rootClassName={isDarkMode ? 'dark-drop-down' : ''}
        className={isDarkMode ? 'dark-drop-down' : ''}
        style={{
          border: `1px solid ${lightColor.selectBorder}`,
          borderRadius: 4,
          color: lightColor.colorBlackOpacity,
          height: '38px',
        }}
      />
    </>
  )
} // Usage of DebounceSelect
async function fetchUserList(value: string, path: string, config?: string, header?: any) {
  try {
    if (header) {
      const { data } = await api_v2_witout_native.get(`/search/${path}?search=${value}${config || ''}`, header)
      return data
    } else {
      const { data } = await api_v2.get(`/search/${path}?search=${value}${config || ''}`)
      return data
    }
  } catch (error) {
    null
  }
}

interface Props {
  label?: string
  path: string
  isMult?: boolean
  config?: string
  header?: any
}

type InputProps = SelectProps<any> & Props
export const SimpleSearchWithoutForm: React.FC<InputProps> = ({ path, isMult, config, header, ...rest }) => {
  return (
    <Container>
      <Label>
        {isMult ? (
          <DebounceSelect
            mode="multiple"
            fetchOptions={async (text: string) => fetchUserList(text, path, config, header)}
            style={{
              width: '100%',
              border: '1px solid red',
              borderRadius: 4,
              color: lightColor.colorBlackOpacity,
              height: '38px',
            }}
            {...rest}
          />
        ) : (
          <DebounceSelect
            showSearch
            fetchOptions={async (text: string) => fetchUserList(text, path, config, header)}
            style={{
              width: '100%',
            }}
            {...rest}
          />
        )}
      </Label>
    </Container>
  )
}
