import React from 'react'
import { ContainerBase, ContainerColumnBottom, ContainerColumnTop, ContainerMenu, ContainerMenuTop } from './style'
import Logo from '../../assets/logo-icon.png'
import { Divider } from 'antd'
import NavLink from './NavLink'
import { AiOutlineLogout, AiOutlineUser } from 'react-icons/ai'
// import { MdOutlineLocationOn } from 'react-icons/md'
// import { BsListCheck } from 'react-icons/bs'
import { TbBuildingWarehouse } from 'react-icons/tb'
import { lightColor } from '../../utils/styleColors'
import { BsClipboardData, BsGear } from 'react-icons/bs'
import { FiMap } from 'react-icons/fi'
import { RiNodeTree } from 'react-icons/ri'

export const Menu = () => {
  return (
    <ContainerBase>
      <ContainerMenu>
        <ContainerMenuTop>
          <ContainerColumnTop>
            <img src={Logo} alt="logo" style={{ width: 30 }} />
            <Divider
              style={{
                borderColor: lightColor.white,
                margin: '14px 0',
                marginBottom: 20,
              }}
            />
            <NavLink href="/promoter" children="Promotor" icon={<AiOutlineUser style={{ fontSize: 25 }} />} />
            <NavLink href="/status_day" children="Status day" icon={<BsClipboardData style={{ fontSize: 25 }} />} />
            <NavLink href="/visits" children="Visitas" icon={<FiMap style={{ fontSize: 25 }} />} />
            <NavLink href="/tasks" children="Atividades" icon={<RiNodeTree style={{ fontSize: 25 }} />} />
            {/* 
            <NavLink href="/status_day" children="Status day" icon={<BsListCheck style={{ fontSize: 25 }} />} /> */}
          </ContainerColumnTop>
          <ContainerColumnBottom>
            <NavLink href="/config" children="Configurações" icon={<BsGear style={{ fontSize: 25 }} />} />
            <NavLink href="/workspaces" children="Ambientes" icon={<TbBuildingWarehouse style={{ fontSize: 25 }} />} />
            <Divider
              style={{
                borderColor: lightColor.white,
                margin: '14px 0',
                marginBottom: 20,
              }}
            />
            <AiOutlineLogout style={{ fontSize: 25, color: 'red', cursor: 'pointer' }} />
          </ContainerColumnBottom>
        </ContainerMenuTop>
      </ContainerMenu>
    </ContainerBase>
  )
}
