import styled from 'styled-components'
import { darkColor, lightColor } from '../../utils/styleColors'
import { useDark } from '../../context/contextDark'

export const ContainerConfig = styled.div`
  margin-left: 100;
  display: flex;
  justify-content: space-between;
  margin-top: 100;
  width: '100%';
  &.ant-spin-dot-item {
    background-color: ${lightColor.primary} !important;
  }
  .ant-table-thead .ant-table-cell {
    background-color: ${lightColor.white} !important;
  }

  .ant-table-container {
    border-left: 6px solid ${lightColor.borderTable} !important;
  }

  .ant-table-selection-column {
    background: ${lightColor.white};
  }

  ${() => {
    const { isDarkMode } = useDark()

    if (isDarkMode)
      return `
      .ant-table-thead .ant-table-cell {
        background-color: ${darkColor.bgTable} !important;
      }
    
      .ant-table-container {
        border-left: 6px solid #7158c1 !important;
        background: ${darkColor.bgTable} !important;
      }
    
      .ant-table-cell {
        background: ${darkColor.bgTable} ;
        border-color: ${darkColor.borderTable} !important;
      }
      th {
        border-color: ${darkColor.borderTable} !important;

      }

      table {
        border-color: ${darkColor.borderTable} !important;
      }
      && .tableOutside {
        background: transparent !important;
      }

      && .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >thead>tr>th {
        color: ${darkColor.white} !important;
      }

      && .ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tbody>tr>td {
        color: ${darkColor.white} !important;
      }

      && .ant-table-thead .ant-table-cell {
        color: ${darkColor.white} !important;
      }

      && .ant-pagination {
        color: ${darkColor.white} !important;
      }

      && .ant-pagination .ant-pagination-item a{
        color: ${darkColor.white} !important;
      }

      && .ant-pagination .ant-pagination-item-active {
        background-color: transparent !important;
      }

      && .ant-pagination-item-link, .ant-pagination-item-ellipsis {
        color: ${darkColor.white} !important;
      }

      && .ant-select-selector {
        color: ${darkColor.white} !important;
        background-color: ${darkColor.primary} !important;
        border-color: ${darkColor.primary} !important;
      }

      && .ant-select-dropdown {
        color: ${darkColor.white} !important;
        background-color: ${darkColor.primary} !important;
      }

      && .ant-select-item {
        color: ${darkColor.white} !important;
      }


      && .ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >td {
        background: ${darkColor.primaryLight};
      }

      && .ant-table-wrapper .ant-table-tbody >tr.ant-table-row:hover >td {
        background: ${darkColor.primaryLight};
      }

      &&& .tableOutside {
        background: transparent !important;
      }
    `

    return ''
  }}
`
