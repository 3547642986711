import React from 'react'
import { ContainerHeader } from './style'
import { Switch } from 'antd'
import { MODE_DARK, useDark } from '../../context/contextDark'
import { BsFillMoonStarsFill, BsFillSunFill } from 'react-icons/bs'
interface IProps {
  title: string
}
export const Header = ({ title }: IProps) => {
  const { setIsDarkMode, isDarkMode } = useDark()
  return (
    <ContainerHeader>
      <p>{title}</p>
      <Switch
        checkedChildren={<BsFillMoonStarsFill color="#fff" style={{ marginTop: 2 }} />}
        unCheckedChildren={<BsFillSunFill color="yellow" fontSize="17px" style={{ marginTop: 2 }} />}
        checked={isDarkMode}
        onChange={(checked) => {
          setIsDarkMode(checked)
          localStorage.setItem(MODE_DARK, JSON.stringify(checked))
        }}
      />
    </ContainerHeader>
  )
}
