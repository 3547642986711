import React, { useState, useEffect } from 'react'
import { Button, ContainerModal, ContainerStep2 } from './style'
import AddUser from '../../assets/addUser.png'
import { yupResolver } from '@hookform/resolvers/yup'
import { Col, message, Row, Spin } from 'antd'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { HookInput } from '../form/hookInput'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import axios from 'axios'
import { getToken } from '../../utils/login'
import { useDark } from '../../context/contextDark'
import { darkColor, lightColor } from '../../utils/styleColors'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  promoters: any[]
}

const scheme = Yup.object().shape({
  password: Yup.string().required('Senha é obrigatório'),
  confirm: Yup.string()
    .oneOf([Yup.ref('password'), undefined], 'Senhas diferentes')
    .required('Senha é obrigatório'),
})

export const ModalPasswordNative = ({ visible, close, promoters }: IProps) => {
  const { isDarkMode } = useDark()

  const [loading, setLoading] = useState<boolean>(false)
  const [seePass, setSeePass] = useState<boolean>(false)
  const [seePass2, setSeePass2] = useState<boolean>(false)

  const { handleSubmit, reset, control } = useForm({
    resolver: yupResolver(scheme),
  })

  async function submit(data: any) {
    setLoading(true)
    try {
      const body = {
        newPassword: data.password,
        promoters: promoters,
      }

      for await (const item of body.promoters) {
        const api_v2 = axios.create({
          baseURL: process.env.REACT_APP_API_V2_URL,
          // baseURL: 'http://localhost:9000/',
          headers: {
            'Content-Type': 'application/json',
          },
        })

        api_v2.interceptors.request.use((config: any) => {
          const token = getToken()
          const headers = { ...config.headers }
          if (token) headers.Authorization = `Bearer ${token}`
          headers.workspace = item.workspace.id
          headers.workspaceId = item.workspace.slug

          return { ...config, headers }
        })
        await api_v2.put(`/promoter/password/${item.id}`, data)
      }

      message.success('Senha alterada com sucesso')
      close(false)
    } catch (err) {
      message.error('Erro ao alterar senha')

      setLoading(false)
    }
    setLoading(false)
  }

  useEffect(() => {
    reset()
    setSeePass(false)
    setSeePass2(false)
  }, [visible])

  return (
    <ContainerModal
      open={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      destroyOnClose={true}
    >
      <ContainerStep2>
        <h3>Trocar senha</h3>
        <img src={AddUser} alt="user" />
        <form autoComplete="off" onSubmit={handleSubmit(submit)}>
          <Row gutter={[20, 20]}>
            <Col span={12}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <HookInput
                  name="password"
                  label="Digite a nova senha"
                  type={seePass ? 'text' : 'password'}
                  control={control}
                  required="Campo obrigatório"
                />
                {seePass && (
                  <EyeOutlined
                    style={{
                      marginLeft: 5,
                      cursor: 'pointer',
                      fontSize: 20,
                      paddingTop: 35,
                      color: isDarkMode ? darkColor.white : lightColor.blackTextBold,
                    }}
                    onClick={() => setSeePass(!seePass)}
                  />
                )}
                {!seePass && (
                  <EyeInvisibleOutlined
                    style={{
                      marginLeft: 5,
                      cursor: 'pointer',
                      fontSize: 20,
                      paddingTop: 35,
                      color: isDarkMode ? darkColor.white : lightColor.blackTextBold,
                    }}
                    onClick={() => setSeePass(!seePass)}
                  />
                )}
              </div>
            </Col>
            <Col span={12}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <HookInput
                  name="confirm"
                  label="Digite a nova senha"
                  type={seePass ? 'text' : 'password'}
                  control={control}
                  required="Campo obrigatório"
                />
                {seePass2 && (
                  <EyeOutlined
                    style={{
                      marginLeft: 5,
                      cursor: 'pointer',
                      fontSize: 20,
                      paddingTop: 35,
                      color: isDarkMode ? darkColor.white : lightColor.blackTextBold,
                    }}
                    onClick={() => setSeePass2(!seePass2)}
                  />
                )}
                {!seePass2 && (
                  <EyeInvisibleOutlined
                    style={{
                      marginLeft: 5,
                      cursor: 'pointer',
                      fontSize: 20,
                      paddingTop: 35,
                      color: isDarkMode ? darkColor.white : lightColor.blackTextBold,
                    }}
                    onClick={() => setSeePass2(!seePass2)}
                  />
                )}
              </div>
            </Col>
          </Row>
          <Button type="submit" style={{ width: '100%', height: '55px', marginTop: 20 }}>
            {loading ? <Spin /> : 'Alterar'}
          </Button>
        </form>
      </ContainerStep2>
    </ContainerModal>
  )
}
