import React, { useEffect, useState } from 'react'
import { Button, Dropdown, MenuProps, Spin, Table, Tooltip } from 'antd'
import type { ColumnsType, TableProps } from 'antd/es/table'
import { TableRowSelection } from 'antd/es/table/interface'
import { FiMoreHorizontal } from 'react-icons/fi'
import { Container, SpaceRow } from './style'
import { MdOpenInFull } from 'react-icons/md'
import DrawerContentInfo from '../drawerContentInfo'
import { BiMessageRounded } from 'react-icons/bi'
import { lightColor } from '../../utils/styleColors'
import { useDark } from '../../context/contextDark'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { useOpenInfo } from '../../context/openInfoDrawer'

export interface IPropsPagination {
  page?: number
  pageSize: number
}
interface IChecked {
  keyCheckItem(data: React.Key[]): void
  keyCheckAll(data: any[]): void
  selectAllPages?(): number[]
}

interface IProps extends TableProps<any> {
  column: ColumnsType<any>
  headerOrigin: any[]
  data: any[]
  checks: IChecked
  total: number
  fixedDataIndex?: string[]
  menu: MenuProps['items']
  pagination: any
  loading: boolean
  reload(): void
  columnsInfo?: any[]
}

export interface IopenDrawer {
  open: boolean
  row: any
  defaultKey?: string
}
export const TableDefault = ({
  column,
  data,
  checks,
  total,
  fixedDataIndex,
  menu,
  pagination,
  headerOrigin,
  loading,
  reload,
  ...rest
}: IProps) => {
  const { isDarkMode } = useDark()
  const { openDrawer, setOpenDrawer } = useOpenInfo()
  const [rowActive, setRowActive] = useState<any>()
  const [tableColumn, setTableColumn] = useState<any>([])
  const [checkedAll, setCheckedAll] = useState<boolean>(false)
  const [checkedKeys, setChekedKeys] = useState<React.Key[]>([])
  const [dataTable, setDataTable] = useState<any[]>(data)
  // const [openDrawer, setOpenDrawer] = useState<IopenDrawer>({
  //   open: false,
  //   row: {},
  // })
  const rowSelection: TableRowSelection<any> = {
    onChange: (selectedRowKeys, selectedRows, info) => {
      if (info.type === 'all' && checkedKeys?.length - selectedRowKeys?.length >= 10) {
        setChekedKeys([])
        checks.keyCheckItem([])
        checks.keyCheckAll([])
        return
      }

      setChekedKeys(selectedRowKeys)
      checks.keyCheckItem(selectedRowKeys)
      checks.keyCheckAll(selectedRows)
    },
    onSelectAll: (selectedRowKeys, selectedRows, changeRows) => {
      setCheckedAll(selectedRows?.length > 0)
      return changeRows
    },
  }

  const changePagination = (page: number, pageSize: number) => {
    pagination({ page, pageSize })
    // setDataTable([])
    return page + pageSize
  }

  const typeViews = {
    boolean: (value: boolean) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      if (value) {
        return (
          <div
            style={{
              width: 50,
              borderRadius: 4,
              border: `1px solid ${lightColor.boolGreen}`,
              backgroundColor: isDarkMode ? 'transparent' : lightColor.boolBgGreen,
              color: lightColor.boolGreen,
              fontSize: 10,
              padding: '3px 8px',
              fontWeight: 600,
              textAlign: 'center',
              alignSelf: 'baseline',
              margin: 'auto',
            }}
          >
            Sim
          </div>
        )
      } else {
        return (
          <div
            style={{
              width: 50,
              borderRadius: 4,
              border: `1px solid ${lightColor.boolOrange}`,
              backgroundColor: isDarkMode ? 'transparent' : lightColor.boolBgOrange,
              color: lightColor.boolOrange,
              fontSize: 10,
              padding: '3px 8px',
              fontWeight: 600,
              textAlign: 'center',
              alignSelf: 'baseline',
              margin: 'auto',
            }}
          >
            Não
          </div>
        )
      }
    },
    date: (value: string) => {
      return value ? moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''
    },
    datetime: (value: string) => {
      return value ? moment.utc(value).format('DD/MM/YYYY HH:mm:ss') : ''
    },
    money: (value: number) => {
      return value || value === 0 ? value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''
    },
    image: (value: string) => {
      return (
        <Button
          onClick={() => window.open(value, '_blank')}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
            background: lightColor.imageBg,
            color: lightColor.white,
          }}
          disabled={!value}
          className="btn-disabled"
        >
          Visualizar
        </Button>
      )
    },
  }

  useEffect(() => {
    setDataTable(data)
  }, [data])

  useEffect(() => {
    if (column && column.length > 0) {
      const renderColumn = column.map((col: any, index) => {
        col.key = uuidv4()
        if (!col.width) col.width = 200
        if (col.dataIndex && col.dataIndex.indexOf('.') > -1) {
          col.dataIndex = col.dataIndex.split('.')
        }

        if (index === 0) {
          col.width = 380
          col.render = (a: any, row: any) => {
            if (!a) return null
            return (
              <SpaceRow>
                {a?.length > 30 ? <Tooltip title={a}>{`${a.substring(0, 30)}...`}</Tooltip> : <p>{a}</p>}
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    className="ocult"
                    onClick={() =>
                      setOpenDrawer({
                        open: true,
                        row,
                        defaultKey: undefined,
                      })
                    }
                  >
                    <MdOpenInFull />
                    <p>Abrir</p>
                  </div>
                  <div
                    onClick={() =>
                      setOpenDrawer({
                        open: true,
                        row,
                        defaultKey: '3',
                      })
                    }
                    style={{
                      marginLeft: 10,
                      fontSize: 20,
                    }}
                  >
                    <BiMessageRounded />
                  </div>
                </div>
              </SpaceRow>
            )
          }
        }
        if (index === 0 || (fixedDataIndex && fixedDataIndex.includes(col.dataIndex))) {
          return { ...col, fixed: 'left' }
        }

        if (col.type === 'boolean') col.render = (value: any) => typeViews.boolean(value)
        if (col.type === 'date') col.render = (value: any) => typeViews.date(value)
        if (col.type === 'datetime') col.render = (value: any) => typeViews.datetime(value)
        if (col.type === 'money') col.render = (value: any) => typeViews.money(value)
        if (col.type === 'image') col.render = (value: any) => typeViews.image(value)

        return col
      })
      setTableColumn([
        {
          title: '',
          // fixed: 'left',
          width: 0,
          className: 'tableOutside',
          render: (text: any, row: any, indice: any) => (
            <>
              {menu && menu.length > 0 && (
                <div
                  style={{
                    display: indice === rowActive ? 'block' : 'none',
                  }}
                >
                  <Dropdown
                    menu={{
                      items:
                        menu &&
                        menu.map((value: any, index: number) => {
                          return {
                            ...value,
                            key: index,
                            onClick: () => value.onClick(row),
                          }
                        }),
                    }}
                    trigger={['click']}
                    rootClassName={isDarkMode ? 'dark-drop-down' : ''}
                  >
                    <FiMoreHorizontal style={{ cursor: 'pointer', fontSize: 20 }} />
                  </Dropdown>
                </div>
              )}
            </>
          ),
        },
        ...renderColumn,
      ])
    }
  }, [column])

  return (
    <Container>
      {checkedAll && checks.selectAllPages && (
        <>
          {checkedKeys?.length > 0 && checkedKeys?.length !== total && (
            <div
              style={{
                width: '100%',
                height: 48,
                marginBottom: 10,
                backgroundColor: lightColor.bgTable,
                fontSize: '0.875rem',
                color: lightColor.colorTable,
                padding: '6px 16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p style={{ margin: 0 }}>Todas os promotores nesta página estão selecionadas.</p>
              <p
                style={{
                  color: lightColor.primary,
                  cursor: 'pointer',
                  fontWeight: 'bold',
                  margin: 0,
                }}
                onClick={() => {
                  if (checks.selectAllPages) {
                    const getIds = checks.selectAllPages()
                    setChekedKeys(getIds)
                    checks.keyCheckItem(getIds)
                    checks.keyCheckAll(dataTable)
                  }
                }}
              >
                Selecione todos os {dataTable?.length} promotores.
              </p>
            </div>
          )}
          {checkedKeys?.length === total && (
            <div
              style={{
                width: '100%',
                height: 48,
                marginBottom: 10,
                backgroundColor: lightColor.bgTable,
                fontSize: '0.875rem',
                color: lightColor.colorTable,
                padding: '6px 16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p style={{ margin: 0 }}>Todas os promotores estão selecionadas.</p>
              <p
                style={{
                  color: lightColor.primary,
                  margin: 0,
                  cursor: 'pointer',
                  fontWeight: 'bold',
                }}
                onClick={() => {
                  setChekedKeys([])
                  checks.keyCheckItem([])
                  checks.keyCheckAll([])
                }}
              >
                Limpar seleção.
              </p>
            </div>
          )}
        </>
      )}
      {tableColumn && tableColumn.length > 0 ? (
        <Table
          columns={tableColumn}
          dataSource={dataTable}
          {...rest}
          bordered={true}
          rowSelection={{
            ...rowSelection,
            selectedRowKeys: checkedKeys,
          }}
          scroll={{ x: 'fit-content' }}
          onRow={(registro, rowIndex) => {
            return {
              onMouseEnter: () => setRowActive(rowIndex),
              onMouseLeave: () => setRowActive(null), // mouse sai da linha
            }
          }}
          pagination={
            pagination
              ? {
                  onChange: changePagination,
                  size: 'small',
                  showSizeChanger: true,
                  total,
                  showTotal: (totalData, [, totalPerPage]) => `Exibindo ${totalPerPage} de ${total} de registros`,
                }
              : {
                  showTotal: (totalData, [, totalPerPage]) => `Exibindo ${totalPerPage} de ${totalData} de registros`,
                }
          }
          loading={loading}
          sticky
        />
      ) : (
        <div
          style={{
            width: '100%',
            height: '400px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin size="large" style={{ color: `${lightColor.red} !important` }} />
        </div>
      )}
      <DrawerContentInfo
        onCloseDrawer={() => setOpenDrawer({ open: false, row: {} })}
        open={openDrawer.open}
        header={headerOrigin}
        valueRow={openDrawer.row}
        defaultKey={openDrawer.defaultKey}
        reload={reload}
      />
    </Container>
  )
}
