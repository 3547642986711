import React, { useEffect, useState } from 'react'
import { ContainerModal } from './style'
import { Button, Col, Row, message } from 'antd'
import { useForm } from 'react-hook-form'
import { HookInput } from '../form/hookInput'
import { SelectsUfs } from '../../utils/selectsUfs'
import { HookSelectedFixed } from '../form/hookSelectedFixed'
import api_v2 from '../../api/api-v2'
import { IPropsWorkspace } from '../defaultFilters/workspaceFilter'
import { lightColor } from '../../utils/styleColors'
import api from '../../api'
import { useOpenInfo } from '../../context/openInfoDrawer'

interface IProps {
  visible: boolean
  close: (boolean: any) => void
  reload?(): void
}

interface IPropsPromoterCreate {
  cpf: string
  nome: string
  mobile_senha: string
  uf_sigla: string
  workspaceName: string
}

export const ModalCreatePromoter = ({ visible, close, reload }: IProps) => {
  const { setOpenDrawer } = useOpenInfo()

  const [workspaces, setWorkspaces] = useState<IPropsWorkspace[]>([])
  const { handleSubmit, formState, control, reset } = useForm<IPropsPromoterCreate>({})
  const { isLoading } = formState

  async function submit(body: IPropsPromoterCreate) {
    try {
      const newBody: any = {
        id: null,
        nome: body.nome,
        nome_social: null,
        data_nascimento: null,
        genero: null,
        raca: null,
        nome_mae: null,
        nome_pai: null,
        nacionalidade: null,
        naturalidade: null,
        portador_deficiencia: false,
        cid: null,
        estado_civil: null,
        'workspace.name': body.workspaceName,
        ativo: true,
        data_admissao: null,
        data_desligamento: null,
        marca_atendida: null,
        colaborador_superior_id: null,
        jornada_de_trabalho_id: null,
        afastado: false,
        desligado: false,
        cep: null,
        endereco: null,
        numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        uf_sigla: body.uf_sigla,
        mobile_senha: body.mobile_senha,
        perfil_promoter_id: null,
        equipe_campo: false,
        cpf: body.cpf,
        rg: null,
        pis: null,
        ctps_numero: null,
        ctps_serie: null,
        cnpj_mei: null,
        temporario: false,
        salario: null,
        matricula: null,
        vinculo: null,
        cargo: null,
        setor: null,
        centro_custo: null,
        auxilio_home_office: false,
        manutencao_veicular: false,
        pensao_alimenticia: false,
        vale_educacao: false,
        periculosidade: false,
        plano_saude: false,
        vale_presente: false,
        plano_odonto: false,
        seguro_vida: false,
        ajuda_custo: false,
        premiacao: false,
        vale_alimentacao: false,
        vale_cultura: false,
        vale_despesas: false,
        cesta_basica: false,
        vale_saude: false,
        vale_refeicao: false,
        insalubridade: false,
        tipo_transporte: null,
        celular: null,
        celular_corporativo: null,
        telefone: null,
        telefone_corporativo: null,
        email: null,
        email_corporativo: null,
        nome_contato_emergencia: null,
        contato_emergencia: null,
        vinculo_contato_emergencia: null,
        banco: null,
        possui_pix: false,
        tipo_pix: null,
        chave_pix: null,
        tipo_conta: null,
        agencia: null,
        conta: null,
        habilitado: false,
        cnh_categoria: null,
        cnh_numero: null,
        cnh_numero_seguranca: null,
        possui_veiculo: false,
        tipo_veiculo: null,
        recebeu_notebook: false,
        recebeu_tablet: false,
        recebeu_celular: false,
        tam_calca: null,
        tam_camisa: null,
        tam_jaqueta: null,
        tam_calcado: null,
        foto_rg: null,
        foto_cnh: null,
        comprovante_bancario: null,
        comprovante_residencia: null,
        comprovante_carteira_digital: null,
        comprovante_pis: null,
        foto_3_4: null,
      }

      newBody['workspace.name'] = body.workspaceName
      delete newBody.workspaceName

      const { data } = await api.post('/ms-promoter/create-or-update', newBody)
      if (data.error) {
        message.error('Erro ao criar promotor')
        return
      }
      message.success('Promotor cadastrado com sucesso')
      if (reload) reload()
      close(false)
      setOpenDrawer({
        open: true,
        row: { ...newBody, id: data.id },
        defaultKey: undefined,
      })
    } catch (err: any) {
      message.error(err.response.data.message)
    }
  }

  const getWorkspaces = async () => {
    try {
      const { data } = await api_v2.get('/workspace')
      setWorkspaces(data.result)
    } catch (error) {
      return null
    }
  }

  useEffect(() => {
    getWorkspaces()
  }, [])

  useEffect(() => {
    reset()
  }, [visible])

  return (
    <ContainerModal
      open={visible}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onCancel={() => close(!visible)}
      closable={true}
      style={{ top: 60 }}
      destroyOnClose={true}
      width="50vw"
    >
      <h3>Adicionar promotor</h3>
      <form autoComplete="off" onSubmit={handleSubmit(submit)}>
        <Row gutter={[20, 20]} style={{ marginBottom: 20 }}>
          <Col span={12}>
            <HookInput name="nome" label="Nome completo" control={control} required="Campo obrigatório" />
          </Col>
          <Col span={12}>
            <HookInput name="cpf" label="CPF" control={control} required="Campo obrigatório" mask="999.999.999-99" />
          </Col>
          <Col span={12}>
            <HookInput
              name="mobile_senha"
              label="Senha"
              type="password"
              control={control}
              required="Campo obrigatório"
            />
          </Col>
          <Col span={12}>
            <HookSelectedFixed
              name="uf_sigla"
              label="Estado"
              control={control}
              required="Campo obrigatório"
              options={SelectsUfs}
            />
          </Col>
          <Col span={12}>
            <HookSelectedFixed
              name="workspaceName"
              label="Ambiente"
              control={control}
              required="Campo obrigatório"
              options={workspaces.map((item) => ({ value: item.name, label: item.name }))}
            />
          </Col>
        </Row>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            loading={isLoading}
            htmlType="submit"
            style={{
              width: '100%',
              height: 40,
              fontSize: 17,
              backgroundColor: lightColor.primary,
              color: lightColor.white,
            }}
          >
            Salvar
          </Button>
        </div>
      </form>
    </ContainerModal>
  )
}
