import styled from 'styled-components'
import { darkColor, lightColor } from '../../utils/styleColors'
import { useDark } from '../../context/contextDark'

export const ContainerListWorkspaces = styled.div`
  width: 100%;
  li {
    padding: 20px 10px 10px !important;
  }
  & li:hover {
    transition: all 0.4s;
    background-color: ${lightColor.purpleLight} !important;
  }

  ${() => {
    const { isDarkMode } = useDark()

    if (isDarkMode)
      return `
      & li:hover {
        transition: all 0.4s;
        background-color: ${darkColor.borderTableHover} !important;
      }
      
    `

    return ''
  }}
`

export const Metric = styled.div`
  display: flex;
  align-items: center;

  svg {
    font-size: 17px;
    transform: rotate(180deg);
    margin-right: 10px;
  }

  .on_off {
    margin-right: 18px;
    display: flex;
    align-items: center;

    svg {
      font-size: 17px;
      transform: rotate(0deg);
      margin-right: 3px;
    }
  }
`

export const ContainerActions = styled.div`
  display: flex;
  flex-direction: column;
  height: 40vh;

  button {
    display: flex;
    align-items: center;
    height: 31%;
    margin-bottom: 10px;
    background: white;
    border: 1px solid white;
    border-radius: 6px;
    padding-left: 14px;
    padding-right: 14px;
    cursor: pointer;

    img {
      width: 32px;
    }

    div {
      margin-left: 14px;
      text-align: left;
    }
  }

  button:hover {
    transition: all 0.3s;
    border: 1px solid ${lightColor.primary};
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  }
`
